// @flow

import React, { memo, useState } from 'react';
import parse from 'html-react-parser';
import Button from 'components/Button';
import Input from 'components/Input';
import images from 'themes/images';
import ROUTERS from 'constants/router';
import ItemComment from './ItemComment';
import REGEX from "../../../constants/regex";
import { useEffect } from 'react';
import AvatarDefault from '../../../assets/icons/avatar_default.svg';
type Props = {
  itemObj: Object,
  history: {
    push: Function,
  },
  handleDelete: Function,
  handleViewImage: Function,
  handleDeleteComment: Function,
  idUserLogin: any,
  contentComment: string,
  handleClickComment: Function,
  editCommentId: any,
  handleSubmitComment: Function,
  handleGetContentOfUser: Function,
  handleSubmitLike: Function,
  handleChange: Function,
  idCommentOfUser: any,
  contentEditComment: string,
  handleSubmitEditComment: Function,
  handleOnFocusInput: Function,
  handleOnBlurInput: Function,
  avatarOffUser: string,
  handleShowEditDel: Function,
  showEditDel: Object,
  handleShowCmtEditDel: Function,
  showCmtEditDel: any,
  handleUpdateListProductEnquiry: Function
};

const ItemProduct = ({
  itemObj,
  handleDelete,
  history,
  handleViewImage,
  handleDeleteComment,
  idUserLogin,
  handleChange,
  contentComment,
  handleClickComment,
  editCommentId,
  handleSubmitComment,
  handleGetContentOfUser,
  handleSubmitLike,
  idCommentOfUser,
  contentEditComment,
  handleSubmitEditComment,
  handleOnFocusInput,
  handleOnBlurInput,
  avatarOffUser,
  handleShowEditDel,
  showEditDel,
  handleShowCmtEditDel,
  showCmtEditDel,
  handleUpdateListProductEnquiry
}: Props) => {
  const dataInfo = window.localStorage.getItem('DATA_INFO') ? JSON.parse(window.localStorage.getItem('DATA_INFO')) : '';
  const regexLastCharacter = /((?:(?:ㅏ|ㅓ|ㅗ|ㅜ|ㅡ|ㅣ|ㅐ|ㅔ|ㅑ|ㅕ|ㅛ|ㅠ|ㅖ|ㅒ|ㅘ|ㅙ|ㅝ|ㅞ|ㅚ|ㅟ|ㅢ)(\D))(?:[\$\&\+\,\:\;\=\?\@\#\|\'\<\>\.\^\*\(\)\%\!\- ]+)|((?:ㅏ|ㅓ|ㅗ|ㅜ|ㅡ|ㅣ|ㅐ|ㅔ|ㅑ|ㅕ|ㅛ|ㅠ|ㅖ|ㅒ|ㅘ|ㅙ|ㅝ|ㅞ|ㅚ|ㅟ|ㅢ) )|(?:ㅏ|ㅓ|ㅗ|ㅜ|ㅡ|ㅣ|ㅐ|ㅔ|ㅑ|ㅕ|ㅛ|ㅠ|ㅖ|ㅒ|ㅘ|ㅙ|ㅝ|ㅞ|ㅚ|ㅟ|ㅢ))+$/gm;
  const renderListComment =
    itemObj &&
    itemObj.comment &&
    itemObj.comment.length > 0 &&
    itemObj.comment.map((item) => (
      <ItemComment
        key={item.id}
        userId={idUserLogin}
        handleGetContentOfUser={handleGetContentOfUser}
        itemObj={item}
        handleDeleteComment={handleDeleteComment}
        idCommentOfUser={idCommentOfUser}
        handleChange={handleChange}
        contentEditComment={contentEditComment}
        handleSubmitEditComment={handleSubmitEditComment}
        handleOnFocusInput={handleOnFocusInput}
        handleOnBlurInput={handleOnBlurInput}
        avatarOffUser={avatarOffUser}
        handleShowCmtEditDel={handleShowCmtEditDel}
        showEditDel={showEditDel}
      />
    ));
  const replaceCommentString = (val) => {
    return val
      .trim()
      .replace(REGEX.LAST_CHARACTER_POLICY, '');
  };

  const commentNumberOfUser = itemObj && itemObj.comment.filter((item) => item.requested === 1);
  let content = itemObj?.content && parse(itemObj?.content);
  content = replaceCommentString(content);

  const handleLike = (id, isLike) => {
    handleUpdateListProductEnquiry({id, isLike})
    handleSubmitLike(id, isLike)
  }

  const replacePathImg = (url) => {
    if (url) {
      const newUrl = url.replace('/images/productInquiry', '');
      return newUrl;
    }
  }

  return (
    <div className="item-product-inquiry">
      <div className="wrap-item-product-inquiry">
        <div className="inquiry-head">
          <div className="inquiry-user">
            <div className="inquiry-user-img ddd">
              {itemObj?.user_image_path ? (
                <img src={itemObj?.user_image_path} alt="" />
              ) : (
                <img
                  src={AvatarDefault}
                  alt=""
                />
              )}
            </div>
            <div className="left-box-info">
              <div className="inquiry-user-name">{itemObj?.user_name || itemObj?.name}</div>
              <div className="inquiry-time">{itemObj?.created_at}</div>
            </div>
          </div>
          {itemObj?.requested === 1 && (
            <div className="action-edit-delete">
              {/* <span className='icon-dot' aria-hidden onClick={() => handleShowEditDel(itemObj?.id)}> */}
                <img className='icon-dot' aria-hidden src={images.three_dot} alt="" onClick={() => handleShowEditDel(itemObj?.id)}/>
              {/* </span> */}
              <div className={`edit-delete ${showEditDel.isShow && showEditDel.idShow === itemObj?.id ? '' : 'd-none'}`}>
                <div
                  className="action-edit"
                  onClick={() =>
                    history.push(`${ROUTERS.INQUIRY_PRODUCT}/${itemObj?.id}`)
                  }
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex={0}
                >
                  수정
                </div>
                <div
                  className="action-delete pr-0"
                  onClick={() => handleDelete(itemObj)}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex={0}
                >
                  삭제
                </div>
              </div>
            </div>
          )}
        </div>

        
        {itemObj?.image_path && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          // style={{
          //   backgroundImage: `url(${itemObj?.image_path}), url(${replacePathImg(itemObj?.image_path)})`,
          //   backgroundSize: 'cover'
          // }}
          <div
            className="img-product"
            style={{
              backgroundImage: `url(${itemObj?.image_path})`,
            }}
            onClick={() => handleViewImage(itemObj?.image_path)}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          />
        )}

        <div className="inquiry-content-product">{content}</div>

        <div className="inquiry-footer d-flex align-items-center justify-content-between">
          <div className="inquiry-time">
            {/* {itemObj?.created_at} */}
          </div>
          <div className="inquiry-like d-flex align-items-end">
            <div
              className="inquiry-like d-flex align-items-end"
              onClick={() => handleLike(itemObj?.id, itemObj?.is_like)}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
            >
              {itemObj?.is_like === 'false' ? (
                <img src={images.icon_love_off} alt="" />
              ) : (
                <img src={images.icon_love_on} alt="" />
              )}
              <span>{itemObj?.like_cnt}</span>
            </div>

            <div
              className="inquiry-comment d-flex align-items-end"
              onClick={() => handleClickComment(itemObj?.id)}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
            >
              {commentNumberOfUser && commentNumberOfUser.length > 0 ? (
                <img src={images.icon_comment_on} alt="" />
              ) : (
                <img src={images.icon_comment_off} alt="" />
              )}
              <span>{itemObj?.comment_cnt}</span>
            </div>
            
          </div>
        </div>
      </div>
      <div
        className={`list-comment ${
          editCommentId === itemObj?.id ? 'd-block mb-3' : 'd-none'
        }`}
      >
        <div className="list-comment-ovl">{renderListComment}</div>
        <div
          className={`form-addComment ${
            idCommentOfUser ? 'd-none' : 'd-block'
          }`}
        >
          <div className="group-form  d-flex align-items-end">
            <Input
              placeholder=""
              type="text"
              name="content"
              value={contentComment}
              maxLength="50"
              onChange={(e) => handleChange(e.target.value, 'comment')}
              onFocus={handleOnFocusInput}
              onBlur={handleOnBlurInput}
            />
            <div className="login-button">
              <Button
                isDisabled={!contentComment.trim()}
                onClick={() => handleSubmitComment()}
              >
                입력
              </Button>
            </div>
          </div>
        </div>
      </div>

      <span className={`line-item-product ${
        editCommentId !== itemObj?.id ? 'd-block' : 'd-none'
      }`}>
        
      </span>
    </div>
  );
};

export default memo<Props>(ItemProduct);
