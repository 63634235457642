import React, {useEffect, useRef, useState} from 'react';
import MainLayout from 'layout/MainLayout';
import SkeletonLoadingComponent from 'components/SkeletonLoading';
import LazyLoadImg from 'components/LazyLoadImg';
import {API, ROUTES} from "../../../utils/Apis";
import {useHistory, useLocation, useParams} from "react-router-dom";
import moment from "moment";
import ClockIcon from "../../../assets/icons/clock.webp";
import ROUTERS from 'constants/router';
import { checkStatusCodeApi } from 'utils/Helpers';
// import Lottie from 'react-lottie';
type Props = {};

const ConsultionDetailComponent = ({}: Props) => {
  const [item, setItem] = useState({
    loading: true
  })
  const [preview, setPreview] = useState("")
  const ulRef = useRef(null);
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const fetchDetail = async () => {
    try {
      // console.log(28)
      const { data: response } = await API.get(
          `${ROUTES.API_GET_CONSUTANT}/${params.id}`
      );
      console.log(29, response);
      if (response?.code !== 200) {
        checkStatusCodeApi(response.code);
      }
      setItem({loading: false, ...response.data})
    } catch (error) {
      console.error(error.message);
      setItem({...item, loading: false})
    }
  };
  const scrollToRightEnd = () => {
    if (ulRef.current) {
      ulRef.current.scrollLeft = ulRef.current.scrollWidth - ulRef.current.clientWidth;
    }
  };


  useEffect(()=>{
    fetchDetail();

  }, [])

  // useEffect(()=>{
  //   console.log(38, item)
  //   if(item.a_attachments?.length>0){
  //     scrollToRightEnd()
  //   }

  // }, [item])

  const renderQuestion = () => {
    if(item.loading) return <></>

    return <div className="question">
      <div className="info">
        <span>{moment(item.created_at).format('YY.MM.DD')}</span><span>|</span><span>{item.user?.nickname}</span>
      </div>
      <div className="support-heading">
        <span className="q">Q</span> <strong>{item.subject}</strong>
      </div>
      <div className="content" dangerouslySetInnerHTML={{ __html: item.question }} />
      {renderAttachment(item.q_attachments)}
    </div>
  }

  const renderAnswer = () => {
    if(item.loading) return <></>
    if(item.answer && item.state === 'COMPLETED'){
      return <div className="answer">
        <div className="info">
          <span>{moment(item.updated_at).format('YY.MM.DD')}</span><span>|</span><span>관리자</span>
        </div>
        <div className="support-heading">
          <span className="q">A</span>
        </div>
        <div className="content" dangerouslySetInnerHTML={{ __html: item.answer }} />
          {renderAttachment(item.a_attachments)}
      </div>
    }
    return <></>
  }

  const handlePreviewImg = (path) => {
    // console.log(89, path)
    setPreview(path)
  }
  const renderAttachment = (attachments) => {
    if(attachments && attachments.length > 0){
      let html = attachments.map((i, k)=> {
        const imgSrc = process.env.REACT_APP_AWS_PRODUCT_RECENT_PATH.replace(/images\/productRecent\//g, i.path_original.replace(/consultsplay/g, 'consults/play'))
        const imgThumb = process.env.REACT_APP_AWS_PRODUCT_RECENT_PATH.replace(/images\/productRecent\//g, i.path_thumbnail.replace(/consultsplay/g, 'consults/play'))
        return <li onClick={()=>handlePreviewImg(imgSrc)}>
          <img src={imgThumb}/>
        </li>
      })
      return <div className="attachment-safezone">
        <div className="attachments" ref={ulRef}>
          <ul>
          {html}
          </ul>
        </div>
      </div>
    }
    return <></>
  }


  const handleClearPreview = () =>{
    setPreview("")
  }
  const renderPreview = () => {
    if(preview){
      return <div className="preview" onClick={handleClearPreview}>
          <img src={preview}/>
      </div>
    }
    return <></>
  }

  const handleBack = () => {
    // if (location.search && location.search.includes('?type=web-view')) {
    //   history.goBack();
    // } else {
    //   window.backToScreenNative()
    // }

    if (location && location?.search && location?.search.includes('from=recent-activity')) {
      // window.backToScreenByUrlNative('TabOther');
      window.backToScreenNative()
    }else{
      history.push(ROUTERS.CONSULTION);
    }
  }

  window.backFromDeviceAndroid = function() {
    handleBack();
  }

  return (
    <MainLayout
      customClass="MainPage"
      isShowHeader
      titleHeader="1:1 문의 상세"
      icoBackWhite
      isShowIcon
      isLink
      isShowIconBackFunction
      iconBackFunction={() => handleBack()} 
      isLoading={item?.loading || false}
    >
      <div className="consultion-page detail-page">
          {renderQuestion()}
          {renderAnswer()}
      </div>
      {renderPreview()}

    </MainLayout>
  );
};

export default ConsultionDetailComponent;
