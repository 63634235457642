// @flow

import React, { memo, useEffect, useState } from 'react';
import MainLayout from 'layout/MainLayout';
import { useParams  } from 'react-router-dom';
import { checkStatusCodeApi, formatDate, nl2br } from 'utils/Helpers';
import { ModalPopup } from 'components/Modal';
import Button from 'components/Button';
import {API, ROUTES} from "../../../utils/Apis";

const PageAnnoucementDetailComponent = () => {
  const {id} = useParams();
  const [detailData, setDetailData] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isNodata, setIsNodata] = useState(false);
  const [isShowModalView, setIsShowModalView] = useState(false);
  const [imagesView, setImagesView] = useState(false);
  
  useEffect(() => {
    API.get(`${ROUTES.API_DETAIL_NOTIFICATION}?id=${id}&type=pay_app&is_now=1`)
      .then((res) => {
        if (res?.data?.code === 401) {
          checkStatusCodeApi(res?.data?.code || null)
        }else{
          setIsLoading(false)
          setDetailData(res.data?.data)
        }

      })
      .catch((e) => {
        setIsLoading(false)
      })
  }, [id])

  useEffect(() => {
    if (detailData !== '' && !isLoading && !detailData?.id) {
      setIsNodata(true);
    }
  }, [detailData, isLoading])

  const handleConfirm = () => {
    setIsNodata(false);
    window.backToScreenNative()
  }

  const backHeader = () => {
    window.backToScreenByUrlNative('Notification');
  }

  const showModalImgView = (item) => {
    setImagesView(item);
    setIsShowModalView(true);
  }

  const renderImgDetail = (data) => {
    if (data.length > 0) {
      return data.map((item, idx) => {
        return <>
            <img onClick={() => showModalImgView(item?.path_thumbnail)} src={item?.path_thumbnail} alt="img" />
        </>
      })
    }
  }
  
  return (
    <MainLayout
      isMarginBottom
      isShowHeader
      titleHeader="공지사항 상세"
      isCenter
      isShowIcon
      isLink
      customClass="faq-detail-page"
      isLoading={isLoading}
      customClassHeader="border-bottom"
      isBackNative
      fontWeight={600}
      // isShowIconBackFunction
      // iconBackFunction={backHeader}
    >
      {!isLoading && detailData?.title && (
        <div className="wrapper-page-faq faq-detail">
          <div className="content">
            <div className="title">
              <p className="title-text">{detailData?.title || ''}</p>
              <span className="time">
                {detailData?.created_at ? formatDate(detailData?.created_at) : ''}
              </span>
            </div>

            <div className="reply">
              <div
                className="reply-text"
                dangerouslySetInnerHTML={{ __html: nl2br(detailData?.content  || '') }}
              />

              <div className="img-detail">
                {
                  renderImgDetail(detailData.attachment)
                }
                
              </div>
            </div>
          </div>
        </div>
      )}

      <ModalPopup
        isOpen={isNodata}
        customClass="no-data-annoucement"
      >
        <div className="content">
          <h3>존재하지 않는 게시물입니다.</h3>
          <div className="group-button-popup">
            <Button
              type="button"
              customClass='button--half'
              onClick={() => handleConfirm()}
            >
              확인
            </Button>
          </div>
        </div>
      </ModalPopup>

      <ModalPopup
        isOpen={isShowModalView}
        isShowIconClose
        handleClose={() => setIsShowModalView(false)}
        handleCloseOvl={() => setIsShowModalView(false)}
        customClass="view-product-popup"
      >
        <div className='review-modal'>
          <div className="img-review">
            <img className='img' src={imagesView} alt="" />
          </div>
        </div>
      </ModalPopup>

    </MainLayout>
  );
};

export default memo(PageAnnoucementDetailComponent);
