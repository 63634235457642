import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from '../../utils/Apis';

import { Types } from '../redux';

// worker Saga: will be fired on VERIFY_SMS_CODE actions
function* findPassword(action) {
  try {
    /**
     * Example data
     * url: enpoint/register/check-confirm-code
     * params:
     *  {
     *    code: '123456'
     *  }
     *
     */
    const response = yield call(() =>
      API.post(ROUTES.API_FIND_PASSWORD, JSON.stringify(action.info))
    );

    if (response.ok) {
      const { data } = response;
      // In case: get sms code request success
      yield put({ type: Types.FIND_PASSWORD_SUCCESS, data });
    } else {
      // In case: get sms code request failed
      yield put({
        type: Types.FIND_PASSWORD_FAILED,
        errors: response.data && response.data,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.FIND_PASSWORD_FAILED, error });
  }
}

/*
  Starts signupAccount on each dispatched `VERIFY_SMS_CODE` action.
*/
function* findPasswordSaga() {
  yield takeLatest(Types.FIND_PASSWORD, findPassword);
}

export default findPasswordSaga;
