import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from 'utils/Apis';

import { Types } from '../redux';

function* getDataMaintenance() {
  console.log('getDataMaintenance------------------');
  try {
    const response = yield call(() =>
      API.get(ROUTES.API_MAINTENANCE, { ran: Math.random() })
    );
    const data = response?.data;
    if (response.ok) {
      // In case: get sms code request success
      yield put({ type: Types.GET_DATA_MAINTENANCE_SUCCESS, data });
    } else {
      // In case: get sms code request failed
      yield put({
        type: Types.GET_DATA_MAINTENANCE_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.GET_DATA_MAINTENANCE_FAILED, error });
  }
}

function* getDataMaintenanceSaga() {
  console.log('getDataMaintenance------------------');
  yield takeLatest(Types.GET_DATA_MAINTENANCE, getDataMaintenance);
}

export default getDataMaintenance;
