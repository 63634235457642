/* eslint-disable camelcase */
import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from 'utils/Apis';

import { Types } from '../redux';

// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* deleteProductRecent(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */

    const response = yield call(() =>
      API.post(ROUTES.API_DELETE_PRODUCT, action.productId)
    );

    if (response.ok) {
      const { data } = response;
      // In case: signup request success
      yield put({
        type: Types.DELETE_PRODUCT_RECENT_SUCCESS,
        data,
        productId: action?.productId?.recent_product_id,
      });
    } else {
      yield put({
        type: Types.DELETE_PRODUCT_RECENT_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.DELETE_PRODUCT_RECENT_FAILED, error });
  }
}

/*
  Starts deleteProductRecent on each dispatched `DELETE_PRODUCT_RECENT` action.
*/
function* deleteProductRecentSaga() {
  yield takeLatest(Types.DELETE_PRODUCT_RECENT, deleteProductRecent);
}

export default deleteProductRecentSaga;
