// @flow
// libs
import React, { memo } from 'react';
import IMAGES from 'themes/images';
import { ReactComponent as ImageNoData } from '../../assets/icons/icon_nodata.svg'

type Props = {
  text?: string,
  children?: any,
};

export const NoData = ({
  children = '',
  text = '공지사항이 없습니다.',
}: Props) => {
  return (
    <div className="non-data">
      <div>
        {/* <img src={IMAGES.icon_no_data} alt="" /> */}
        <ImageNoData />
        <p>{text}</p>
        {children && <div>{children}</div>}
      </div>
    </div>
  );
};

NoData.defaultProps = {
  children: '',
  text: '검색 결과가 없습니다.',
};

export default memo<Props>(NoData);
