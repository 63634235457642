import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'swiper/swiper.min.css';
import 'swiper/swiper.scss';
import './App.scss';
import Router from './router';
import createStore from './store/createStore';

function App() {
  const { store, persistor } = createStore();
  // window.getUserInfoNative()
  // getUserInfoNative();
  // alert('dataInfo');
  // window.setUserInfo = function(dataInfo){
  //   document.getElementById('js-2').innerHTML = 'setUserInfo'
  //   const dataParse = JSON.parse(dataInfo); 
  //   const data = {
  //     token: `${dataParse.token}`,
  //     user: {}
  //   }
  //   store.dispatch(
  //     { type: 'SET_INFO_FROM_APP', data }
  //   )
  // }

  return (
    <div className="App">
      <PersistGate loading={null} persistor={persistor}>
        <Provider store={store}>
          <Router />
        </Provider>
      </PersistGate>
    </div>
  );
}

export default App;
