// import libs
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

// Define action creators
export const { Types, Creators } = createActions({
  addPointNumber: null,
  addPointNumberSuccess: null,
  addPointNumberFailed: null,

  getPointNumber: ['obj'],
});

// Initial state
export const INITIAL_STATE = Immutable({
  isProcessing: false,
  errors: '',
  pointNumber: { current: 0, pointNew: 0, totalPoint: 0 },
});

const addPointNumber = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

const addPointNumberSuccess = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

const addPointNumberFailed = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

const getPointNumber = (state, action) => {
  const { obj } = action;
  return state.merge({
    pointNumber: obj,
  });
};

// Assign handler to types.
const HANDLERS = {
  [Types.ADD_POINT_NUMBER]: addPointNumber,
  [Types.ADD_POINT_NUMBER_SUCCESS]: addPointNumberSuccess,
  [Types.ADD_POINT_NUMBER_FAILED]: addPointNumberFailed,

  [Types.GET_POINT_NUMBER]: getPointNumber,
};

// Create reducers by pass state and handlers
export const addPointReducer = createReducer(INITIAL_STATE, HANDLERS);
