import MainLayout from "../../../layout/MainLayout";
import React, {useEffect, useRef, useState} from "react";
import {API, ROUTES} from "../../../utils/Apis";
import {uuid4} from "@capacitor/core/dist/esm/util";
import XIcon from 'assets/icons/x.svg';
import ROUTERS from "../../../constants/router";
import {useHistory} from "react-router-dom";
import {ModalPopup} from "../../../components/Modal";
import AvatarDefault from '../../../assets/icons/avatar_default.svg';
import { checkStatusCodeApi } from "utils/Helpers";

type Props = {};
const ConsutantCreateComponent = ({}: Props) => {
    const [formData, setFormData] = useState({
        loading: false,
        data: {},
        errors: [],
        files: [],
    })
    const [profile, setProfile] = useState({})
    const [alert, setAlert] = useState("")
    const inputFileRef = useRef();
    const history = useHistory();

		useEffect(() => {
			window.setPermissionCamera = function(data) {
				window.permissionCamera = data;
				if (data) {
					document.getElementById('btnUploadFile').classList.remove("d-none");
					// document.getElementById('btnUploadFile').click();
					inputFileRef.current.click()
				}
				// setIsCheckPermission(data);
			}
	
			return () => {
				window.setPermissionCamera = undefined
			}
		});

		const handlePermission = (e) => {
			if (!window?.permissionCamera || window?.permissionCamera === 'false') {
				window.callRequestPermissionNative();
			}else{
				inputFileRef.current.click()
				// document.getElementById('btnUploadFile').classList.remove("d-none")
			}
	
		}

    const renderInputError = (inputName) => {
        let html = <></>
        if(formData.errors?.length > 0) {
            formData.errors.forEach((item) => {
                if (item.field == inputName) html = <p className="error">{item.error}</p>
            })
        }

        return html
    }

    const handleChangeInput = (e) => {
			if (e.target.name === 'subject' && e.target.value.length > 20)  return;
			if (e.target.name === 'question' && e.target.value.length > 1000)  return;
        let _data = formData.data
        let _error = []
        _data = {..._data, [e.target.name]: e.target.value}
        if(formData.errors?.length > 0){
            // eslint-disable-next-line consistent-return
            formData.errors.forEach((er)=> {
                if(er.field != e.target.name) _error.push(er)
            })
        }
        console.log(36, _data, _error)
        setFormData({...formData, data: _data, errors: _error})
    }

    const handleChangeFile = (e) => {
        const file = e.target.files[0];
        console.log(51, file.size)
        let _files = formData.files;
        if(_files.length < 3) {
            if (file) {
                if(file.size <= 10485760) {
                    // Tạo FileReader để đọc file dưới dạng base64
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const base64String = reader.result;

                        let _file = {
                            id: uuid4(),
                            thumb: base64String,
                            file: file
                        }
                        _files.push(_file)
                        setFormData({...formData, files: _files})
                        inputFileRef.current.value = null;
                    };
                    reader.readAsDataURL(file);
                }else{
                    setAlert("ERROR")
                }
            }
        }
        else {
            console.log("LIMIT FILES")
        }
    }

    const handleRemoveAttachment = (id) => {
        console.log(id)
        let _files = [];
        formData.files.forEach((file)=>{
            if(file.id != id) _files.push(file)
        })
        setFormData({...formData, files: _files})
    }

    const handleSubmitSuccess = () => {
        const url = ROUTERS.CONSULTION;
        // type=web-view(web-view: back to list) or nothing (back to native)
        history.push(`${url}?type=web-view`);
    }

    const handleSubmitForm = async () => {
        try {
            setFormData({...formData, loading: true})
            let _formData = new FormData()
            Object.keys(formData.data).forEach((k) => {
                _formData.append(k, formData.data[k])
            })
            if(formData.files?.length > 0){
                let _files = []
                formData.files.forEach((file) => {
                    // _files.push(file.file)
                    _formData.append('attachment[]', file.file)
                })
                // _formData.append('attachment', _files)
            }

            const response = await API.post(
                ROUTES.API_GET_CONSUTANT,
                _formData
            );
            console.log(23, response.data)
            checkStatusCodeApi(response.data.code);
            let stateData
            if(response.data.code == 200){
                stateData = {
                    loading: false,
                    data: {},
                    errors: [],
                    files: []
                }
                handleSubmitSuccess()
                setFormData(stateData)
            }else{
                
                stateData = {
                    ...formData,
                    loading: false,
                    errors: response.data.errors,
                }
                console.log(73, stateData)
                setFormData(stateData)
            }


        } catch (error) {
            console.error(56, error.message);
            setFormData({...formData, loading: false})
        }
    }


    const renderFilesThumb =()=>{
        if(formData.files.length > 0){
            return formData.files.map((file, k)=>{
                return <>
                    <div className="file-item">
                        <div className="closebtn" onClick={()=>handleRemoveAttachment(file.id)}><img src={XIcon}/></div>
                        <div className="thumb">
                            <img src={file.thumb} alt=""/>
                        </div>
                    </div>
                </>
            })
        }
    }


    useEffect(()=>{
        // console.log(63, formData, profile)
    }, [formData, profile])

    useEffect(()=>{
        try {
            let _profile = localStorage.getItem('DATA_INFO')
            if (_profile) _profile = JSON.parse(_profile)
            setProfile(_profile)

        } catch (e) {
            console.log(e)
        }
    }, [])

    return (
        <MainLayout
            customClass="MainPage"
            isShowHeader
            titleHeader="문의 작성"
            icoBackWhite
            isShowIcon
            isLink
            isLoading={formData.loading}
        >
            <div className="consultion-page create-page">
                <div className="user-info">
                    <span className="avatar">
											<img src={profile?.profile?.picture ? profile?.profile?.picture?.thumbnail : AvatarDefault}/>
                    </span>
                    <span className="title">{profile?.profile?.nickname}</span>
                </div>
                <form>
                    <div className="row">
                        <div className="col-sm-12">
                            <input value={formData.data.subject} maxLength="20" name={'subject'} onChange={handleChangeInput} type="text" className="form-control" placeholder="제목을 입력해 주세요 (최대 20자)"/>
                            {renderInputError('subject')}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <textarea value={formData.data.question} maxLength="1000" name={'question'} onChange={handleChangeInput} rows={10} className="form-control" placeholder="내용을 입력해 주세요 (최대 1,000자)"/>
                            {renderInputError('question')}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
														<input 
															type="file" 
															hidden 
															id="btnUploadFile" 
															disabled={formData.files?.length >= 3} ref={inputFileRef} 
															onChange={handleChangeFile}
															accept="image/png, image/jpeg, image/jpg, image/heif, image/heic, image/gif, image/raw, image/tiff"
															/>
															
                            <div className="thumbs">
                                <label className="image-picker" onClick={() => handlePermission()}>
                                    <span>이미지 추가</span>
                                </label>
                                {renderFilesThumb()}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="bottom-panel">
                <button disabled={formData.loading} onClick={handleSubmitForm}>등록</button>
            </div>
            <ModalPopup
                isOpen={alert}
                isShowHeader={false}
                isShowFooter={false}
                handleClose={() => {}}
                handleSubmit={() =>{}}
                textBtnRight="확인"
                z
                customClass="modal-filesize-validate"
            >
                <div className="content">
                    이미지 등록에 실패했습니다.<br/>
                    최대 10MB이하 이미지만<br/>
                    등록 가능합니다.
                    <button onClick={()=>{
                        setAlert("")
                    }}>확인</button>
                </div>
            </ModalPopup>
        </MainLayout>
    );
}

export default ConsutantCreateComponent