import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from 'utils/Apis';
import { checkStatusCodeApi } from 'utils/Helpers';

import { Types } from '../redux';

// worker Saga: will be fired on GET_SMS_CODE actions
function* getDataGroupBuyingHistoryPage(action) {
  // console.log("--------------action", action);
  try {
    /**
     * Example data
     * url: enpoint/register/send-confirm-code
     *
     */
    const response = yield call(() =>
      API.get(ROUTES.API_GROUP_BUYING_HISTORY, {
        ...action.history,
        ran: Math.random()
      })
    );
    const data = response?.data;
    if (response.ok) {
      // In case: get sms code request success
      yield put({ type: Types.GET_DATA_GROUP_BUYING_HISTORY_SUCCESS, data });
    } else {
      // In case: get sms code request failed
      checkStatusCodeApi(data?.code || null)
      yield put({
        type: Types.GET_DATA_GROUP_BUYING_HISTORY_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.GET_DATA_GROUP_BUYING_HISTORY_FAILED, error });
  }
}

function* getDataGroupBuyingPage(action) {
  try {
    /**
     * Example data
     * url: enpoint/register/send-confirm-code
     *
     */
    // console.log(action);
    const response = yield call(() =>
        API.get(ROUTES.API_GROUP_BUYING, {
          ...action.data,
          ran: Math.random()
        })
    );
    const data = response?.data;
    if (response.ok) {
      // In case: get sms code request success
      yield put({ type: Types.GET_DATA_GROUP_BUYING_SUCCESS, data });
    } else {
      // In case: get sms code request failed
      checkStatusCodeApi(data?.code || null)
      yield put({
        type: Types.GET_DATA_GROUP_BUYING_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.GET_DATA_GROUP_BUYING_FAILED, error });
  }
}

function* getDataStoreEventPage(action) {
  // console.log(action,'getDataStoreEventPage')
  try {
    /**
     * Example data
     * url: enpoint/register/send-confirm-code
     *
     */
    const response = yield call(() =>
        API.get(ROUTES.API_GET_EVENT_STORE, {
          ran: Math.random(),
          lat: action?.location?.lat || "",
          lng: action?.location?.lng || "",
          event_id: action?.location?.event_id || "",
        })
    );
    const data = response?.data;
    if (response.ok) {
      // In case: get sms code request success
      yield put({ type: Types.GET_DATA_EVENT_STORE_SUCCESS, data });
    } else {
      // In case: get sms code request failed
      checkStatusCodeApi(data?.code || null)
      yield put({
        type: Types.GET_DATA_EVENT_STORE_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.GET_DATA_EVENT_STORE_FAILED, error });
  }
}

/*
  Starts signupAccount on each dispatched `SIGN_IN` action.
*/
function* getDataGroupBuyingPageSaga() {
  yield takeLatest(Types.GET_DATA_GROUP_BUYING_HISTORY, getDataGroupBuyingHistoryPage);
  yield takeLatest(Types.GET_DATA_GROUP_BUYING, getDataGroupBuyingPage);
  yield takeLatest(Types.GET_DATA_EVENT_STORE, getDataStoreEventPage);
}

export default getDataGroupBuyingPageSaga;
