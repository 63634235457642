import React, { Fragment } from 'react';
import useCountdown from './../../customHooks/useCountdown';
// import moment from 'moment-timezone'
import moment from 'moment';

const TIME_ZONE = 'Asia/Seoul';

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <Fragment>
      {value}
      {/*{type}*/}
    </Fragment>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <Fragment>
      <DateTimeDisplay
        value={hours + days * 24}
        type={'Hours'}
        isDanger={false}
      />
      :
      <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
      :
      <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
    </Fragment>
  );
};

const TimeCounter = ({ endTime = {} }) => {
  // time to end award
  const now = moment().format('Y/MM/DD H:mm:ss');
  const end = moment(endTime).format('Y/MM/DD H:mm:ss');
  const endedAt = Math.floor(
    (new Date(end).getTime() - new Date(now).getTime()) / 1000
  );
  const startCountDown = true;
  const [time] = useCountdown(endedAt, startCountDown);
    return (
    <ShowCounter
      days={time.days}
      hours={time.hours}
      minutes={time.minutes}
      seconds={time.seconds}
    />
  );
};

export default TimeCounter;
