// @flow
import React, { memo } from 'react';
import { connect } from 'react-redux';
// import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';

import footerTabs from '../../constants/footerTab';

type Props = {
  onChangeTab: Function,
  activeTab?: number,
  // token: string,
};

const Footer = ({ onChangeTab, activeTab }: Props) => {
  const history = useHistory();
  // const isAuthenticated = token !== '';
  // let footerTabUpdate = [];
  // if (!isAuthenticated) {
  //   footerTabUpdate = footerTabs.map((item) =>
  //     item.key === 1 ? { ...item, link: '' } : { ...item, disable: true }
  //   );
  // } else {
  //   footerTabUpdate = footerTabs;
  // }
  const renderItemTab = footerTabs.map((tab) => {
    const isActive = activeTab === tab.key;
    return (
      <div
        className={`footer__tab ${
          activeTab === tab.key ? 'footer__tab--active' : ''
        } ${tab.disable ? 'disable-tab' : ''}`}
        key={tab.key}
        onClick={() => {
          if (!isActive) {
            onChangeTab(tab);
          }
        }}
        onKeyUp={() => {
          if (!isActive) {
            onChangeTab(tab);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <div
          onClick={() => (tab ? history.push(tab.link) : {})}
          className="footer-link"
        >
          {activeTab === tab.key ? (
            <img src={tab.classAction} alt="" />
          ) : (
            <img src={tab.class} alt="" />
          )}
        </div>
      </div>
    );
  });

  return <footer className="container-fluid footer">{renderItemTab}</footer>;
};

Footer.defaultProps = {
  activeTab: '',
};

const mapStateToProps = (state) => {
  return {
    token: state.accountReducer.token,
  };
};

export default connect(mapStateToProps, null)(memo<Props>(Footer));
