
import React, { memo }  from 'react';

function  MarketingInformationComponent() {
  return (
    <>
      <div>
        <div className='content text-right'>제정 2020.12.18</div>
        <div className='content'>
          주식회사 문구야놀자는 개인정보보호법 등에 관한 법률 등 관계법령에 따라 광고성 정보를 전송하기 위해 수신자의 사전 수신동의를 받고 있으며, 광고 성 정보 수신자의 수신동의여부를 정기적 으로 확인합니다. 다만 동의하지 않을 경우, 상품/서비스 소개 및 권유, 사은행사, 판촉행사 등 이용목적에 따른 혜택의 제한이 있을 수 있습니다. 그 밖에 금융 거래와 관련된 불이익은 없습니다.
        </div>
      </div>

      <div>
        <h2 className='title'>● 전송방법</h2>
        <div className='content'>
          고객님의 핸드폰 문자메시지(SMS), Email, 앱 푸시 등을 통해 전달될 수 있습니다.
        </div>
      </div>

      <div>
        <h2 className='title'>● 전송내용</h2>
        <div className='content'>
        발송되는 마케팅 정보는 수신자에게 ㈜문구야놀자가 운영하는 서비스에서 제공하는 혜택 정보, 각종 이벤트 정보, 상품 정보, 신규 서비스 안내 등 광고성 정보로 관련 법의 규정을 준수하여 발송됩니다. 단, 광고성 정보 이외 의무적으로 안내되어야 하는 정보성 내 용은 수신동의 여부와 무관하게 제공됩니다.
        </div>
      </div>

      <div>
        <h2 className='title'>● 철회안내</h2>
        <div className='content'>
          고객님은 수신 동의 이후에라도 의사에 따라 동의를 철회할 수 있으며, 수신을 동의하지 않아도 회사가 제공하는 기본적인 서비스를 이용할 수 있으나, 당사의 마케팅 정보를 수신하지 못할 수 있습니다.
        </div>
      </div>

      <div>
        <h2 className='title'>● 수신동의 변경</h2>
        <div className='content'>
          고객센터(02-6356-1500)를 통해 수신동의를 변경(동의/철회)할 수 있습니다.
        </div>
      </div>

      <div>
        <h2 className='title'>● 개인정보 이용 상세내용</h2>
        <div className='content'>
          휴대전화번호, 이메일주소
        </div>
      </div>

      <div>
        <h2 className='title'>● 이용하는 개인정보 항목 보유 이용 목적</h2>
        <div className='content'>
          혜택 정보, 각종 이벤트 정보, 상품 정보, 신규 서비스 안내 등 광고 성 정보 제공
        </div>
      </div>

      <div>
        <h2 className='title'>● 개인정보 보유 및 이용 기간</h2>
        <div className='content'>
          마케팅 활용 동의일로부터 회원 탈퇴 또는 마케팅 동의 철회 시까지 보유 및 이용
        </div>
      </div>
    </>
  ) 
}

export default memo(MarketingInformationComponent)