// @flow

import React, { memo } from 'react';
import MainLayout from 'layout/MainLayout';
import TermsConditionsRegisterComponent from './TermsConditionsRegisterComponent';
import CollectionUsePersonalRegisterComponent from './CollectionUsePersonalRegisterComponent';
import ElectronicFinancialRegisterComponent from './ElectronicFinancialRegisterComponent';
import EmaiSMSRegisterComponent from './EmaiSMSRegisterComponent';
import PrivacyPolicyComponent from './PrivacyPolicyComponent';
import MarketingInformationComponent from './MarketingInformationComponent';
import LocationBaseServicesComponent from './LocationBaseServicesComponent';
import { useState } from 'react';
import { useEffect } from 'react';
import { API, ROUTES } from 'utils/Apis';
import { useParams } from 'react-router';

import review1_guide from '../../../assets/images/review1_guide.png';
import review2_guide from '../../../assets/images/review2_guide.png';
import review3_guide from '../../../assets/images/review3_guide.png';
import review4_guide from '../../../assets/images/review4_guide.png';

type Props = {
  history: {
    push: Function,
  },
};

const PagePolicyWebComponent = ({ history }: Props) => {

  // eslint-disable-next-line react/prop-types
  const searchParams = new URLSearchParams(history.location?.search || null);

  const type = searchParams.get('type') || 'TERM_USE_SERVICE';
  const [detailData, setDetailData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true)
    API.get(`${ROUTES.API_DETAIL_TERMS}?type=${type}`)
      .then((res) => {
        setDetailData(res.data?.data?.data[0])
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
      })
  }, [type])

  // console.log(detailData, 'detailData');
  // eslint-disable-next-line consistent-return
  const renderDetail = (type) => {
    switch (type) {
      case 'TERM_USE_SERVICE': //TERM_USE_SERVICE
        // https://docs.google.com/spreadsheets/d/1iJEccfMLryX296umwhWruYDfao0EiD0tNTQhFJ1HWBk/edit#gid=1152304882
        return <TermsConditionsRegisterComponent data={detailData}/>;
      case 'POLICY_COLLECT_PERSONAL_INFORMATION': //POLICY_COLLECT_PERSONAL_INFORMATION
        // https://docs.google.com/spreadsheets/d/1iJEccfMLryX296umwhWruYDfao0EiD0tNTQhFJ1HWBk/edit#gid=621393974
        return <CollectionUsePersonalRegisterComponent data={detailData}/>;
      case 'TERM_USE_ELECTRONIC_FINANCIAL': //TERM_USE_ELECTRONIC_FINANCIAL
        // https://docs.google.com/spreadsheets/d/1iJEccfMLryX296umwhWruYDfao0EiD0tNTQhFJ1HWBk/edit#gid=676755498 
        return <ElectronicFinancialRegisterComponent data={detailData}/>;
      case 'SUPPLY_INFORMATION_TO_THIRD_PARTIES': //SUPPLY_INFORMATION_TO_THIRD_PARTIES
        // https://docs.google.com/spreadsheets/d/1iJEccfMLryX296umwhWruYDfao0EiD0tNTQhFJ1HWBk/edit#gid=243323246
        return <EmaiSMSRegisterComponent data={detailData}/>;
      case 'POLICY_HANDLE_PERSONAL_INFORMATION': //POLICY_HANDLE_PERSONAL_INFORMATION
        // https://docs.google.com/spreadsheets/d/1iJEccfMLryX296umwhWruYDfao0EiD0tNTQhFJ1HWBk/edit#gid=1149810823
        return <PrivacyPolicyComponent data={detailData}/>;
      case 'RECEIVE_MARKETING_INFORMATION': //RECEIVE_MARKETING_INFORMATION
        // https://docs.google.com/spreadsheets/d/1iJEccfMLryX296umwhWruYDfao0EiD0tNTQhFJ1HWBk/edit#gid=1296227739
        return <MarketingInformationComponent data={detailData}/>;
      case 'TERM_USE_SERVICE_LOCATION': //TERM_USE_SERVICE_LOCATION
        // https://docs.google.com/spreadsheets/d/1iJEccfMLryX296umwhWruYDfao0EiD0tNTQhFJ1HWBk/edit#gid=1126812301
        return <LocationBaseServicesComponent data={detailData}/>;
      default:
        return <TermsConditionsRegisterComponent />;
    }
  }
  

  return (
    <MainLayout
      customClassHeader='border-bottom'
    >
      <div className="wrapper-page-web-policy">
        <div className="content-policy">
          <h1>정보 삭제</h1>
          <div className="content">
            <a href="/privacy-policy?type=POLICY_COLLECT_PERSONAL_INFORMATION" target='_blank'>
            "정보 수집 정책" 
            </a>
            {" "}약관에 따라 법적 근거로 필요하고 해당 법률에서 허용하는 기간 동안만 귀하의 개인 정보를 보관합니다. 
            보관 정책에 대해 알아보려면{" "}
            <a href="/privacy-policy?type=POLICY_HANDLE_PERSONAL_INFORMATION" target='_blank'>
            "개인정보 처리방침"
            </a>
            {" "}을 참조해주시기 바랍니다. 회원 탈퇴하고 시스템의 데이터 정보를 삭제 진행할 수 있습니다. 
            회원 탈퇴는 다음과 같이 진행해주시면 됩니다: 
          </div>
          <h2 className="title">1. 플레이페이 계정으로 로그인</h2>
          <div className="img">
            <img src={review1_guide} alt="" />
          </div>
          <h2 className="title">2. 화면 우측 상단 "더보기"버튼 선택하여 Other 화면으로 진입</h2>
          <div className="img">
            <img src={review2_guide} alt="" />
          </div>
          <h2 className="title">3. Other 화면 우측 상단 "설정"아이콘 선택</h2>
          <div className="img">
            <img src={review3_guide} alt="" />
          </div>
          <h2 className="title">4. 회원탈퇴 메뉴 선택</h2>
          <div className="img">
            <img src={review4_guide} alt="" />
          </div>
          <div className="mb-1 guide">
          귀하의 계정이 삭제 완료 되면 앱 내 더이상 확인할 수 없다는 것으로 보장합니다.<br /> 
          실수로 삭제될 경우에 최대 30일 내 계정 복구 가능합니다. 30일 이후 저희 시스템에  다음과 같은 케이스들 제외하여 귀하의 개인 정보가 삭제 됩니다. 
          </div>
          <div className="content">
            1. 현행 법률을 따라 데이터를 저장해야 할 경우 (예: 저희 앱에 상품 구매 진행하면 정산 또는 세금 관련 목적으로 인해 저장되어야 할 일부 개인 데이터가 있습니다) 
          </div>
          <div className="content">
            2. 현행 법률을 준수하고 있다는 증거로 해당 정보를 보관해야 할 경우 (예: 차단되는 계정이 있을 시 법적 문의나 신고가 있을 경우 해당 계정이 차단 처리 되게 만든 행위 관련된 서류 및 계정정보들 보관하여 증거 남깁니다.) 
          </div>
          <div className="content">
            3. 문제 또는 클레임 발생하여 문제 완전히 해결 될때까지 관련 정보들 보관합니다 
          </div>
          <div className="content">
            4. 사기 방지 및 사용자 안전과 보안 강화등.. 저희의 합법적인 운영이익을 위해 정보가 보관되어야 할 경우 (예: 보안사고 또는 안전하지 않은 행위가 확인 된 사용자에게 신규 계정으로 재 가입 방지하기 위해 보관합니다)
          </div>
          <br />
          <h2 className="warning">*유의사항:</h2> 
          <div className="content">
          본인 프로필에서 정보 삭제 또는 계정 삭제했어도 정보는 기존에 다른 사람에게 공유되거나 다른사람이 복사하여 보관하고 있었다면 해당 정보의 복사본을 볼수 있거나 접속 할 가능성이 있습니다. 저희는 이에 대한 통제권이 없으며 책임도 지지 않습니다. 귀하가 제3자 앱 또는 웹사이트에 귀하의 개인 정보에 대한 액세스 권한을 부여한 경우 서비스 약관 또는 개인 정보 보호 정책에서 허용하는 범위 내에서 제3자가 해당 정보를 보유할 수 있습니다.
          </div>
          <br /><br /><br />
          <hr />
          <div className="content text-right">
            <p>&copy; Copyright 2023, [문구야놀자] - 회사 [(주)문구야놀자]</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default memo<Props>(PagePolicyWebComponent);
