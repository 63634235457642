import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FindStoreLikeComponent from '../components/storeLike';
import { Creators } from '../redux';

const mapStateToProps = (state) => {
  console.log(state.searchReducer.isProcessingAllStore, 'state.searchReducer.isProcessingAllStore');
  return {
    listStoreLike: state.searchReducer.listStoreLikeOfUser,
    type: state.searchReducer.type,
    isProcessing: state.searchReducer.isProcessingAllStore,
    isProcessingRemove: state.searchReducer.isProcessingRemove,
    listLocationStore: state.searchReducer.listLocationStore,
    listAllStore: state.searchReducer.listAllStore,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...Creators,
      getListFindStore: Creators.getListFindStore,
      getListAllStore: Creators.getListAllStore,
      removeStoreLikeById: Creators.removeStoreLikeById
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FindStoreLikeComponent);
