// @flow

import React, { memo } from 'react';
import MainLayout from 'layout/MainLayout';
import TermsConditionsRegisterComponent from './TermsConditionsRegisterComponent';
import CollectionUsePersonalRegisterComponent from './CollectionUsePersonalRegisterComponent';
import ElectronicFinancialRegisterComponent from './ElectronicFinancialRegisterComponent';
import EmaiSMSRegisterComponent from './EmaiSMSRegisterComponent';
import PrivacyPolicyComponent from './PrivacyPolicyComponent';
import MarketingInformationComponent from './MarketingInformationComponent';
import LocationBaseServicesComponent from './LocationBaseServicesComponent';
import { useState } from 'react';
import { useEffect } from 'react';
import { API, ROUTES } from 'utils/Apis';
import { useParams } from 'react-router';

type Props = {
  history: {
    push: Function,
  },
};

const PagePrivacyPolicyComponent = ({ history }: Props) => {

  // eslint-disable-next-line react/prop-types
  const searchParams = new URLSearchParams(history.location?.search || null);

  const type = searchParams.get('type') || 'TERM_USE_SERVICE';
  const [detailData, setDetailData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true)
    API.get(`${ROUTES.API_DETAIL_TERMS}?type=${type}`)
      .then((res) => {
        setDetailData(res.data?.data?.data[0])
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
      })
  }, [type])

  // console.log(detailData, 'detailData');
  // eslint-disable-next-line consistent-return
  const renderDetail = (type) => {
    switch (type) {
      case 'TERM_USE_SERVICE': //TERM_USE_SERVICE
        // https://docs.google.com/spreadsheets/d/1iJEccfMLryX296umwhWruYDfao0EiD0tNTQhFJ1HWBk/edit#gid=1152304882
        return <TermsConditionsRegisterComponent data={detailData}/>;
      case 'POLICY_COLLECT_PERSONAL_INFORMATION': //POLICY_COLLECT_PERSONAL_INFORMATION
        // https://docs.google.com/spreadsheets/d/1iJEccfMLryX296umwhWruYDfao0EiD0tNTQhFJ1HWBk/edit#gid=621393974
        return <CollectionUsePersonalRegisterComponent data={detailData}/>;
      case 'TERM_USE_ELECTRONIC_FINANCIAL': //TERM_USE_ELECTRONIC_FINANCIAL
        // https://docs.google.com/spreadsheets/d/1iJEccfMLryX296umwhWruYDfao0EiD0tNTQhFJ1HWBk/edit#gid=676755498 
        return <ElectronicFinancialRegisterComponent data={detailData}/>;
      case 'SUPPLY_INFORMATION_TO_THIRD_PARTIES': //SUPPLY_INFORMATION_TO_THIRD_PARTIES
        // https://docs.google.com/spreadsheets/d/1iJEccfMLryX296umwhWruYDfao0EiD0tNTQhFJ1HWBk/edit#gid=243323246
        return <EmaiSMSRegisterComponent data={detailData}/>;
      case 'POLICY_HANDLE_PERSONAL_INFORMATION': //POLICY_HANDLE_PERSONAL_INFORMATION
        // https://docs.google.com/spreadsheets/d/1iJEccfMLryX296umwhWruYDfao0EiD0tNTQhFJ1HWBk/edit#gid=1149810823
        return <PrivacyPolicyComponent data={detailData}/>;
      case 'RECEIVE_MARKETING_INFORMATION': //RECEIVE_MARKETING_INFORMATION
        // https://docs.google.com/spreadsheets/d/1iJEccfMLryX296umwhWruYDfao0EiD0tNTQhFJ1HWBk/edit#gid=1296227739
        return <MarketingInformationComponent data={detailData}/>;
      case 'TERM_USE_SERVICE_LOCATION': //TERM_USE_SERVICE_LOCATION
        // https://docs.google.com/spreadsheets/d/1iJEccfMLryX296umwhWruYDfao0EiD0tNTQhFJ1HWBk/edit#gid=1126812301
        return <LocationBaseServicesComponent data={detailData}/>;
      default:
        return <TermsConditionsRegisterComponent />;
    }
  }
  

  return (
    <MainLayout
      customClassHeader='border-bottom'
    >
      <div className="wrapper-page-privacy-policy">
        <div className="list-policy">
          <ul>
            <li>
              <span>1.</span>
              <a href="/privacy-policy?type=TERM_USE_SERVICE" className={type==='TERM_USE_SERVICE' ? 'active' : ''}>서비스 이용 약관</a>
            </li>
            <li>
              <span>2.</span>
              <a href="/privacy-policy?type=TERM_USE_ELECTRONIC_FINANCIAL" className={type==='TERM_USE_ELECTRONIC_FINANCIAL' ? 'active' : ''}>전자금융거래 이용약관</a>
            </li>
            <li>
            <span>3.</span>
              <a href="/privacy-policy?type=POLICY_COLLECT_PERSONAL_INFORMATION" className={type==='POLICY_COLLECT_PERSONAL_INFORMATION' ? 'active' : ''}>개인정보 수집/이용 동의</a>
            </li>
            <li>
            <span>4.</span>
              <a href="/privacy-policy?type=POLICY_HANDLE_PERSONAL_INFORMATION" className={type==='POLICY_HANDLE_PERSONAL_INFORMATION' ? 'active' : ''}>개인정보 처리방침</a>
            </li>
            <li>
            <span>5.</span>
              <a href="/privacy-policy?type=TERM_USE_SERVICE_LOCATION" className={type==='TERM_USE_SERVICE_LOCATION' ? 'active' : ''}>위치기반서비스 이용약관</a>
            </li>
            <li>
            <span>6.</span>
              <a href="/privacy-policy?type=RECEIVE_MARKETING_INFORMATION" className={type==='RECEIVE_MARKETING_INFORMATION' ? 'active' : ''}>마케팅 정보 수신 동의</a>
            </li>
            <li>
            <span>7.</span>
              <a href="/privacy-policy?type=SUPPLY_INFORMATION_TO_THIRD_PARTIES" className={type==='SUPPLY_INFORMATION_TO_THIRD_PARTIES' ? 'active' : ''}>개인정보 제3자 제공 동의</a>
            </li>
          </ul>
          <hr />
        </div>

        <div className="content-policy">
          <h1>
            {detailData?.title}
          </h1>
          {/* {renderDetail(detailData.type)} */}
          <div dangerouslySetInnerHTML={{__html: detailData?.content || ''}} />
          <br />
          {
            detailData?.title && <>
              <hr />
              <div className="content text-right">
                <p>&copy; Copyright 2023, [문구야놀자] - 회사 [(주)문구야놀자]</p>
              </div>
            </>
          }
          
        </div>
      </div>
    </MainLayout>
  );
};

export default memo<Props>(PagePrivacyPolicyComponent);
