/* eslint-disable camelcase */
import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from 'utils/Apis';

import { Types } from '../redux';

// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* settingPush(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() => API.post(ROUTES.API_PUSH, action.data));

    if (response.ok) {
      const { data } = response;
      // In case: signup request success
      yield put({ type: Types.SETTING_PUSH_SUCCESS, data });
    } else {
      yield put({
        type: Types.SETTING_PUSH_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.SETTING_PUSH_FAILED, error });
  }
}

/*
  Starts settingPush on each dispatched `SETTING_PUSH` action.
*/
function* settingPushSaga() {
  yield takeLatest(Types.SETTING_PUSH, settingPush);
}

export default settingPushSaga;
