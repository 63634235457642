/* eslint-disable no-nested-ternary */
// import libs
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

// Define action creators
export const { Types, Creators } = createActions({
  getDataProduct: null,
  getDataProductSuccess: null,
  getDataProductFailed: null,

  registerRecentProduct: ['data'],
  registerRecentProductSuccess: null,
  registerRecentProductFailed: null,
  resetTypeRegister: null,

  registerProductEnquiry: ['data'],
  registerProductEnquirySuccess: null,
  registerProductEnquiryFailed: null,

  updateProductEnquiry: ['data'],
  updateProductEnquirySuccess: null,
  updateProductEnquiryFailed: null,

  getListProductEnquiry: ['data'],
  getListProductEnquirySuccess: null,
  getListProductEnquiryFailed: null,

  likeProductEnquiry: ['data', 'isLike'],
  likeProductEnquirySuccess: null,
  likeProductEnquiryFailed: null,

  deleteProductEnquiry: ['data'],
  deleteProductEnquirySuccess: null,
  deleteProductEnquiryFailed: null,

  detailProductEnquiry: ['data'],
  detailProductEnquirySuccess: null,
  detailProductEnquiryFailed: null,

  createComment: ['data'],
  createCommentSuccess: null,
  createCommentFailed: null,

  deleteComment: ['data'],
  deleteCommentSuccess: null,
  deleteCommentFailed: null,


  updateComment: ['data', 'enquiryId'],
  updateCommentSuccess: null,
  updateCommentFailed: null,

  setStoreActive: ['obj'],

  updateListProductEnquiry: ['data'],
});

// Initial state
export const INITIAL_STATE = Immutable({
  isProcessing: true,
  errors: '',
  type: '',
  statusCode: null,
  listProductEnquiry: [],
  total: 0,
  currentPage: 0,
  dataEnquiryDetail: {},
  isProcessingProduct: false,
  isProcessingLike: false,
  isProcessingComment: false,
  isProcessingDelProductEnquiry: false,
  storeSearch: null,
});
const resetTypeRegister = (state) => {
  return state.merge({
    isProcessing: false,
    errors: '',
    type: '',
    statusCode: null,
    listProductEnquiry: [],
    total: 0,
    currentPage: 0,
    dataEnquiryDetail: {},
    isProcessingProduct: false,
  });
};

const getDataProduct = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

const getDataProductSuccess = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

const getDataProductFailed = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

const registerRecentProduct = (state, action) => {
  return state.merge({
    isProcessing: true,
    type: action.type,
  });
};

const registerRecentProductSuccess = (state, action) => {
  const { data } = action;
  return state.merge({
    isProcessing: false,
    type: action.type,
    statusCode: data?.code,
  });
};

const registerRecentProductFailed = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

const registerProductEnquiry = (state, action) => {
  return state.merge({
    isProcessing: true,
    type: action.type,
  });
};

const registerProductEnquirySuccess = (state, action) => {
  const { data } = action;
  return state.merge({
    isProcessing: false,
    type: action.type,
    statusCode: data?.code,
  });
};

const registerProductEnquiryFailed = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

const getListProductEnquiry = (state, action) => {
  return state.merge({
    isProcessingProduct: true,
    type: action.type,
  });
};

const getListProductEnquirySuccess = (state, action) => {
  const { data } = action;
  let listData = [];
  if (data?.current_page === 1) {
    listData = [];
  } else {
    listData = state?.listProductEnquiry;
  }
  return state.merge({
    isProcessingProduct: false,
    type: action.type,
    total: data.total || 0,
    currentPage: data?.current_page || 0,
    listProductEnquiry: data && [...listData, ...data.data],
  });
};

const getListProductEnquiryFailed = (state, action) => {
  return state.merge({
    isProcessingProduct: false,
    type: action.type,
  });
};

const likeProductEnquiry = (state, action) => {
  return state.merge({
    type: action.type,
    isProcessingLike: true
  });
};

const likeProductEnquirySuccess = (state, action) => {
  const { data, isLike } = action;
  const listProductEnquiry = state?.listProductEnquiry;
  // const listUpdate =
  //   listProductEnquiry &&
  //   listProductEnquiry.map((item) => ({
  //     ...item,
  //     is_like:
  //       item.id === data.id && isLike === 'false'
  //         ? 'true'
  //         : item.id === data.id && isLike === 'true'
  //         ? 'false'
  //         : item.is_like,
  //     like_cnt:
  //       item.id === data.id && isLike === 'false'
  //         ? item.like_cnt + 1
  //         : item.id === data.id && isLike === 'true'
  //         ? item.like_cnt - 1
  //         : item.like_cnt,
  //   }));
  return state.merge({
    isProcessingLike: false,
    type: action.type,
    listProductEnquiry
  });
};

const likeProductEnquiryFailed = (state, action) => {
  return state.merge({
    type: action.type,
  });
};

const deleteProductEnquiry = (state, action) => {
  return state.merge({
    isProcessingDelProductEnquiry: true,
    type: action.type,
  });
};

const deleteProductEnquirySuccess = (state, action) => {
  const { data, enquiryId } = action;
  const listProductEnquiry = state?.listProductEnquiry;
  const listUpdate =
    listProductEnquiry &&
    listProductEnquiry.filter((item) => item.id !== enquiryId);
  return state.merge({
    type: action.type,
    listProductEnquiry: listUpdate,
    statusCode: data && data?.code,
    isProcessingDelProductEnquiry: false
  });
};

const deleteProductEnquiryFailed = (state, action) => {
  return state.merge({
    isProcessingDelProductEnquiry: false,
    type: action.type,
  });
};

const detailProductEnquiry = (state, action) => {
  return state.merge({
    isProcessing: true,
    type: action.type,
  });
};

const detailProductEnquirySuccess = (state, action) => {
  const { data } = action;
  const dataEnquiryDetail = {
    id: data && data.id,
    content: data && data.content,
    productImg: '',
    imageView: data?.image_path,
    store_id: data?.store_id,
    storeName: null,
  };

  return state.merge({
    isProcessing: false,
    type: action.type,
    dataEnquiryDetail,
  });
};

const detailProductEnquiryFailed = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

const updateProductEnquiry = (state, action) => {
  return state.merge({
    isProcessing: true,
    type: action.type,
  });
};

const updateProductEnquirySuccess = (state, action) => {
  const { data } = action;
  return state.merge({
    isProcessing: false,
    type: action.type,
    statusCode: data?.code,
  });
};

const updateProductEnquiryFailed = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

const createComment = (state, action) => {
  return state.merge({
    type: action.type,
    isProcessingComment: true,
  });
};

const createCommentSuccess = (state, action) => {
  const { inquiryId, data } = action;
  const listProductEnquiry = state?.listProductEnquiry;
  const listUpdate =
    listProductEnquiry &&
    listProductEnquiry.map((item) => ({
      ...item,
      comment:
        item.id === inquiryId ? [data.comment, ...item.comment] : item.comment,
      comment_cnt:
        item.id === inquiryId ? item.comment_cnt + 1 : item.comment_cnt,
    }));
  return state.merge({
    type: action.type,
    listProductEnquiry: listUpdate,
    isProcessingComment: false
  });
};

const createCommentFailed = (state, action) => {
  return state.merge({
    type: action.type,
    isProcessingComment: false
  });
};

const deleteComment = (state, action) => {
  return state.merge({
    isProcessingComment: true,
    type: action.type,
  });
};

const deleteCommentSuccess = (state, action) => {
  const { params } = action;
  const listProductEnquiry = state?.listProductEnquiry;
  const listUpdate =
    listProductEnquiry &&
    listProductEnquiry.map((item) => ({
      ...item,
      comment:
        item.id === params?.inquiry_id
          ? item.comment.filter(
              (itemRemove) => itemRemove.id !== params?.comment_id
            )
          : item.comment,
      comment_cnt:
        item.id === params?.inquiry_id
          ? item.comment_cnt - 1
          : item.comment_cnt,
    }));

  return state.merge({
    type: action.type,
    listProductEnquiry: listUpdate,
    isProcessingComment: false,
  });
};

const deleteCommentFailed = (state, action) => {
  return state.merge({
    type: action.type,
    isProcessingComment: false
  });
};

const updateComment = (state, action) => {
  return state.merge({
    type: action.type,
  });
};

const updateCommentSuccess = (state, action) => {
  const { enquiryId, params } = action;

  const listProductEnquiry = state?.listProductEnquiry;
  const listUpdate =
    listProductEnquiry &&
    listProductEnquiry.map((item) => ({
      ...item,
      comment:
        item.id === enquiryId
          ? item.comment.map((itemCm) => ({
              ...itemCm,
              comment:
                itemCm.id === params.comment_id
                  ? params.comment
                  : itemCm.comment,
            }))
          : item.comment,
    }));

  return state.merge({
    type: action.type,
    listProductEnquiry: listUpdate,
  });
};

const updateCommentFailed = (state, action) => {
  return state.merge({
    type: action.type,
  });
};
const setStoreActive = (state, action) => {
  return state.merge({
    storeSearch: action.obj,
  });
};

const updateListProductEnquiry = (state, action) => {
  const {data} = action 
  const listProductEnquiry = state?.listProductEnquiry;
  const listUpdate =
    listProductEnquiry &&
    listProductEnquiry.map((item) => ({
      ...item,
      is_like:
        item.id === data.id && data.isLike === 'false'
          ? 'true'
          : item.id === data.id && data.isLike === 'true'
          ? 'false'
          : item.is_like,
      like_cnt:
        item.id === data.id && data.isLike === 'false'
          ? item.like_cnt + 1
          : item.id === data.id && data.isLike === 'true'
          ? item.like_cnt - 1
          : item.like_cnt,
    }));
    return state.merge({
      type: action.type,
      listProductEnquiry: listUpdate
    });
}

// Assign handler to types.
const HANDLERS = {
  [Types.GET_DATA_PRODUCT]: getDataProduct,
  [Types.GET_DATA_PRODUCT_SUCCESS]: getDataProductSuccess,
  [Types.GET_DATA_PRODUCT_FAILED]: getDataProductFailed,

  [Types.REGISTER_RECENT_PRODUCT]: registerRecentProduct,
  [Types.REGISTER_RECENT_PRODUCT_SUCCESS]: registerRecentProductSuccess,
  [Types.REGISTER_RECENT_PRODUCT_FAILED]: registerRecentProductFailed,

  [Types.REGISTER_PRODUCT_ENQUIRY]: registerProductEnquiry,
  [Types.REGISTER_PRODUCT_ENQUIRY_SUCCESS]: registerProductEnquirySuccess,
  [Types.REGISTER_PRODUCT_ENQUIRY_FAILED]: registerProductEnquiryFailed,

  [Types.GET_LIST_PRODUCT_ENQUIRY]: getListProductEnquiry,
  [Types.GET_LIST_PRODUCT_ENQUIRY_SUCCESS]: getListProductEnquirySuccess,
  [Types.GET_LIST_PRODUCT_ENQUIRY_FAILED]: getListProductEnquiryFailed,

  [Types.LIKE_PRODUCT_ENQUIRY]: likeProductEnquiry,
  [Types.LIKE_PRODUCT_ENQUIRY_SUCCESS]: likeProductEnquirySuccess,
  [Types.LIKE_PRODUCT_ENQUIRY_FAILED]: likeProductEnquiryFailed,

  [Types.DELETE_PRODUCT_ENQUIRY]: deleteProductEnquiry,
  [Types.DELETE_PRODUCT_ENQUIRY_SUCCESS]: deleteProductEnquirySuccess,
  [Types.DELETE_PRODUCT_ENQUIRY_FAILED]: deleteProductEnquiryFailed,

  [Types.DETAIL_PRODUCT_ENQUIRY]: detailProductEnquiry,
  [Types.DETAIL_PRODUCT_ENQUIRY_SUCCESS]: detailProductEnquirySuccess,
  [Types.DETAIL_PRODUCT_ENQUIRY_FAILED]: detailProductEnquiryFailed,

  [Types.UPDATE_PRODUCT_ENQUIRY]: updateProductEnquiry,
  [Types.UPDATE_PRODUCT_ENQUIRY_SUCCESS]: updateProductEnquirySuccess,
  [Types.UPDATE_PRODUCT_ENQUIRY_FAILED]: updateProductEnquiryFailed,

  [Types.CREATE_COMMENT]: createComment,
  [Types.CREATE_COMMENT_SUCCESS]: createCommentSuccess,
  [Types.CREATE_COMMENT_FAILED]: createCommentFailed,

  [Types.DELETE_COMMENT]: deleteComment,
  [Types.DELETE_COMMENT_SUCCESS]: deleteCommentSuccess,
  [Types.DELETE_COMMENT_FAILED]: deleteCommentFailed,

  [Types.UPDATE_COMMENT]: updateComment,
  [Types.UPDATE_COMMENT_SUCCESS]: updateCommentSuccess,
  [Types.UPDATE_COMMENT_FAILED]: updateCommentFailed,

  [Types.RESET_TYPE_REGISTER]: resetTypeRegister,
  [Types.SET_STORE_ACTIVE]: setStoreActive,
  [Types.UPDATE_LIST_PRODUCT_ENQUIRY] : updateListProductEnquiry
};

// Create reducers by pass state and handlers
export const productReducer = createReducer(INITIAL_STATE, HANDLERS);
