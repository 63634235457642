import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ConsultionComponent from '../components/index';
import { Creators } from '../redux';

const mapStateToProps = (state) => {
  return {
    userInfo: state.accountReducer.userInfo,
    token: state.accountReducer.token
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...Creators,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ConsultionComponent);
