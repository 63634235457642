import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from '../../utils/Apis';

import { Types } from '../redux';

// worker Saga: will be fired on VERIFY_NICK_NAME_USER actions
function* checkNickName(action) {
  try {
    /**
     * Example data
     * url: enpoint/register/send-confirm-code
     * params:
     *  {
     *    id: 'user123'
     *  }
     *
     */
    const response = yield call(() =>
      API.get(ROUTES.API_CHECK_NICK_NAME, { nickname: action.nickName })
    );

    if (response.ok) {
      const { data } = response;
      // In case:  request success
      yield put({ type: Types.VERIFY_NICK_NAME_USER_SUCCESS, data });
    } else {
      // In case: request failed
      // const { status } = response.data;
      yield put({
        type: Types.VERIFY_NICK_NAME_USER_FAILED,
        // eslint-disable-next-line camelcase
        errors: '',
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.VERIFY_NICK_NAME_USER_FAILED, error });
  }
}

/*
  Starts checkNickName on each dispatched `VERIFY_NICK_NAME_USER` action.
*/
function* checkNickNameSaga() {
  yield takeLatest(Types.VERIFY_NICK_NAME_USER, checkNickName);
}

export default checkNickNameSaga;
