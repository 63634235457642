/* eslint-disable camelcase */
import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from 'utils/Apis';
import { checkStatusCodeApi } from 'utils/Helpers';

import { Types } from '../redux';

// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* getListProductEnquiry(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() =>
      API.get(ROUTES.API_GET_LIST_PRODUCT_ENQUIRY, action.data)
    );

    if (response.ok) {
      const { data } = response;
      // In case: signup request success
      yield put({
        type: Types.GET_LIST_PRODUCT_ENQUIRY_SUCCESS,
        data,
        storeId: action?.data?.store_id,
      });
    } else {
      const { data } = response;
      checkStatusCodeApi(data?.code || null)
      yield put({
        type: Types.GET_LIST_PRODUCT_ENQUIRY_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.GET_LIST_PRODUCT_ENQUIRY_FAILED, error });
  }
}

/*
  Starts getListProductEnquiry on each dispatched `GET_LIST_PRODUCT_ENQUIRY` action.
*/
function* getListProductEnquirySaga() {
  yield takeLatest(Types.GET_LIST_PRODUCT_ENQUIRY, getListProductEnquiry);
}

export default getListProductEnquirySaga;
