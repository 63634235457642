// import libs
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

// Define action creators
export const { Types, Creators } = createActions({
  getDataPush: null,
  getDataPushSuccess: null,
  getDataPushFailed: null,
});

// Initial state
export const INITIAL_STATE = Immutable({
  isProcessing: false,
  errors: '',
  dataPushHistory: [],
});

const getDataPush = (state, action) => {
  return state.merge({
    isProcessing: true,
    type: action.type,
  });
};

const getDataPushSuccess = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
    dataPushHistory: action.data.pushList,
  });
};

const getDataPushFailed = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

// Assign handler to types.
const HANDLERS = {
  [Types.GET_DATA_PUSH]: getDataPush,
  [Types.GET_DATA_PUSH_SUCCESS]: getDataPushSuccess,
  [Types.GET_DATA_PUSH_FAILED]: getDataPushFailed,
};

// Create reducers by pass state and handlers
export const pushReducer = createReducer(INITIAL_STATE, HANDLERS);
