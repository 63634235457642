// @flow

import React, { memo } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import IMAGES from 'themes/images';
import { ReactComponent as IconBack } from '../../assets/icons/icon_back.svg';
// import ROUTERS from 'constants/router';

type Props = {
  isLink?: boolean,
  customClass?: string,
  title: string,
  fontWeight?: string,
  isCenter?: boolean,
  isShowIcon?: boolean,
  isShowIconBackFunction?: boolean,
  iconBackFunction?: Function,
  showModalLogout?: Function,
  isShowModalLogout?: boolean,
  isBackNative?: boolean
};

const Header = ({
  isLink = false,
  customClass = '',
  fontWeight = 600,
  title,
  isCenter = false,
  isShowIcon = false,
  isShowIconBackFunction = false,
  iconBackFunction = () => {},
  showModalLogout = () => {},
  isShowModalLogout = false,
  isBackNative = false
}: Props) => {
  const history = useHistory();
  const handleBack = () => {
    if (isBackNative) {
      window.backToScreenNative();
    }else{
      history.go(-1);
    }
  };

  return (
    <div className="wrapper-header">
      <div className={`app-sub-header  ${customClass}`}>
        <div
          className={`app-sub-header__content d-flex align-items-center  ${
            isCenter ? 'app-sub-header__content--center' : ''
          }`}
        >
          {isLink && (
            <div className="app-sub-header__content__ico">
            {isShowIcon && (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div className="d-flex align-items-center" style={{width: 50}}
                  onClick={
                    isShowIconBackFunction ? iconBackFunction : handleBack
                  }
                  onKeyDown={
                    isShowIconBackFunction ? iconBackFunction : handleBack
                  }>
                  <p
                    role="presentation"
                    className="icon_back"
                  >
                    {/* <img src={IMAGES.iconBack} alt="icon_back" /> */}
                    <IconBack />
                  </p>
              </div>
              )}
            </div>
          )}
          <div
            className={`app-sub-header__content__text app-sub-header__content__text--${fontWeight}`}
          >
            <span>{title}</span>
            {isShowModalLogout && (
              <div
                className="btn-logout"
                onKeyDown={() => {}}
                role="button"
                tabIndex={1}
                onClick={showModalLogout}
              >
                <img src={IMAGES.iconLogout} alt="" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Header.defaultProps = {
  isLink: false,
  customClass: '',
  fontWeight: 600,
  isCenter: false,
  onClick: () => {},
  isShowIcon: false,
  itemActive: false,
  isShowIconBackFunction: false,
  iconBackFunction: () => {},
  showModalLogout: () => {},
  isShowModalLogout: false,
  isBackNative: false
};
export default withRouter(memo<Props>(Header));
