/* eslint-disable camelcase */
import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from 'utils/Apis';

import { Types } from '../redux';

// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* getMyInfo() {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() => API.get(ROUTES.API_MY_INFO));

    if (response.ok) {
      const { data } = response;
      // In case: signup request success
      yield put({ type: Types.GET_MY_INFO_SUCCESS, data });
    } else {
      yield put({
        type: Types.GET_MY_INFO_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.GET_MY_INFO_FAILED, error });
  }
}

/*
  Starts getMyInfo on each dispatched `GET_MY_INFO` action.
*/
function* getMyInfoSaga() {
  yield takeLatest(Types.GET_MY_INFO, getMyInfo);
}

export default getMyInfoSaga;
