// @flow

import React, { lazy, Suspense } from 'react';
import { bindActionCreators } from 'redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import ROUTERS from 'constants/router';
// import Loading from 'components/Loading';
import { connect } from 'react-redux';
import PrivateRoute from 'utils/PrivateRoute';
import pageFAQDetailContainer from 'screens/pageNotice/containers/pageFAQDetailContainer';
import pageAnnoucementDetailContainer from 'screens/pageNotice/containers/pageAnnoucementDetailContainer';
import { API } from '../utils/Apis';
import { Creators } from '../account/redux';

// import MainContainer from '../screens/mainPage/containers/mainContainer';
import FindStoreComponent from '../screens/findStore/containers/findStoreContainer';
import FindStoreLikeComponent from '../screens/findStore/containers/findStoreLikeContainer';
import ProductContainer from '../screens/productPage/containers/productContainer';
import createStore from 'store/createStore';

import PageOrderDetailContainer from '../screens/order/components/detail';
import PageEventContainer from '../screens/event/containers/eventContainer';
import PageEventDetailComponent from '../screens/event/components/detail';

import PageConsultionContainer from '../screens/consultion/containers/consultionContainer';
import PageConsultionDetailComponent from '../screens/consultion/components/detail';
import ConsutantCreateComponent from '../screens/consultion/components/create';

import PagePrivacyPolicyComponent from '../screens/policy/components/list';
import PagePolicyWebComponent from '../screens/policy/components/policy';
import PagePolicyHandlePersonalInfomationComponent from '../screens/policy/components/policyHandlePersonalInfomation';

// import HistoryBuyComponent from '../screens/historyBuy/containers/historyBuyContainer';
// import MyPageContainer from '../screens/myPage/containers/myPageContainer';

// const SettingComponent = lazy(() => import('../screens/settingPage'));

// const LoginComponent = lazy(() =>
//   import('../account/containers/SigninContainer')
// );
// const signUpComponent = lazy(() =>
//   import('../account/containers/SignUpContainer')
// );

// const FindUserComponent = lazy(() =>
//   import('../account/containers/FindUserContainer')
// );

// const historyPushContainer = lazy(() =>
//   import('../screens/historyPush/containers/pushContainer')
// );

// const pointContainer = lazy(() =>
//   import('../screens/addPoint/containers/pointContainer')
// );

// const LevelContainer = lazy(() =>
//   import('../screens/levelPage/containers/levelContainer')
// );

const inquiryRegisterContainer = lazy(() =>
  import('../screens/productPage/containers/inquiryContainer')
);

const editInquiryContainer = lazy(() =>
  import('../screens/productPage/containers/editInquiryContainer')
);

const registerProductContainer = lazy(() =>
  import('../screens/productPage/containers/registerProductContainer')
);

const PageNoticeContainer = lazy(() =>
  import('../screens/pageNotice/containers/pageNoticeContainer')
);

const PolicyContainer = lazy(() => import('screens/policy/components'));

// const TermPoliciesContainer = lazy(() =>
//   import('screens/policy/components/policy')
// );

// const TermOffServerContainer = lazy(() =>
//   import('screens/policy/components/termOfService')
// );

// const PageNoticeDetailContainer = lazy(() =>
//   import('../screens/pageNotice/components/detail')
// );

// const editMyPageContainer = lazy(() =>
//   import('../screens/myPage/containers/editInfoContainer')
// );

// const scanQRContainer = lazy(() =>
//   import('../screens/scanQRCode/containers/scanQRContainer')
// );
const mainGroupBuyContainer = lazy(() =>
  import('../screens/order/containers/mainGroupBuyContainer')
);
const purchaseHistoryContainer = lazy(() =>
  import('../screens/purchaseHistory/containers/purchaseHistoryContainer')
);
const detailOrderContainer = lazy(() =>
  import('screens/order/components/orderBook')
);

const storeSelectionContainer = lazy(() =>
  import('screens/order/containers/selectStoreEventContainer')
);

// const PageOrderDetailContainer = lazy(() =>
//   import('../screens/order/components/detail')
// );
const PageOrderCompleteContainer = lazy(() =>
  import('../screens/order/components/orderComplete')
);
const PageOrderHistoryDetailContainer = lazy(() =>
  import('../screens/purchaseHistory/containers/purchaseHistoryDetailContainer')
);
const PageQaCreate = lazy(() => import('../screens/order/components/createQA'));
const PageQaEdit = lazy(() => import('../screens/order/components/editQA'));
const PageQaManagement = lazy(() =>
  import('../screens/qna/containers/qnaAdminContainer')
);
const PageQaHistory = lazy(() =>
  import('../screens/qna/containers/qnaHistoryContainer')
);
const PageProductDetailContainer = lazy(() =>
  import('../screens/productPage/containers/detailProductContainer')
);

type Props = {
  token: string,
  settingApp: Object,
  setInfoFromApp: Function
};


const Router = ({ token, settingApp}: Props) => {
  // console.log(token, 'token app');
  // alert(token);
  const { store } = createStore();
  
  // console.log('132 window.tokenApp', window.tokenApp);
  // if (window.tokenApp) {
  //   API.setHeader('Authorization', `Bearer ${window.tokenApp}`);
  // } else {
  //   document.getElementById('token').innerHTML = 'tokennnnnnnnnnnnnnnnnullllllllll'
  // }

  const isAuthenticated = window.tokenApp;

  // document.getElementById('www').innerHTML = window?.tokenApp !== ''
  return (
    <BrowserRouter>
      <Suspense fallback="" >
        <Switch>
            {/* <Route exact path={ROUTERS.LOGIN} component={LoginComponent} />
            <Route exact path={ROUTERS.SIGN_UP} component={signUpComponent} />
            <Route
              exact
              path={ROUTERS.FIND_PASSWORD}
              component={FindUserComponent}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.MAIN}
              component={MainContainer}
            />
            <Route
              exact
              path={ROUTERS.NOTICE_DETAIL}
              component={PageNoticeDetailContainer}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.HISTORY_BUY}
              component={HistoryBuyComponent}
            /> */}

            {/* <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.SCAN_QR_CODE}
              component={scanQRContainer}
            /> */}

            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.FIND_STORE}
              component={FindStoreComponent}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.FIND_STORE_FAVORITE}
              component={FindStoreLikeComponent}
            />
            {/* <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.PUSH_NOTICE}
              component={historyPushContainer}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.ADD_POINT}
              component={pointContainer}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.LEVEL_PAGE}
              component={LevelContainer}
            /> */}

            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.INQUIRY_PRODUCT}
              component={ProductContainer}
            />

            {/* hotfix update router playground from native */}
            <Route exact path="/playground" render={(e) => (
              <Redirect to={`${ROUTERS.INQUIRY_PRODUCT}${e?.location?.search}`}/>
            )}/>
            {/* end */}

            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.INQUIRY_PRODUCT_REGISTER}
              component={inquiryRegisterContainer}
            />
            <Route
              // isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.TERM_AND_POLICY}
              component={PolicyContainer}
            />

            <Route
              exact
              path={ROUTERS.PRIVACY_POLICY}
              component={PagePrivacyPolicyComponent}
            />

            <Route
              exact
              path={ROUTERS.POLICY_WEB}
              component={PagePolicyWebComponent}
            />

            <Route
              exact
              path={ROUTERS.POLICY_HANDLE_PERSONAL_INFORMATION}
              component={PagePolicyHandlePersonalInfomationComponent}
            />
            
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.PRODUCT_ADD}
              component={registerProductContainer}
            />
            {/* <Route
              exact
              path={ROUTERS.POLICY_PAGE}
              component={TermPoliciesContainer}
            /> */}
            {/* <PrivateRoute
              // isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.TERM_OFF_SERVER}
              component={TermOffServerContainer}
            /> */}
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.NOTICE_PAGE}
              component={PageNoticeContainer}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.INQUIRY_PRODUCT_DETAIL}
              component={editInquiryContainer}
            />

            {/* <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.MY_PAGE}
              component={MyPageContainer}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.MY_PAGE_EDIT}
              component={editMyPageContainer}
            /> */}
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.ORDER}
              component={mainGroupBuyContainer}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.PUSCHASE_HISTORY}
              component={purchaseHistoryContainer}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.ORDER_BOOK}
              component={detailOrderContainer}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.ORDER_DETAIL}
              component={PageOrderDetailContainer}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.ORDER_COMPLETE}
              component={PageOrderCompleteContainer}
            />

            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.STORE_SELECTION}
              component={storeSelectionContainer}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.PUSCHASE_HISTORY_DETAIL}
              component={PageOrderHistoryDetailContainer}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.QA_CREATE}
              component={PageQaCreate}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.QA_DETAIL}
              component={PageQaEdit}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.QA_MANAGEMENT}
              component={PageQaManagement}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.QA_HISTORY}
              component={PageQaHistory}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.PRODUCT_DETAIL}
              component={PageProductDetailContainer}
            />

            <Route
              exact
              path={ROUTERS.FAQ_DETAIL}
              component={pageFAQDetailContainer}
            />

            <Route
              exact
              path={ROUTERS.ANNOUCEMENT_DETAIL}
              component={pageAnnoucementDetailContainer}
            />

            {/* router event */}
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.EVENT}
              component={PageEventContainer}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.EVENT_DETAIL}
              component={PageEventDetailComponent}
            />

            {/* router consultion */}
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.CONSULTION}
              component={PageConsultionContainer}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTERS.CONSULTION_DETAIL}
              component={PageConsultionDetailComponent}
            />
            <PrivateRoute
                isAuthenticated={isAuthenticated}
                exact
                path={ROUTERS.CONSULTION_CREATE}
                component={ConsutantCreateComponent}
            />
          </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state?.accountReducer?.token,
    settingApp: state?.settingAppReducer?.settingApp,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...Creators}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Router);
