// @flow

import React, { memo, useEffect, useState } from 'react';
import MainLayout from 'layout/MainLayout';
import { useParams  } from 'react-router-dom';
import { checkStatusCodeApi, formatDate, nl2br } from 'utils/Helpers';
import {API, ROUTES} from "../../../utils/Apis";
import { ModalPopup } from 'components/Modal';

const PageFaqDetailComponent = () => {
  const {id} = useParams();
  const [detailData, setDetailData] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [isShowModalView, setIsShowModalView] = useState(false);
  const [imagesView, setImagesView] = useState(false);

  useEffect(() => {
    API.get(`${ROUTES.API_DETAIL_FAQ}?id=${id}`)
      .then((res) => {
        if (res?.data?.code === 401) {
          checkStatusCodeApi(res?.data?.code || null)
        }else{
          setDetailData(res.data?.data)
          setIsLoading(false)
        }
      })
      .catch((e) => {
        setIsLoading(false)
      })
  }, [id])
  
  const showModalImgView = (item) => {
    setImagesView(item);
    setIsShowModalView(true);
  }

  const renderImgDetail = (data) => {
    if (data.length > 0) {
      return data.map((item, idx) => {
        return <>
            <img onClick={() => showModalImgView(item?.path_thumbnail)} src={item?.path_thumbnail} alt="img" />
        </>
      })
    }
  }


  return (
    <MainLayout
      isMarginBottom
      isShowHeader
      titleHeader="FAQ 상세"
      isCenter
      isShowIcon
      isLink
      customClass="faq-detail-page"
      isLoading={isLoading}
      customClassHeader="border-bottom"
      isBackNative
      fontWeight={600}
    >
      {!isLoading && (
        <div className="wrapper-page-faq faq-detail">
          <div className="content">
            <div className="title">
              <p className="title-text">{detailData?.title || ''}</p>
              <span className="time">{detailData?.created_at ? formatDate(detailData.created_at) : ''}</span>
            </div>

            <div className="reply">
              <div
                className="reply-text"
                dangerouslySetInnerHTML={{ __html: nl2br(detailData?.content || '') }}
              />

              <div className="img-detail">
                {
                  renderImgDetail(detailData?.attachment || '')
                }
              </div>

            </div>
          </div>
        </div>
      )}

      <ModalPopup
        isOpen={isShowModalView}
        isShowIconClose
        handleClose={() => setIsShowModalView(false)}
        handleCloseOvl={() => setIsShowModalView(false)}
        customClass="view-product-popup"
      >
        <div className='review-modal'>
          <div className="img-review">
            <img className='img' src={imagesView} alt="" />
          </div>
        </div>
      </ModalPopup>
    </MainLayout>
  );
};

export default memo(PageFaqDetailComponent);
