// import libs
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

// Define action creators
export const { Types, Creators } = createActions({
  setDataSettingApp: ['params'],
});

// Initial state
export const INITIAL_STATE = Immutable({
  isProcessing: false,
  errors: '',
  settingApp: {
    isSettingApp: false,
  },
});

const setDataSettingApp = (state, action) => {
  const { params } = action;
  return state.merge({
    settingApp: params,
    type: action.type,
  });
};

// Assign handler to types.
const HANDLERS = {
  [Types.SET_DATA_SETTING_APP]: setDataSettingApp,
};

// Create reducers by pass state and handlers
export const settingAppReducer = createReducer(INITIAL_STATE, HANDLERS);
