// import libs
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

// Define action creators
export const { Types, Creators } = createActions({
  getDataMain: null,
  getDataMainSuccess: null,
  getDataMainFailed: null,

  updateListStore: ['list'],

  deleteProductRecent: ['productId'],
  deleteProductRecentSuccess: null,
  deleteProductRecentFailed: null,

  resetState: null,

  getUserBarcode: null,
  getUserBarcodeSuccess: null,
  getUserBarcodeFailed: null,

  getDataMainUpdatePoint: null,
  getDataMainUpdatePointSuccess: null,
  getDataMainUpdatePointFailed: null,

  getDataMaintenance: null,
  getDataMaintenanceSuccess: null,
  getDataMaintenanceFailed: null,
});

// Initial state
export const INITIAL_STATE = Immutable({
  isProcessing: false,
  errors: '',
  pointUser: 0,
  pushCheck: 0,
  storeListLike: [],
  recentProductList: [],
  statusCode: 0,
  barcode: '',
  point: '',
  expiredBarCode: '',
  maintenanceSetting: {},
});

const resetState = (state) => {
  return state.merge({
    statusCode: 0,
    type: '',
  });
};

const getDataMain = (state, action) => {
  return state.merge({
    isProcessing: true,
    type: action.type,
  });
};

const getDataMainSuccess = (state, action) => {
  const { data } = action;
  const listStore =
    data &&
    data.store_list &&
    data.store_list.filter((item) => item.favorite === 'true');

  const storeListLikeOfUser = (listStore || [])
    .slice(0, 3)
    .map((item, index) => ({
      id: index,
      value: index,
      label: item?.name,
    }));

  return state.merge({
    isProcessing: false,
    type: action.type,
    pointUser: data?.point || 0,
    pushCheck: data?.push_check,
    storeListLike: storeListLikeOfUser || [],
    recentProductList: data?.recent_product_list || [],
    level: (data?.level && parseInt(data?.level, 10)) || 1,
  });
};

const getDataMainFailed = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

const getDataMainUpdatePoint = (state, action) => {
  return state.merge({
    type: action.type,
  });
};

const getDataMainUpdatePointSuccess = (state, action) => {
  const { data } = action;
  return state.merge({
    isProcessing: false,
    type: action.type,
    pointUser: data?.point || 0,
  });
};

const getDataMainUpdatePointFailed = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

const updateListStore = (state, action) => {
  const { list } = action;
  return state.merge({
    storeListLike: list || [],
  });
};

const deleteProductRecent = (state, action) => {
  return state.merge({
    type: action.type,
  });
};

const deleteProductRecentSuccess = (state, action) => {
  const { productId, data } = action;

  const recentProductList = state.recentProductList || [];
  const listUpdate =
    recentProductList &&
    recentProductList.filter((item) => item.id !== productId);

  return state.merge({
    type: action.type,
    recentProductList: listUpdate,
    statusCode: data?.code,
  });
};

const deleteProductRecentFailed = (state, action) => {
  return state.merge({
    type: action.type,
    statusCode: 0,
  });
};

const getUserBarcode = (state, action) => {
  return state.merge({
    type: action.type,
    expiredBarCode: '',
  });
};

const getUserBarcodeSuccess = (state, action) => {
  return state.merge({
    type: action.type,
    barcode: action.data?.barcode,
    point: action.data?.point || 0,
    pointUser: action.data?.point || 0,
    expiredBarCode: action?.data?.expiry_time,
  });
};

const getUserBarcodeFailed = (state, action) => {
  return state.merge({
    type: action.type,
    statusCode: 0,
  });
};

const getDataMaintenance = (state, action) => {
  return state.merge({
    type: action.type,
  });
};

const getDataMaintenanceSuccess = (state, action) => {
  const { data } = action;
  return state.merge({
    isProcessing: false,
    type: action.type,
    maintenanceSetting: data?.maintenanceSetting || {},
  });
};

const getDataMaintenanceFailed = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

// Assign handler to types.
const HANDLERS = {
  [Types.GET_DATA_MAIN]: getDataMain,
  [Types.GET_DATA_MAIN_SUCCESS]: getDataMainSuccess,
  [Types.GET_DATA_MAIN_FAILED]: getDataMainFailed,

  [Types.GET_DATA_MAIN_UPDATE_POINT]: getDataMainUpdatePoint,
  [Types.GET_DATA_MAIN_UPDATE_POINT_SUCCESS]: getDataMainUpdatePointSuccess,
  [Types.GET_DATA_MAIN_UPDATE_POINT_FAILED]: getDataMainUpdatePointFailed,

  [Types.DELETE_PRODUCT_RECENT]: deleteProductRecent,
  [Types.DELETE_PRODUCT_RECENT_SUCCESS]: deleteProductRecentSuccess,
  [Types.DELETE_PRODUCT_RECENT_FAILED]: deleteProductRecentFailed,

  [Types.UPDATE_LIST_STORE]: updateListStore,
  [Types.RESET_STATE]: resetState,

  [Types.GET_USER_BARCODE]: getUserBarcode,
  [Types.GET_USER_BARCODE_SUCCESS]: getUserBarcodeSuccess,
  [Types.GET_USER_BARCODE_FAILED]: getUserBarcodeFailed,

  [Types.GET_DATA_MAINTENANCE]: getDataMaintenance,
  [Types.GET_DATA_MAINTENANCE_SUCCESS]: getDataMaintenanceSuccess,
  [Types.GET_DATA_MAINTENANCE_FAILED]: getDataMaintenanceFailed,
};

// Create reducers by pass state and handlers
export const mainReducer = createReducer(INITIAL_STATE, HANDLERS);
