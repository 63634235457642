// @flow
// libs
import React, { memo } from 'react';
// import Lottie from 'react-lottie';
import images from 'themes/images';
import ROUTERS from 'constants/router';
// import iconHeaderMain from '../../assets/json/MOMO_HeaderMain.json';

// const iconLogo = {
//   loop: true,
//   autoplay: true,
//   animationData: iconHeaderMain,
//   rendererSettings: {
//     preserveAspectRatio: 'xMidYMid slice',
//   },
// };

type Props = {
  history: {
    push: Function,
  },
  numberBell: any,
};

export const HeaderMain = ({ history, numberBell }: Props) => (
  <div className="info-header d-flex align-items-center justify-content-between">
    <div className="title-header">
      <img src={images.mainLogo} alt="" />
    </div>
    <div
      className="bell"
      onClick={() => history && history.push(ROUTERS.PUSH_NOTICE)}
      onKeyUp={() => {}}
      role="button"
      tabIndex={0}
    >
      <img src={images.iconBell} alt="" />
      {numberBell > 0 && <span>N</span>}
    </div>
  </div>
);

export default memo<Props>(HeaderMain);
