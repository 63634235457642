// import libs
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

// Define action creators
export const { Types, Creators } = createActions({
  getListPaymentHistory: ['data'],
  getListPaymentHistorySuccess: null,
  getListPaymentHistoryFailed: null,
});

// Initial state
export const INITIAL_STATE = Immutable({
  isProcessing: false,
  errors: '',
  dataPaymentHistory: [],
  totalPrice: 0,
  totalPoint: 0,
});

const getListPaymentHistory = (state, action) => {
  return state.merge({
    isProcessing: true,
    type: action.type,
  });
};

const getListPaymentHistorySuccess = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
    dataPaymentHistory: action.data.paymentsList,
    totalPrice:
      action.data.total_price && parseInt(action.data.total_price, 10),
    totalPoint: action.data.point && parseInt(action.data.point, 10),
  });
};

const getListPaymentHistoryFailed = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

// Assign handler to types.
const HANDLERS = {
  [Types.GET_LIST_PAYMENT_HISTORY]: getListPaymentHistory,
  [Types.GET_LIST_PAYMENT_HISTORY_SUCCESS]: getListPaymentHistorySuccess,
  [Types.GET_LIST_PAYMENT_HISTORY_FAILED]: getListPaymentHistoryFailed,
};

// Create reducers by pass state and handlers
export const paymentReducer = createReducer(INITIAL_STATE, HANDLERS);
