import { call, put, takeLatest } from 'redux-saga/effects';
import { checkStatusCodeApi } from 'utils/Helpers';

import { ROUTES, API } from '../../../utils/Apis';

import { Types } from '../redux';

// worker Saga: will be fired on orderGroupBuying actions
function* orderGroupBuying(action) {
  try {
    /**
     * Example data
     * url: enpoint/login
     
     *
     */
    const response = yield call(() =>
      API.post(ROUTES.API_ORDER_GROUP_BUYING, action.orderInfo, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    );

    if (response.ok) {
      const { data } = response;
      // In case: order group buying request success
      yield put({ type: Types.ORDER_GROUP_BUYING_SUCCESS, data });
    } else {
      const { data } = response;
      checkStatusCodeApi(data?.code || null)
      // In case: order group buying  request failed
      yield put({
        type: Types.ORDER_GROUP_BUYING_FAILED,
        data
      });
    }
  } catch (error) {
    // console.log(error)
    // in case: server error
    const errors = error.response?.data;
    yield put({ type: Types.ORDER_GROUP_BUYING_FAILED, errors });
  }
}

/*
  Starts order group buying on each dispatched `ORDER_GROUP_BUYING` action.
*/
function* orderGroupBuyingSaga() {
  yield takeLatest(Types.ORDER_GROUP_BUYING, orderGroupBuying);
}

export default orderGroupBuyingSaga;
