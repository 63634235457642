/* eslint-disable camelcase */
import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from 'utils/Apis';
import { checkStatusCodeApi } from 'utils/Helpers';

import { Types } from '../redux';

// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* registerProductEnquiry(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() =>
      API.post(ROUTES.API_INQUIRY, action.data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    );

    if (response.ok) {
      const { data } = response;
      // In case: signup request success
      yield put({ type: Types.REGISTER_PRODUCT_ENQUIRY_SUCCESS, data });
    } else {
      const { data } = response;
      checkStatusCodeApi(data?.code || null)

      yield put({
        type: Types.REGISTER_PRODUCT_ENQUIRY_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.REGISTER_PRODUCT_ENQUIRY_FAILED, error });
  }
}

/*
  Starts registerProductEnquiry on each dispatched `REGISTER_PRODUCT_ENQUIRY` action.
*/
function* registerProductEnquirySaga() {
  yield takeLatest(Types.REGISTER_PRODUCT_ENQUIRY, registerProductEnquiry);
}

export default registerProductEnquirySaga;
