import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from 'utils/Apis';

import { Types } from '../redux';

// worker Saga: will be fired on GET_SMS_CODE actions
function* getDataMainPageUpdatePoint() {
  try {
    /**
     * Example data
     * url: enpoint/register/send-confirm-code
     *
     */
    const response = yield call(() =>
      API.get(ROUTES.API_MAIN, { ran: Math.random() })
    );
    const data = response?.data;
    if (response.ok) {
      // In case: get sms code request success
      yield put({ type: Types.GET_DATA_MAIN_UPDATE_POINT_SUCCESS, data });
    } else {
      // In case: get sms code request failed
      yield put({
        type: Types.GET_DATA_MAIN_UPDATE_POINT_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.GET_DATA_MAIN_UPDATE_POINT_FAILED, error });
  }
}

/*
  Starts signupAccount on each dispatched `SIGN_IN` action.
*/
function* getDataMainPageUpdatePointSaga() {
  yield takeLatest(
    Types.GET_DATA_MAIN_UPDATE_POINT,
    getDataMainPageUpdatePoint
  );
}

export default getDataMainPageUpdatePointSaga;
