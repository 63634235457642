
import React, { memo }  from 'react';

function  ElectronicFinancialRegisterComponent() {
  return (
    <>
      <div>
        <div className='content text-right'>
          개정시행 2022. 3 . 21
        </div>
      </div>
      <div>
        <h2 className='title'>제1조(목적)</h2>
        <div className='content'>
          이 약관은 문구야놀자 주식회사(이하 “회사”라 합니다)가 제공하는 선불전자지급수단의 발행 및 관리 서비스(이하 통칭하여 “전자금융거래서비스”라 합니다)를 이용자가 이용함에 있어 이용자와 회사 간의 전자금융거래에 관한 기본적인 사항을 정함에 그 목적이 있습니다.
        </div>
      </div>
      <div>
        <h2 className='title'>제2조(정의)</h2>
        <div className='content'>
          이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br />
          1. “전자금융거래”는 회사가 전자적 장치를 통하여 전자금융거래서비스를 제공하고, 이용자가 회사의 종사자와 직접 대면하거나 의사소통을 하지 아니하고 자동화된 방식으로 이를 이용하는 거래를 말합니다.<br />
          2. “전자적 장치”는 전자금융거래정보를 전자적 방법으로 전송하거나 처리하는데 이용되는 장치로서 현금자동지급기, 자동입출금기, 지급용단말기, 컴퓨터, 휴대전화 등 모바일 기기 그 밖에 전자적 방법으로 정보를 전송하거나 처리하는 장치를 말합니다.<br />
          3. “전자지급수단”은 선불전자지급수단 등 전자금융거래법 제2조 제11호에서 정하는 전자적 방법에 따른 지급수단을 말합니다.<br />
          4. “선불전자지급수단”은 이전 가능한 금전적 가치가 전자적인 방법으로 저장되어 발행된 증표 또는 증표에 관한 정보로써 전자금융거래법에 따라 발행인 외의 제3자로부터 재화나 용역을 구입한 대가로 지급하는데 사용되는 것을 말합니다.<br />
          5. “전자지급거래”는 자금을 주는 자(이하 “지급인”이라 합니다)가 회사로 하여금 전자지급수단을 이용하여 자금을 받는 자(이하 “수취인”이라 합니다)에게 자금을 이동하게 하는 전자금융거래를 말합니다.<br />
          6. “이용자”는 회사가 제공하는 전자금융거래서비스를 이용하는 자를 말합니다.<br />
          7. “전자문서”는 전자문서 및 전자거래 기본법 제2조 제1호에 따른 작성, 송신, 수신 또는 저장된 정보를 말합니다.<br />
          8. “접근매체”는 전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 수단 또는 정보로서 전자식 카드 및 이에 준하는 전자적 정보, 전자서명법의 전자서명생성정보 및 인증서, 회사에 등록된 이용자번호, 이용자의 생체정보, 이상의 수단이나 정보를 사용하는데 필요한 비밀번호 등 전자금융거래법 제2조 제10호에서 정하고 있는 것을 말합니다.<br />
          9. “거래지시”는 이용자가 이 약관에 따라 회사에게 전자금융거래의 처리를 지시하는 것을 말합니다.<br />
          10. “오류”는 이용자의 고의 또는 과실 없이 전자금융거래가 이 약관 또는 이용자의 거래지시에 따라 이행되지 아니한 경우를 말합니다.<br />
          11. “가맹점”은 이 약관에 따라 선불전자지급수단 등 전자지급수단에 의한 거래에 있어서 이용자에게 재화 또는 용역을 제공하는 자로서 금융회사 또는 전자금융업자가 아닌 자를 말합니다.<br />
        </div>
      </div>
      <div>
        <h2 className='title'>제3조(약관의 명시 및 변경)</h2>
        <div className='content'>
          <span>①</span> 회사는 이용자가 전자금융거래서비스를 이용하기 전에 이 약관의 중요한 내용을 모바일 어플리케이션 화면 또는 인터넷 웹사이트 등(이하 “어플리케이션 화면 등”이라 합니다)에 게시하여 이용자가 이 약관의 중요한 내용을 확인할 수 있도록 합니다.<br />
          <span>②</span> 회사는 이용자의 요청이 있는 경우 전자문서 전송, 전자우편, 모사전송, 우편 또는 직접 교부의 방식(이하 “전자문서 전송 등의 방식”이라 합니다)으로 이 약관의 사본을 이용자에게 교부합니다.<br />
          <span>③</span> 회사가 이 약관을 변경하는 때에는 그 시행일 1월 전에 변경되는 약관을 어플리케이션 화면 등에 게시하여 이용자에게 공지합니다. 다만, 법령의 개정으로 인하여 긴급하게 약관을 변경한 경우에는 변경된 약관을 어플리케이션 화면 등에 1개월 이상 게시하고 이용자에게 전자문서 전송 등의 방식을 통하여 사후 통지합니다.<br />
          <span>④</span> 회사는 제3항에 따른 고지나 통지를 할 경우에는 “이용자가 변경에 동의하지 아니한 경우에는 고지되거나 통지를 받은 날로부터 30일 이내에 계약을 해지 할 수 있으며, 계약 해지의 의사 표시를 하지 아니한 경우에는 변경된 약관에 동의한 것으로 본다” 라는 취지의 내용을 함께 고지하거나 통지합니다.<br />
          <span>⑤</span> 이용자가 제4항의 고지나 통지를 받은 날로부터 30일 이내에 계약 해지의 의사표시를 하지 아니한 경우에는 약관의 변경에 동의한 것으로 봅니다.<br />
        </div>
      </div>
      <div>
        <h2 className='title'>제4조(전자금융거래서비스의 구성 및 내용)</h2>
        <div className='content'>
          회사가 제공하는 전자금융거래서비스는 다음 각 호와 같습니다.<br />
          1. 선불전자지급수단의 발행 및 관리 서비스<br />
        </div>
      </div>

      <div>
        <h2 className='title'>제5조(이용 시간)</h2>
        <div className='content'>
          <span>①</span> 회사는 이용자에게 연중무휴 1일 24시간 전자금융거래서비스를 제공함을 원칙으로 합니다. 단, 금융회사 기타 결제수단 발행업자의 사정에 따라 달리 정할 수 있습니다.
          <span>②</span> 회사는 정보통신설비의 보수, 점검 기타 기술상의 필요나 금융회사 기타 결제수단 발행업자의 사정에 의하여 서비스 중단이 불가피한 경우에는 사전에 어플리케이션 화면 등을 통하여 서비스 중단 사실을 공지한 후 서비스를 일시 중단할 수 있습니다. 다만, 시스템 장애 복구, 긴급한 프로그램의 보수, 외부 요인 등 불가피한 경우에는 사전 공지 없이 서비스를 중단할 수 있습니다.
        </div>
      </div>

      <div>
        <h2 className='title'>제6조(접근매체 관리)</h2>
        <div className='content'>
          <span>①</span> 회사는 전자금융거래서비스 제공 시 접근매체를 선정하여 이용자의 신원, 권한 및 거래지시 내용 등을 확인할 수 있습니다.
          <span>②</span> 이용자는 접근매체를 사용함에 있어서 회사의 동의 또는 다른 법률에 특별한 규정이 없는 한 접근매체를 제3자에게 양도, 대여하거나 사용을 위임하는 행위 또는 질권 등 담보 목적으로 하는 행위 등을 할 수 없습니다. 단, 선불전자지급수단의 경우 이용자는 제3자에게 양도하거나 제3자로부터 양수하는 행위 등을 할 수 있습니다.
          <span>③</span> 이용자는 자신의 접근매체를 제3자에게 누설 또는 노출하거나 방치하여서는 안되며, 접근매체의 도용이나 위조 또는 변조를 방지하기 위하여 충분한 주의를 기울여야 합니다.
          <span>④</span> 회사는 이용자로부터 접근매체의 분실이나 도난의 통지를 받은 때에는 그때부터 제3자가 그 접근매체를 사용함으로 인하여 이용자에게 발생한 손해를 배상할 책임이 있습니다.
        </div>
      </div>

      <div>
        <h2 className='title'>제7조(전자금융거래 기록의 생성 및 보존)</h2>
        <div className='content'>
          <span>①</span>회사는 이용자가 이용한 전자금융거래의 내용을 추적, 검색하거나 그 내용에 오류가 발생했을 때 이를 확인하거나 정정할 수 있는 기록을 생성하여 보존합니다.<br />
          <span>②</span>제1항의 규정에 따라 회사가 보존하여야 하는 기록의 종류 및 보존 기간은 제8조 제3항 내지 제4항에서 정한 바에 따릅니다.<br />
        </div>
      </div>

      <div>
        <h2 className='title'>제8조(거래내용의 확인 및 오류 정정)</h2>
        <div className='content'>
          <span>①</span> 회사는 어플리케이션 화면 등을 통하여 이용자의 거래내용을 확인할 수 있도록 하며, 이용자가 요청하면 요청을 받은 날로부터 2주 이내에 전자문서 전송 등의 방식으로 거래 내용에 관한 서면을 교부합니다.<br />
          <span>②</span> 회사는 제1항에 따른 이용자의 거래내용 서면 교부 요청을 받았으나 전자적 장치의 운영 장애 등의 사유로 거래내용을 제공할 수 없는 때에는 즉시 이용자에게 전자문서 전송 등의 방식으로 그러한 사유를 알려야 하며, 전자적 장치의 운영 장애 등의 사유로 거래내용을 제공할 수 없는 기간은 제1항의 거래 내용에 관한 서면 교부 기간에 산입하지 아니합니다.<br />
          <span>③</span> 제1항의 대상이 되는 거래내용 중 보존 기간이 5년인 것은 다음 각 호와 같습니다.<br />
          1. 전자금융거래 계좌의 명칭 또는 그 번호<br />
          2. 전자금융거래의 종류 및 금액<br />
          3. 전자금융거래 상대방을 나타내는 정보<br />
          4. 전자금융거래 일시<br />
          5. 이용자의 출금 동의에 관한 사항<br />
          6. 전자적 장치의 종류와 전자적 장치를 식별할 수 있는 정보<br />
          7. 회사가 전자금융거래의 대가로 받은 수수료<br />
          8. 해당 전자금융거래와 관련한 전자적 장치의 접속기록<br />
          9. 전자금융거래의 신청 및 조건의 변경에 관한 사항<br />
          10. 건당 거래금액이 1만원을 초과하는 전자금융거래에 관한 기록<br />
          <span>④</span> 제1항의 대상이 되는 거래 내용 중 보존 기간이 1년인 것은 다음 각 호와 같습니다.<br />
          1. 건당 거래금액이 1만원 이하인 소액 전자금융거래에 관한 기록<br />
          2. 전자지급수단 이용 시 거래승인에 관한 기록<br />
          3. 이용자의 오류 정정 요구사실 및 처리 결과에 관한 사항<br />
          <span>⑤</span> 이용자가 제1항에서 정한 서면 교부를 요청하고자 할 경우 아래의 주소, 전자우편 및 전화번호로 전자문서 전송 등의 방식을 통해 요청할 수 있습니다.<br />
          1. 주소: 서울특별시 광진구 천호대로 585 KD빌딩 3층<br />
          고객센터 담당, 우편번호 04998<br />
          2. 전자우편: yongin77@enjoyworks.co.kr<br />
          3. 전화번호: 02-6224-3125<br />
          (부가서비스 별로 고객센터 전화번호는 달라질 수 있습니다. 상세 내용은 서비스 이용약관 내용을 참조하시기 바랍니다)<br />
          <span>⑥</span> 이용자는 전자금융거래서비스를 이용함에 있어 오류가 있음을 안 때에는 회사에 대하여 본 조 제5항의 방식으로 그 정정을 요구할 수 있습니다.<br />
          <span>⑦</span> 회사는 오류의 정정요구를 받은 때 또는 스스로 전자금융거래에 오류가 있음을 안때에는 이를 즉시 조사하여 처리한 후 정정요구를 받은 날로부터 2주 이내에 그 결과를 이용자에게 알려드립니다.<br />
        </div>
      </div>

      <div>
        <h2 className='title'>제9조(전자지급거래의 효력 발생 및 거래지시의 철회)</h2>
        <div className='content'>
          <span>①</span> 회사는 이용자의 거래지시가 전자지급거래에 관한 경우 그 지급절차를 이행하며, 전자지급거래에 관한 거래지시의 내용을 전송하여 지급이 이루어지도록 합니다.<br />
          <span>②</span> 이용자가 전자지급수단을 이용하여 자금을 지급하는 경우 지급의 효력은 다음 각 호에서 정한 때에 발생합니다.<br />
          1. 선불전자지급수단으로 지급하는 경우: 거래 지시된 금액의 정보가 수취인이 지정한 전자적 장치에 도달한 때<br />
          <span>③</span> 이용자는 지급의 효력이 발생하기 전까지 이 약관에서 정한 바에 따라 제8조 제5항에 기재된 담당자에게 전자문서 전송 등의 방식으로 거래지시를 철회할 수 있습니다.<br />
          <span>④</span> 전자지급의 효력이 발생할 경우 이용자는 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령상 청약 철회의 방법으로 결제대금을 반환 받을 수 있습니다.<br />
        </div>
      </div>

      <div>
        <h2 className='title'>제10조(전자금융거래정보의 제공 금지)</h2>
        <div className='content'>
          회사는 전자금융거래서비스를 제공하는 과정에서 취득한 이용자의 인적 사항, 이용자의 계좌, 접근매체 및 전자금융거래의 내용과 실적에 관한 정보 또는 자료를 법령에 의하거나 이용자의 동의를 얻지 아니하고 제3자에게 제공, 누설하거나 업무상 목적 외에 사용하지 않습니다.
        </div>
      </div>

      <div>
        <h2 className='title'>제11조(회사의 책임)</h2>
        <div className='content'>
          <span>①</span> 회사는 접근매체의 위조나 변조로 발생한 사고, 계약 체결 또는 거래지시의 전자적 전송이나 처리 과정에서 발생한 사고, 전자금융거래를 위한 전자적 장치 또는 ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’ 제2조제1항제1호에 따른 정보통신망에 침입하여 거짓이나 그 밖의 부정한 방법으로 획득한 접근매체의 이용으로 발생한 사고로 인하여 이용자에게 손해가 발생하는 경우 발생한 손해를 배상할 책임을 집니다.<br />
          <span>②</span> 회사는 제1항에도 불구하고 다음 각호의 어느 하나에 해당하는 사유로 인하여 이용자에게 발생한 손해에 대하여는 이용자가 그 책임의 전부 또는 일부를 부담하게 할 수 있습니다.<br />
          1. 이용자가 접근매체를 제3자에게 대여하거나 사용을 위임하거나 양도 또는 담보 목적으로 제공한 경우<br />
          2. 제3자가 권한 없이 이용자의 접근매체를 이용하여 전자금융거래를 할 수 있음을 알았거나 쉽게 알 수 있었음에도 불구하고 이용자가 자신의 접근매체를 누설 또는 노출하거나 방치한 경우<br />
          3. 법인(「중소기업기본법」 제2조 제2항에 따른 소기업을 제외합니다)인 이용자에게 손해가 발생한 경우로 회사가 사고를 방지하기 위하여 보안절차를 수립하고 이를 철저히 준수하는 등 합리적으로 요구되는 주의 의무를 다한 경우<br />
          4. 회사가 전자금융거래법 제6조 제1항에 따른 확인 외에 보안강화를 위하여 전자금융거래 시 추가적인 보안조치를 요구하였으나 이용자가 회사의 요구를 정당한 사유 없이 거부하여 전자금융거래법 제9조 제1항 제3호의 사고가 발생한 경우<br />
          5. 이용자가 제4호에 따른 추가적인 보안조치에 사용되는 매체, 수단 또는 정보에 대하여 다음 각 목의 어느 하나에 해당하는 행위를 하여 전자금융거래법 제9조 제1항 제3호에 따른 사고가 발생한 경우<br />
          가. 제3자에게 누설, 노출하거나 방치한 행위<br />
          나. 제3자에게 대여하거나 그 사용을 위임한 행위<br />
          다. 제3자에게 양도하거나 담보의 목적으로 제공한 행위<br />
          <span>③</span> 회사는 제1항 내지 제2항에도 불구하고 이용자에게 유리하게 적용될 수 있는 다른 법령이 있는 경우 그 법령을 우선하여 적용합니다.<br />
        </div>
      </div>

      <div>
        <h2 className='title'>제12조(회사의 안정성 확보 의무)</h2>
        <div className='content'>
          회사는 전자금융거래의 안전성과 신뢰성을 확보할 수 있도록 전자금융거래의 종류별로 전자적 전송이나 처리를 위한 인력, 시설, 전자적 장치 등의 정보기술부문 및 전자금융업무에 관하여 금융위원회가 정하는 기준을 준수합니다.
        </div>
      </div>

      <div>
        <h2 className='title'>제13조(선불전자지급수단의 유효기간 및 소멸시효)</h2>
        <div className='content'>
          <span>①</span> 회사는 선불전자지급수단에 대하여 1년 이상의 유효기간을 설정할 수 있으며, 이용자는 회사가 정한 유효기간 내에서만 선불전자지급수단을 사용할 수 있습니다.<br />
          <span>②</span> 회사는 어플리케이션 화면 등을 통하여 선불전자지급수단의 유효기간 설정 여부 및 그 기간을 공지합니다.<br />
          <span>③</span> 회사가 정한 유효기간이 도래하기 30일전 통지를 포함하여 3회 이상 유효기간의 도래, 연장 가능 여부와 방법, 유효기간 경과 후 잔액의 90%를 반환받을 수 있다는 내용 등을 문자메시지 전송 또는 전자문서 전송 등의 방식으로 통지합니다.<br />
          <span>④</span> 유효기간이 경과하였으나 제5항의 소멸시효가 완성되지 않은 선불전자지급수단의 경우 이용자는 회사에게 선불전자지급수단 미사용 부분에 대한 반환을 청구할 수 있으며, 이 경우 회사는 이용자가 구매하거나 충전한 잔액의 90%를 회사가 정한 절차에 의하여 이용자에게 환급합니다.<br />
          <span>⑤</span> 선불전자지급수단을 구매한 날 또는 충전일로부터 5년이 경과하면 상법상의 상사채권소멸시효가 완성되어 이용자는 회사 등에 물품 등의 제공, 환급 및 잔액반환을 요청할 수 없습니다.<br />
          <span>⑥</span> 이용자는 사업자에게 유효기간 내에는 유효기간의 연장을 요청할 수 있고, 요청을 받은 발행자는 특별한 사유가 없는 한 유효기간을 3개월 단위로 연장한다.<br />
        </div>
      </div>

      <div>
        <h2 className='title'>제14조(선불전자지급수단의 환급)</h2>
        <div className='content'>
          <span>①</span> 이용자는 선불전자지급수단의 잔액에 대하여 환급을 요청할 수 있으며, 회사는 다음 각호의 경우 수수료를 공제하지 않고 선불전자지급수단의 잔액 전부를 회사가 정한 절차에 따라 환급합니다. 다만, 다음 각호에 해당하지 않고 이용자의 단순 변심에 의한 경우 환급을 거절하거나 수수료를 공제한 후 환급할 수 있습니다.<br />
          1. 선불전자지급수단의 구매일로부터 7일 이내 구매액 전부를 환불 요청하는 경우<br />
          2. 천재지변 등의 사유로 가맹점이 재화 또는 용역을 제공하기가 곤란하여 선불전자지급수단의 사용이 불가능한 경우<br />
          3. 선불전자지급수단의 결함으로 가맹점이 재화 또는 용역을 제공하지 못하게 된 경우<br />
          4. 이용자가 정상적으로 구매하거나 충전한 선불전자지급수단 잔액이 구매시점 또는 최종 충전시점의 잔액을 기준으로 60% 이상 (1만원권 이하는 80% 이상)을 재화나 용역의 구매에 사용한 후 잔액의 환급을 요청하는 경우 (다만, 선물하기, 양도 등은 위 사용에 포함되지 않음)<br />
          <span>②</span> 제휴카드의 경우 제휴사의 환급 기준이 별도로 있는 경우 제휴사의 환급기준을 먼저 적용합니다.<br />
          <span>③</span> 선불전자지급수단의 잔액 전부가 아닌 일부에 대한 환급은 불가합니다.<br />
          <span>④</span> 재화와 용역의 구매나 이벤트 등을 통하여 회사로부터 무상 제공받은 선불전자지급수단의 경우 환급 대상에서 제외되며, 제1항 제4호의 사용액의 산정에 포함되지 않습니다. 또한 회사가 이용자에게 선불전자지급수단을 환급하는 경우 무상 제공받은 선불전자지급수단은 소멸합니다.<br />
          <span>⑤</span> 회사는 이용자의 현금 융통 목적의 부정 환급을 제한하기 위하여 환급 한도 및 횟수 등을 제한할 수 있습니다.<br />
          <span>⑥</span> 이 약관에서 환급에 대하여 정하지 않은 기타 사항은 어플리케이션 화면 등을 통해 공지합니다.<br />
        </div>
      </div>

      <div>
        <h2 className='title'>제15조(선불충전금의 관리 및 관련 공시)</h2>
        <div className='content'>
          <span>①</span> 회사는 이용자의 선불충전금을 고유재산과 구분하여 외부 금융기관에 신탁하거나 지급보증보험에 가입합니다.<br />
          <span>②</span> 회사는 매 영업일 마다 선불충전금 총액과 신탁금 등 실제 운용중인 자금 총액의 상호 일치 여부를 점검하며, 매 분기말(분기 종료 후 10일 이내) 선불충전금 규모 및 신탁내역, 지급보증보험 가입여부, 부보 금액 등을 홈페이지(www.문구야card.com) 등에 공시합니다.<br />
          <span>③</span> 회사는 다음 각 호의 어느 하나에 해당하는 경우 선불충전금을 신탁회사 및 보험회사 등을 통하여 이용자에게 우선 지급합니다. 이 경우 1개월 이내에 그 사실과 선불충전금의 지급시기, 지급장소, 그 밖에 선불충전금의 지급과 관련된 사항을 둘 이상의 일간신문에 공고하고, 인터넷 홈페이지 등을 통하여 공시합니다.<br />
          1. 등록이 취소되거나 말소된 경우<br />
          2. 해산 또는 선불전자지급수단 발행 및 관리 업무를 폐지한 경우<br />
          3. 파산선고를 받은 경우<br />
          4. 선불전자지급수단 발행 및 관리 업무의 정지명령을 받은 경우<br />
          5. 제1호부터 제4호까지에 준하는 사유가 발생한 경우<br />
        </div>
      </div>

      <div>
        <h2 className='title'>제16조 (선불충전금의 신탁 또는 지급보증보험)</h2>
        <div className='content'>
          <span>①</span> 회사는 선불충전금의 100% 이상을 이용자를 수익자로 하여 신탁하거나 이용자를 피보험자로 하여 지급보증보험에 가입합니다.<br />
          <span>②</span> 회사는 선불충전금을 원칙적으로 신탁하되, 불가피한 경우 지급보증의 방식을 혼합할 수 있습니다.<br />
          <span>③</span> 회사가 선불충전금을 신탁하는 경우 대상금액을 전액 신탁하며, 일부를 지급준비금 용도로 예치할 수 없습니다.<br />
          <span>④</span> 회사는 신탁 또는 지급보증보험에 가입하지 않은 나머지 선불충전금을 운용하는 경우 안전자산으로 운용합니다.<br />
        </div>
      </div>

      <div>
        <h2 className='title'>제17조 (착오송금에 관한 사항)</h2>
        <div className='content'>
          <span>①</span> 회원이 착오로 수취금융회사, 수취계좌번호 등을 잘못 기재하거나 입력하여 수취인에게 선불전자지급수단 등의 자금이 이동(이하 ‘착오송금’이라 합니다.)된 경우, 회사에 통지하여 회사 또는 수취 금융회사 등을 통해 수취인에게 연락하여 착오 송금액 반환을 요청할 수 있습니다.<br />
          <span>②</span> 회사는 수취인에 대한 연락 사실, 수취인의 반환의사 유무, 수취인이 반환의사가 없는 경우 그 사유 등 회원 요청사항에 대한 처리결과 또는 관련 처리 진행상황을 회원이 전항의 ‘착오송금’ 발생사실을 회사에 통지한 날로부터 15일 이내에 회원에게 알려야 합니다.<br />
          <span>③</span> 회사 또는 수취 금융회사를 통한 착오송금 반환 요청에도 수취인이 반환하지 않는 경우, 회원은 예금자보호법 제5장(착오송금 반환지원)에 따라 예금보험공사에 착오송금 반환지원 제도 이용을 신청할 수 있습니다. (개정 예금자보호법 시행일인 2021년 7월 6일 이후 발생한 착오송금에 대해 신청 가능) 단, 연락처를 통한 송금, SNS회원 간 송금 거래 등 예금보험공사가 수취인의 실지명의를 취득할 수 없는 거래는 반환지원 신청이 제한됩니다.<br />
          <span>④</span> 회사는 예금보험공사가 착오송금 반환지원 업무의 원활한 수행을 위해 회사에 착오송금 수취인의 반환불가 사유, 실지명의, 주소 및 연락처, 착오송금 발생 현황 등의 자료를 요청하는 경우 정당한 사유가 없으면 이에 따라야 합니다.<br />
          <span>⑤</span> 회원이 예금보험공사를 통해 착오송금 반환지원을 신청한 내역이 다음 각 호에 해당하는 경우 관련 법령에 따라 예금보험공사의 지원 절차가 중단될 수 있습니다.<br />
          1. 회원이 거짓이나 부정한 방법으로 반환지원을 신청한 경우<br />
          2. 착오송금이 아님이 객관적인 자료로 확인되는 경우<br />
          3. 신청일 이전 반환지원을 신청한 착오송금과 관련된 소송 등이 진행 중이거나 완료된 경우<br />
          4. 그 밖에 예금보험위원회가 인정하는 경우<br />
        </div>
      </div>

      <div>
        <h2 className='title'>제18조(분쟁의 처리 및 조정)</h2>
        <div className='content'>
          <span>①</span> 이용자는 어플리케이션 화면 등에 게시된 고객센터 담당자 또는 제8조 제5항에 기재된 연락처를 통하여 전자금융거래와 관련한 의견 및 불만의 제기, 손해배상의 청구 등 분쟁 처리를 요청할 수 있습니다.<br />
          <span>②</span> 이용자가 회사에 분쟁의 처리를 요청한 경우 회사는 요청을 수령한 날로부터 15일 이내에 이에 대한 조사 또는 처리 결과를 이용자에게 안내합니다.<br />
          <span>③</span> 이용자는 회사의 분쟁 처리 결과에 이의가 있으면 금융위원회의 설치 등에 관한 법률 제51조의 규정에 따른 금융감독원의 금융분쟁조정위원회나 소비자기본법 제31조 제1항의 규정에 따른 한국소비자원의 소비자분쟁조정위원회에 회사의 전자금융거래서비스 이용과 관련한 분쟁조정을 신청할 수 있습니다.<br />
        </div>
      </div>

      <div>
        <h2 className='title'>제19조(약관 외 준칙 및 관할법원)</h2>
        <div className='content'>
          <span>①</span> 전자금융거래에 관하여 이 약관에 정하지 않은 사항은 개별 약관이 정하는 바에 따릅니다.<br />
          <span>②</span> 이 약관과 전자금융거래에 관한 개별 약관에 정하지 않은 사항은 전자금융거래법, 전자상거래 등에서의 소비자 보호에 관한 법률, 여신전문금융업법 등 소비자 보호 관련 법령에서 정한 바에 따릅니다.<br />
          <span>③</span> 이 약관 및 전자금융거래와 관련한 제반 분쟁에 관한 소송은 민사소송법에 따른 제1심 관할법원에 제기합니다.<br />
          <span>④</span> 회사와 이용자 사이에 개별적으로 합의한 사항이 이 약관에 정한 사항과 다를 때에는 그 합의사항을 이 약관에 우선하여 적용합니다.<br /><br />

          부칙<br />
          제1조 (시행일)<br />
          <span>ⓛ</span> 이 약관은 2022년 3월 21일부터 시행합니다.<br />
        </div>
      </div>
    </>
  ) 
}

export default memo(ElectronicFinancialRegisterComponent)