// import libs
import { all } from 'redux-saga/effects';
import getListFindStoreSaga from 'screens/findStore/sagas/getListFindStoreSags';
import getListAllStoreSaga from 'screens/findStore/sagas/getListAllStoreSaga';
import deleteProductRecentSaga from 'screens/mainPage/sagas/deletePorudctSaga';
import getUserBarcodeSaga from 'screens/mainPage/sagas/getUserBarcodeSaga';
import settingPushSaga from 'screens/myPage/sagas/settingPushSaga';
import createCommentSaga from 'screens/productPage/sagas/createCommentSaga';
import deleteCommentSaga from 'screens/productPage/sagas/deleteCommentSaga';
import updateCommentSaga from 'screens/productPage/sagas/updateCommentSaga';
import findCodePhoneSaga from '../account/sagas/checkCodePhoneSaga';
import checkNickNameSaga from '../account/sagas/checkNickNameSaga';
import findPasswordSaga from '../account/sagas/findPasswordSaga';
import getSmsCodeSaga from '../account/sagas/getSMSCodeSaga';
import logOutSaga from '../account/sagas/logOutSaga';
import signInSaga from '../account/sagas/signInSaga';

/** signup */
import signUpAccountSaga from '../account/sagas/signUpAccountSaga';
import verifySmsCodeSaga from '../account/sagas/verifySmsCodeSaga';
import getListPaymentHistorySaga from '../screens/historyBuy/sagas/getListPaymentHistorySaga';
import getListHistoryPushSaga from '../screens/historyPush/sagas/getListPushSaga';
import getDataLevelSaga from '../screens/levelPage/sagas/getLevelSaga';
import getDataMainPageSaga from '../screens/mainPage/sagas/getDataMainSaga';
import getDataMaintenance from '../screens/mainPage/sagas/getDataMaintenanceSaga';
import getListStoreSaga from '../screens/myPage/sagas/getListStoreSaga';
import getMyInfoSaga from '../screens/myPage/sagas/getMyInfoSaga';
import updateUserInfoSaga from '../screens/myPage/sagas/updateUserInfoSaga';
import deleteProductEnquirySaga from '../screens/productPage/sagas/deleteProductEnquirySaga';
import getListProductEnquirySaga from '../screens/productPage/sagas/getListProductEnqurySaga';
import detailProductEnquirySaga from '../screens/productPage/sagas/getProductEnquiryDetailSaga';
import likeProductEnquirySaga from '../screens/productPage/sagas/likePorductSaga';
import registerProductEnquirySaga from '../screens/productPage/sagas/registerProductEnquirySaga';
import registerRecentProductSaga from '../screens/productPage/sagas/registerRecentProductSaga';
import updateProductEnquirySaga from '../screens/productPage/sagas/updateProductEnquirySaga';
import getDataMainPageUpdatePointSaga from '../screens/mainPage/sagas/getDataMainUpdatePointSaga';
import getDataGroupBuyingPageSaga from '../screens/order/sagas/getDataOrderSaga';
import orderGroupBuyingSaga from '../screens/order/sagas/orderGroupBuyingSaga';


export default function* RootSagas() {
  yield all([
    signUpAccountSaga(),
    checkNickNameSaga(),
    findPasswordSaga(),
    getSmsCodeSaga(),
    logOutSaga(),
    signInSaga(),
    verifySmsCodeSaga(),
    findCodePhoneSaga(),
    getDataMainPageSaga(),
    getDataMaintenance(),
    updateUserInfoSaga(),
    getListStoreSaga(),
    registerRecentProductSaga(),
    getListHistoryPushSaga(),
    getListPaymentHistorySaga(),
    getDataLevelSaga(),
    registerProductEnquirySaga(),
    getListProductEnquirySaga(),
    likeProductEnquirySaga(),
    deleteProductEnquirySaga(),
    detailProductEnquirySaga(),
    updateProductEnquirySaga(),
    getMyInfoSaga(),
    createCommentSaga(),
    deleteCommentSaga(),
    updateCommentSaga(),
    settingPushSaga(),
    deleteProductRecentSaga(),
    getListFindStoreSaga(),
    getUserBarcodeSaga(),
    getDataMainPageUpdatePointSaga(),
    getDataGroupBuyingPageSaga(),
    orderGroupBuyingSaga(),
    getListAllStoreSaga(),
  ]);
}
