import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import pageAnnoucementDetail from '../components/annoucementDetail';

import { Creators } from '../redux';

const mapStateToProps = () => {
  return {
    type: '',
    isProcessing: '',
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...Creators,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(pageAnnoucementDetail);
