import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from '../../../utils/Apis';

import { Types } from '../redux';

// worker Saga: will be fired on GET_LIST_PAYMENT_HISTORY actions
function* getListPaymentHistory(action) {
  try {
    /**
     * Example data
     * url: enpoint/login
     
     *
     */
    const response = yield call(() =>
      API.get(ROUTES.API_GET_LIST_PAYMENT_HISTORY, action.data)
    );

    if (response.ok) {
      const { data } = response;
      // In case: signup request success
      yield put({ type: Types.GET_LIST_PAYMENT_HISTORY_SUCCESS, data });
    } else {
      // In case: signup request failed
      yield put({
        type: Types.GET_LIST_PAYMENT_HISTORY_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.GET_LIST_PAYMENT_HISTORY_FAILED });
  }
}

/*
  Starts signupAccount on each dispatched `GET_LIST_PAYMENT_HISTORY` action.
*/
function* getListPaymentHistorySaga() {
  yield takeLatest(Types.GET_LIST_PAYMENT_HISTORY, getListPaymentHistory);
}

export default getListPaymentHistorySaga;
