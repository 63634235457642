// import libs
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

// Define action creators
export const { Types, Creators } = createActions({
  getDataGroupBuyingHistory: ['history'],
  getDataGroupBuyingHistorySuccess: null,
  getDataGroupBuyingHistoryFailed: null,
  resetState: null,
  getDataGroupBuying: ['data'],
  getDataGroupBuyingSuccess: null,
  getDataGroupBuyingFailed: null,
  getDataEventStore: ['location'],
  getDataEventStoreSuccess: null,
  getDataEventStoreFailed: null,
  setStoreSelected: ['obj'],
  // getAccmulutePointTimes: null,
  // getAccmulutePointTimesSuccess: null,
  // getAccmulutePointTimesFailed: null,
  orderGroupBuying: ['orderInfo'],
  orderGroupBuyingSuccess: null,
  orderGroupBuyingFailed: null,
  resetStoreSelected: null,
  resetStateOrder: null,

  getDataGroupBuyingHistoryDetail: null,
  getDataGroupBuyingHistoryDetailSuccess: null,
  getDataGroupBuyingHistoryDetailFailed: null,
  setIdQnaScroll: ['obj'],
  setIdEventProduct: ['obj'],
  setOptionEventSelected: ['obj'],
  setListOptionsEvent: ['data']
});

// Initial state
export const INITIAL_STATE = Immutable({
  isProcessingGroupBuyingHistory: true,
  isProcessingGroupBuying: true,
  isProcessingEventStore: true,
  isProcessingStoreSelected: false,
  isProcessingOrderGroupBuying: true,
  isProcessingGroupBuyingHistoryDetail: false,
  isProcessingEvent: false,
  statusCode: 0,
  dataGroupBuyingHistory: null,
  dataGroupBuying: null,
  dataEventStore: null,
  accmulutePointTimes: 0,
  storeSelected: {},
  errors: '',
  messages: '',
  errorServer: {},
  lat: '',
  lng: '',
  event_id: '',
  dataGroupBuyingHistoryDetail: null,
  dataGroupBuyingCreate: null,
  indexEvent: 0,
  idQnaScroll:0,
  idEventProduct:0,
  optionEventSelected:{},
  listOptionsEvent: []
});

const resetState = (state) => {
  return state.merge({
    isProcessingGroupBuyingHistory: false,
    isProcessingGroupBuying: false,
    isProcessingEventStore: false,
    isProcessingStoreSelected: false,
    isProcessingOrderGroupBuying: false,
    isProcessingGroupBuyingHistoryDetail: false,
    isProcessingEvent: false,
    statusCode: 0,
    type: '',
    errors: '',
    messages: '',
    errorServer: {},
    lat: '',
    lng: '',
    event_id: '',
  });
};

const getDataGroupBuyingHistory = (state, action) => {
  // console.log('----------------getDataGroupBuyingHistory')
  return state.merge({
    isProcessingGroupBuyingHistory: true,
    type: action.type,
  });
};

const getDataGroupBuyingHistorySuccess = (state, action) => {
  return state.merge({
    isProcessingGroupBuyingHistory: false,
    type: action.type,
    dataGroupBuyingHistory: action.data,
  });
};

const getDataGroupBuyingHistoryFailed = (state, action) => {
  return state.merge({
    isProcessingGroupBuyingHistory: false,
    type: action.type,
  });
};

const getDataGroupBuying = (state, action) => {
  return state.merge({
    isProcessingGroupBuying: true,
    isProcessingEvent: true,
    type: action.type,
  });
};

const getDataGroupBuyingSuccess = (state, action) => {
  return state.merge({
    isProcessingGroupBuying: false,
    isProcessingEvent: false,
    type: action.type,
    dataGroupBuying: action.data.dataGroupBuying,
    accmulutePointTimes: action.data.accmulutePointTimes,
    indexEvent: action.data.indexEvent,
  });
};

const getDataGroupBuyingFailed = (state, action) => {
  return state.merge({
    isProcessingGroupBuying: false,
    isProcessingEvent: false,
    type: action.type,
  });
};

const getDataEventStore = (state, action) => {
  return state.merge({
    isProcessingEventStore: true,
    type: action.type,
  });
};

const getDataEventStoreSuccess = (state, action) => {
  return state.merge({
    isProcessingEventStore: false,
    type: action.type,
    dataEventStore: action.data,
  });
};

const getDataEventStoreFailed = (state, action) => {
  return state.merge({
    isProcessingEventStore: false,
    type: action.type,
  });
};

const setStoreSelected = (state, action) => {
  // console.log('action123', action);
  return state.merge({
    isProcessingStoreSelected: false,
    type: action.type,
    storeSelected: action.obj,
  });
};

const setOptionEventSelected = (state, action) => {
  return state.merge({
    isProcessingStoreSelected: false,
    type: action.type,
    optionEventSelected: action.obj,
  });
}
const getQuantityByOption = (warehouse, order_quantity) => {
  return Math.floor(+warehouse / +order_quantity );
}
const setListOptionsEvent = (state, action) => {
  console.log(action, '_____action setListOptionsEvent');
    let list = action.data && action.data.length > 0 ? action.data.map(x => {
      let quantity = getQuantityByOption(x.warehouse, x.order_quantity);
      console.log(quantity, 'quantity');
      return {
        ...x,
        value: x.id,
        label:(quantity + (x.total_order == x.order_quantity ? '(품절)' : '')),
        isDisabled:(x.total_order == x.order_quantity ? true : false),
        className: (x.total_order == x.order_quantity ? 'custom-class-disabled' : '')
      }
    }) : [];
  return state.merge({
    isProcessing: false,
    type: action.type,
    listOptionsEvent: list,
  });
}

const orderGroupBuying = (state, action) => {
  // console.log(orderGroupBuying, state);
  return state.merge({
    isProcessingOrderGroupBuying: true,
    type: action.type,
    errors: '',
    messages: '',
    errorServer: {},
  });
};

const orderGroupBuyingSuccess = (state, action) => {
  return state.merge({
    isProcessingOrderGroupBuying: false,
    type: action.type,
    dataGroupBuyingCreate: action.data,
  });
};

const orderGroupBuyingFailed = (state, action) => {
  // console.log();
  console.log(action, 'orderGroupBuyingFailed failed');
  return state.merge({
    isProcessingOrderGroupBuying: false,
    type: action.type,
    statusCode: 400,
    messages: action?.data?.msg || 'Error serve',
  });
};

const resetStateOrder = (state) => {
  return state.merge({
    isProcessing: false,
    statusCode: 0,
    dataGroupBuyingHistory: null,
    dataGroupBuying: null,
    dataEventStore: null,
    accmulutePointTimes: 0,
    storeSelected: {},
    errors: '',
    messages: '',
    errorServer: {},
  });
};

const getDataGroupBuyingHistoryDetail = (state, action) => {
  return state.merge({
    isProcessingGroupBuyingHistoryDetail: true,
    type: action.type,
  });
};

const getDataGroupBuyingHistoryDetailSuccess = (state, action) => {
  return state.merge({
    isProcessingGroupBuyingHistoryDetail: false,
    type: action.type,
    dataGroupBuyingHistoryDetail: action.data,
  });
};

const getDataGroupBuyingHistoryDetailFailed = (state, action) => {
  return state.merge({
    isProcessingGroupBuyingHistoryDetail: false,
    type: action.type,
  });
};

const setIdQnaScroll = (state, action) => {
  // console.log(state,'setIdQnaScroll', action);
  return state.merge({
    isProcessing: false,
    type: action.type,
    idQnaScroll: action.obj,
  });
};
const setIdEventProduct = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
    idEventProduct: action.obj,
  });
};



// Assign handler to types.
const HANDLERS = {
  [Types.GET_DATA_GROUP_BUYING]: getDataGroupBuying,
  [Types.GET_DATA_GROUP_BUYING_SUCCESS]: getDataGroupBuyingSuccess,
  [Types.GET_DATA_GROUP_BUYING_FAILED]: getDataGroupBuyingFailed,

  [Types.GET_DATA_GROUP_BUYING_HISTORY]: getDataGroupBuyingHistory,
  [Types.GET_DATA_GROUP_BUYING_HISTORY_SUCCESS]:
    getDataGroupBuyingHistorySuccess,
  [Types.GET_DATA_GROUP_BUYING_HISTORY_FAILED]: getDataGroupBuyingHistoryFailed,

  [Types.GET_DATA_GROUP_BUYING_HISTORY_DETAIL]: getDataGroupBuyingHistoryDetail,
  [Types.GET_DATA_GROUP_BUYING_HISTORY_DETAIL_SUCCESS]:
    getDataGroupBuyingHistoryDetailSuccess,
  [Types.GET_DATA_GROUP_BUYING_HISTORY_DETAIL_FAILED]:
    getDataGroupBuyingHistoryDetailFailed,

  [Types.RESET_STATE]: resetState,

  [Types.GET_DATA_EVENT_STORE]: getDataEventStore,
  [Types.GET_DATA_EVENT_STORE_SUCCESS]: getDataEventStoreSuccess,
  [Types.GET_DATA_EVENT_STORE_FAILED]: getDataEventStoreFailed,

  [Types.SET_STORE_SELECTED]: setStoreSelected,
  [Types.SET_OPTION_EVENT_SELECTED]: setOptionEventSelected,
  [Types.SET_LIST_OPTIONS_EVENT]: setListOptionsEvent,

  [Types.ORDER_GROUP_BUYING]: orderGroupBuying,
  [Types.ORDER_GROUP_BUYING_SUCCESS]: orderGroupBuyingSuccess,
  [Types.ORDER_GROUP_BUYING_FAILED]: orderGroupBuyingFailed,

  [Types.RESET_STATE_ORDER]: resetStateOrder,

  [Types.SET_ID_QNA_SCROLL]: setIdQnaScroll,
  [Types.SET_ID_EVENT_PRODUCT]: setIdEventProduct,

};

// Create reducers by pass state and handlers
export const groupBuyingReducer = createReducer(INITIAL_STATE, HANDLERS);
