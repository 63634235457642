/* eslint-disable camelcase */
import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from 'utils/Apis';

import { Types } from '../redux';

// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* registerRecentProduct(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() =>
      API.post(ROUTES.API_REGISTER_PRODUCT, action.data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    );

    if (response.ok) {
      const { data } = response;
      // In case: signup request success
      yield put({ type: Types.REGISTER_RECENT_PRODUCT_SUCCESS, data });
    } else {
      yield put({
        type: Types.REGISTER_RECENT_PRODUCT_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.REGISTER_RECENT_PRODUCT_FAILED, error });
  }
}

/*
  Starts registerRecentProduct on each dispatched `REGISTER_RECENT_PRODUCT` action.
*/
function* registerRecentProductSaga() {
  yield takeLatest(Types.REGISTER_RECENT_PRODUCT, registerRecentProduct);
}

export default registerRecentProductSaga;
