// @flow

import Button from 'components/Button';
import InputAutoCompleteSearch from 'components/AutoComplete';
import ModalPopup from 'components/Modal';
import NoData from 'components/NoData';
import ROUTERS from 'constants/router';
import useClickOutside from 'customHooks/useClickOutSide';
import MainLayout from 'layout/MainLayout';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import images from 'themes/images';
import ItemProduct from './ItemProduct';
import Regex from '../../../constants/regex';
import REGEX from "../../../constants/regex";
import ModalTouchBottom from 'components/Modal/ModalTouchBottom';
import iconLoading from '../../../assets/images/loading1.gif'
import Loading from 'components/Loading';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

type Props = {
  history: {
    push: Function,
  },
  isProcessing: boolean,
  isProcessingProduct: boolean,
  getListProductEnquiry: Function,
  listProductEnquiry: Array<{
    id: number,
  }>,
  userInfo: Object,
  likeProductEnquiry: Function,
  deleteProductEnquiry: Function,
  typeRequest: string,
  statusCode: any,
  resetTypeRegister: Function,
  total: number,
  createComment: Function,
  deleteComment: Function,
  updateComment: Function,
  getMyInfo: Function,
  listStoreLikeOfUser: Array<{
    id: number,
  }>,
  dataMyInfo: Object,
  updateProfileUserInfo: Function,
  storeSearch: any,
  setStoreActive: Function,
  getListAllStore: Function,
  listAllStore: Array,
  isProcessingLike: Boolean,
  updateListProductEnquiry: Function,
  isProcessingComment: Boolean,
  isProcessingDelProductEnquiry: Boolean
};

const ProductComponent = ({
  history,
  isProcessing,
  getListProductEnquiry,
  listProductEnquiry,
  userInfo,
  likeProductEnquiry,
  deleteProductEnquiry,
  typeRequest,
  resetTypeRegister,
  statusCode,
  total,
  isProcessingProduct,
  createComment,
  deleteComment,
  updateComment,
  getMyInfo,
  listStoreLikeOfUser,
  listAllStore,
  getListAllStore,
  dataMyInfo,
  updateProfileUserInfo,
  storeSearch,
  setStoreActive,
  isProcessingLike,
  updateListProductEnquiry,
  isProcessingComment,
  isProcessingDelProductEnquiry
}: Props) => {
  const CONCAT_IMAGE_LEVEL = {
    1: images.icon_level1,
    2: images.icon_level2,
    3: images.icon_level3,
    4: images.icon_level4,
    5: images.icon_level5,
  };
  const optionDefault = {
    id: 0,
    value: 0,
    label: '지점 선택',
  };

  const optionSelectStore = [
    {
      id: 0,
      value: 'store_name',
      label: '가맹점명',
    },
    {
      id: 1,
      value: 'content',
      label: '문의 내용',
    },
  ];

  const optionSelectFilter = [
    {
      id: 0,
      value: 'comment',
      label: '최신 순',
    },
    {
      id: 1,
      value: 'like',
      label: '좋아요 순',
    },
    {
      id: 2,
      value: 'content',
      label: '댓글 순',
    },
  ];

  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModalSelect, setIsShowModalSelect] = useState(false);
  const [isShowOverlay, setIsShowOverlay] = useState(false);
  const [avatarOffUser, setAvatarOffUser] = useState(
    dataMyInfo?.profile || CONCAT_IMAGE_LEVEL[dataMyInfo?.level]
  );
  const [activeItemStore, setActiveItemStore] = useState(
    storeSearch || optionDefault
  );
  const [isShowModalView, setIsShowModalView] = useState(false);
  const typeLogin = userInfo?.is_admin === 'Y' ? 'admin' : 'user'; // Kiểm tra loại login nếu là admin thì cho xóa sản phẩm
  const idUserLogin = userInfo?.user_name; // Kiểm tra loại ID của user nếu chính là user login thì cho xóa và chỉnh sửa
  const [itemDelete, setItemDelete] = useState(null);
  const [imagesView, setImagesView] = useState({});
  const [editCommentId, setEditCommentId] = useState(null);
  const [idCommentOfUser, setIdCommentOfUser] = useState(null);
  const [itemCommentDelete, setItemCommentDelete] = useState(null);
  const [contentComment, setContentComment] = useState('');
  const [contentEditComment, setContentEditComment] = useState('');
  const [isShowModalDeleteComment, setIsShowModalDeleteComment] = useState(false);
  const [isClickSearch, setIsClickSearch] = useState(false);
  const [typeSeachOrFilter, setTypeSeachOrFilter] = useState('');
  const [showEditDel, setShowEditDel] = useState({
    isShow: false,
    idShow: null,
    idCmtShow: null,
  });
  const [showCmtEditDel, setShowCmtEditDel] = useState({
    isShow: false,
    idShow: null
  });
  const [showModal, setShowModal] = useState({
    isShow: false,
    content: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [dataSearch, setDataSearch] = useState({
    type: 'store_name',
    keyword: '',
    sort: 'comment',
    store_id: null,
    page: 1
  })
  const [dataSelectModal, setDataSelectModal] = useState(optionSelectStore);
  const [storeSelected, setStoreSelected] = useState(null);
  const [resetSearch, setResetSearch] = useState(false);
  const [isShowData, setIsShowData] = useState(true);
  const [isDisable, setIsDisable] = useState(false);
  const [isLoadingNative, setIsLoadingNative] = useState(true);


  useEffect(() => {
    if (isShowModalSelect) {
      if (document.body) {
        document.body.className = 'body-overflow';
      }
    } else if (document.body) {
      document.body.className = '';
    }
  }, [isShowModalSelect]);

  useEffect(() => {
    getMyInfo();
    resetTypeRegister();
    getListAllStore();
    setIsDisable(true)
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      if (!history.location.pathname.includes(`${ROUTERS.INQUIRY_PRODUCT}`)) {
        setStoreActive(null);
      }
    };
    // eslint-disable-next-line
  }, [activeItemStore, activeItemStore.id]);

  useEffect(() => {
    console.log(dataSearch, 'dataSearch');
    getListProductEnquiry({
      type: dataSearch?.type || '',
      keyword: dataSearch?.keyword || '',
      sort: dataSearch?.sort || '',
      store_id: dataSearch?.store_id || '',
      page: 1,
    });
  }, [dataSearch])

  useEffect(() => {
    updateProfileUserInfo({
      profile_photo_url:
        dataMyInfo?.profile || CONCAT_IMAGE_LEVEL[dataMyInfo?.level],
      name: dataMyInfo?.name,
      phone_number: dataMyInfo?.phone_number,
    });
    setAvatarOffUser(
      dataMyInfo?.profile || CONCAT_IMAGE_LEVEL[dataMyInfo?.level]
    );
    // eslint-disable-next-line
  }, [dataMyInfo]);

  const handleOnBlurInput = () => {
    if (contentComment.trim().match(REGEX.LAST_CHARACTER_POLICY)) {
      setShowModal({
        ...showModal,
        isShow: true,
        content: '모음을 마지막에 입력한 상태로 저장 할 수 </br>없습니다.',
      });
    } else {
      setTimeout(() => {
        if (document.body) {
          document.body.className = '';
        }
      }, 300);
    }
  };
  const handleOnFocusInput = () => {
    if (document.body) {
      document.body.className = 'hidden-footer';
    }
  };

  // const handleGetListProductEnquiry = useCallback(
  //   (storeId) => {
  //     console.log('handleGetListProductEnquiry scroll');
  //     getListProductEnquiry({
  //       ...dataSearch,
  //       store_id: storeId,
  //       page: currentPage,
  //     });
  //   },
  //   [currentPage]
  // );

  useEffect(() => {
    console.log(currentPage, 'currentPage');
    if (currentPage !== 1) {
      getListProductEnquiry({
        ...dataSearch,
        page: currentPage,
      });
    }
  }, [currentPage])

  // useEffect(() => {
  //   handleGetListProductEnquiry(null);
  // }, [handleGetListProductEnquiry]);

  useEffect(() => {
    setStoreActive(activeItemStore);
    if (activeItemStore && activeItemStore.id) {
      getListProductEnquiry({
        store_id: activeItemStore.id || null,
        page: 1,
      });
      // activeItemStore
    } else if (isClickSearch) {
      getListProductEnquiry({
        store_id: null,
        page: 1,
      });
    }
    // eslint-disable-next-line
  }, [activeItemStore, isClickSearch]);

  useEffect(() => {
    switch (typeRequest) {
      case 'DELETE_PRODUCT_ENQUIRY_SUCCESS':
        if (statusCode !== 200) {
          setShowModal({
            ...showModal,
            isShow: true,
            content: '제품 삭제 실패.',
          });
        }
        break;
      case 'DELETE_PRODUCT_ENQUIRY_FAILED':
        setShowModal({
          ...showModal,
          isShow: true,
          content: '제품 삭제 실패.',
        });
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [typeRequest]);

  const handleShowDeleteProduct = (item) => {
    setItemDelete(item?.id);
    setIsShowModal(true);
  };

  const handleDeleteProduct = () => {
    setIsShowModal(false);
    deleteProductEnquiry({
      inquiry_id: itemDelete,
    });
  };

  const handleViewImage = (item) => {
    setImagesView(item);
    setIsShowModalView(true);
  };

  const handleShowDeleteComment = (item) => {
    setItemCommentDelete(item);
    setIsShowModalDeleteComment(true);
    setIdCommentOfUser(null);
  };

  const handleDeleteComment = () => {
    const params = {
      inquiry_id: editCommentId,
      comment_id: itemCommentDelete?.id,
    };
    deleteComment(params);
    setIsShowModalDeleteComment(false);
  };

  const handleClickComment = (itemId) => {
    if (editCommentId === itemId) {
      setEditCommentId(null);
    } else {
      setEditCommentId(itemId);
    }
    setContentComment('');
  };

  const handleChangeComment = (value, name) => {
    console.log(355, value, name);
    if (value.length <= 50) {
      switch (name) {
        case 'comment':
          setContentComment(value);
          break;
        case 'editComment':
          setContentEditComment(value);
          break;
        default:
          break;
      }
    }
    
  };

  const handleGetContentOfUser = (item) => {
    if (idCommentOfUser === item.id) {
      setIdCommentOfUser(null);
      setContentEditComment('');
    } else {
      setIdCommentOfUser(item.id);
      setContentEditComment(item.comment);
    }
  };

  const handleSubmitComment = () => {
    if (contentComment.trim().match(REGEX.LAST_CHARACTER_POLICY)) {
      setShowModal({
        ...showModal,
        isShow: true,
        content: '모음을 마지막에 입력한 상태로 저장 할 수 </br>없습니다.',
      });
    }else {
      const params = {
        inquiry_id: editCommentId,
        comment: contentComment,
      };
      createComment(params);
      setContentComment('');
    }
  };

  const handleSubmitEditComment = () => {
    if (contentEditComment.trim().match(REGEX.LAST_CHARACTER_POLICY)) {
      setShowModal({
        ...showModal,
        isShow: true,
        content: '모음을 마지막에 입력한 상태로 저장 할 수 </br>없습니다.',
      });
    }else{
      const paramUpdate = {
        comment_id: idCommentOfUser,
        comment: contentEditComment,
      };
      updateComment(paramUpdate, editCommentId);
      setContentEditComment('');
      setIdCommentOfUser(null);
      setContentEditComment('');
    }
  };

  const handleSubmitLike = (item, isLike) => {
    likeProductEnquiry(
      {
        inquiry_id: item,
      },
      isLike
    );
  };

  const handleUpdateListProductEnquiry = (item, isLike) => {
    updateListProductEnquiry(item, isLike)
  }

  // const handleToggleModal = () => {
  //   setIsShowModalSelect(false);
    
  // };

  const listStoreLike = [optionDefault, ...listStoreLikeOfUser];

  // const renderListStore =
  //   listStoreLike &&
  //   listStoreLike.map((item) => {
  //     const activeList =
  //       activeItemStore && activeItemStore.id === item?.id ? 'active' : '';
  //     return (
  //       <SwiperSlide key={item.id}>
  //         <div className={`item ${activeList}`}>{item.label}</div>
  //       </SwiperSlide>
  //     );
  //   });

    useEffect(() => {
      setShowEditDel({
        ...showEditDel,
        isShow: true,
      })
    }, [showEditDel.idShow])

    useEffect(() => {
      setShowCmtEditDel({
        ...showCmtEditDel,
        isShow: true,
      })
    }, [showCmtEditDel.idShow])

    const handleShowEditDel = (id) => {
      setShowEditDel({
        isShow: !showEditDel.isShow,
        idShow: id
      })
    }

    const handleShowCmtEditDel = (id) => {
      setShowEditDel({
        isShow: !showEditDel.isShow,
        idCmtShow: id
      })
    }

  const renderListInquiryProduct =
    listProductEnquiry && listProductEnquiry.length > 0 ? (
      listProductEnquiry.map((item) => (
        <ItemProduct
          key={item.id}
          itemObj={item}
          handleDelete={handleShowDeleteProduct}
          history={history}
          idUserLogin={idUserLogin}
          contentComment={contentComment}
          handleChange={handleChangeComment}
          handleViewImage={handleViewImage}
          handleDeleteComment={handleShowDeleteComment}
          handleClickComment={handleClickComment}
          editCommentId={editCommentId}
          idCommentOfUser={idCommentOfUser}
          handleGetContentOfUser={handleGetContentOfUser}
          handleSubmitComment={handleSubmitComment}
          handleSubmitLike={handleSubmitLike}
          contentEditComment={contentEditComment}
          handleSubmitEditComment={handleSubmitEditComment}
          handleOnFocusInput={handleOnFocusInput}
          handleOnBlurInput={handleOnBlurInput}
          avatarOffUser={avatarOffUser}
          handleShowEditDel={handleShowEditDel}
          showEditDel={showEditDel}
          showCmtEditDel={showCmtEditDel}
          handleShowCmtEditDel={handleShowCmtEditDel}
          handleUpdateListProductEnquiry={handleUpdateListProductEnquiry}
        />
      ))
    ) : (
      !isProcessingProduct && <NoData text="검색 결과가 없습니다." />
    );

    const handleSearch = (data) => {
      setDataSearch({
        ...dataSearch,
        store_id: data.id || null,
        page: 1,
      })
      // getListProductEnquiry({
      //   ...dataSearch,
      //   store_id: data.id || null,
      //   page: 1,
      // });
    }

    const handleSearchText = (text) => {
      setStoreSelected(null);
      setResetSearch(false);
      setDataSearch({
        ...dataSearch,
        type: 'store_name',
        keyword: text,
        store_id: null
      })

      // getListProductEnquiry({
      //   keyword: text || null,
      //   page: 1,
      // });
    }

    const handleClickSelectDropdown = (type) => {
      if (type === 'select_filter') {
        setTypeSeachOrFilter('filter')
        setDataSelectModal(optionSelectFilter)
      }else{
        setTypeSeachOrFilter('select')
        setDataSelectModal(optionSelectStore)
      }
      setIsShowModalSelect(true);
      setIsShowOverlay(!isShowOverlay)
    }

    const onChangeInputSearch = (text) => { 
      setIsDisable(false);
      // setDataSearch({
      //   ...dataSearch,
      //   keyword: text,
      // })
    }

    const showDataProduct = (isShow) => {
      setIsShowData(!isShow)
    }

    const handleSelect = (data) => {
      if (typeSeachOrFilter === 'filter') {
        setDataSearch({
          ...dataSearch,
          sort: data.value,
        })
      }else{
        setDataSearch({
          ...dataSearch,
          type: data.value || '',
          label: data?.label || '',
        })
      }
    }

    const handleSelectStore = (data) => {
      console.log('handleSelectStore', data);
      if (data.id === dataSearch.store_id) {
        setStoreSelected(null);
        setDataSearch({
          ...dataSearch,
          type: 'store_name',
          keyword: '',
        })
      }else{
        setStoreSelected(data);
        setResetSearch(true);
        setDataSearch({
          ...dataSearch,
          type: '',
          keyword: '',
          store_id: data.id,
        })
      }
      
    };

    // eslint-disable-next-line consistent-return
    const renderListStore = (data) => {
      if (data && data.length > 0) {
        return data.map((item) => (
          <li
            key={item.id}
            className={`item ${
              storeSelected && storeSelected?.id === item.id ? 'selected' : ''
            }`}
            aria-hidden
            onClick={() => handleSelectStore(item)}
          >
            {item.name}
          </li>
        ));
      }
    };

  window.onscroll = function() {
    let body = window.document.body; //IE 'quirks'
    let document = window.document.documentElement; //IE with doctype
    document = (document.clientHeight) ? document : body;
    if (document.scrollTop == 0) {
      setCurrentPage(1);
      setDataSearch({
        ...dataSearch,
        page: 1
      })
    }        
  };

  const replacePathImg = (url) => {
    console.log(url, 'lllllllllll');
    // if (url) {
    //   const newUrl = url.replace('/images/productInquiry', '');
    //   return newUrl;
    // }
  }

  // console.log(imagesView, 'imagesView');

  return (
    <MainLayout
      // isMarginBottom
      // activePage={4}
      isShowHeader
      titleHeader="상품 문의"
      // isCenter
      // isShowFooter
      isLink
      // isShowBtnAdd
      // icoBackWhite
      isShowIcon
      customClass="page-product-list"
      isShowBtnAdd
      typeLogin
      history={history}
      isBackNative
      isLoading={
        isProcessing || isProcessingComment || isProcessingDelProductEnquiry ||
        (isProcessingProduct && storeSelected) || 
        (isProcessingProduct && currentPage < 2)
      }
    >
      <div className="product-page">
        {/* {isProcessingProduct && <img style={{width: '20px', marginTop: '10px'}} src={iconLoading} alt="" />} */}
        <div className="list-store-select">
          <ul className="list">
            {renderListStore(listStoreLikeOfUser)}
          </ul>
        </div>

        <InputAutoCompleteSearch 
          data={listAllStore || []}
          handleSearch={handleSearch}
          handleSearchText={handleSearchText}
          onChangeInputSearch={onChangeInputSearch}
          showDataProduct={showDataProduct}
          placeholder="가맹점명을 입력해 주세요"
          // hasSelect
          showBoxResult
          handleClickSelectDropdown={handleClickSelectDropdown}
          dataSelected={dataSearch}
          classResult="data-store-product"
          showIconSearch
          classDiv="flex-search"
          disabledSearch={isDisable}
        />
        {/* <div className="sort-store">
          <div
            className="select-name"
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
            onClick={() => setIsShowModalSelect(true)}
          >
            <div className="name">
              {activeItemStore && activeItemStore.label}
            </div>
            <img src={images.iconArrowBB} alt="" />
          </div>
        </div> */}
        {
          isShowData && <div className="list-product-inquiry">
          <InfiniteScroll
            dataLength={(listProductEnquiry && listProductEnquiry.length) || 0}
            next={() => {
              console.log(22222222222222);
              // setDataSearch({...dataSearch, page: dataSearch.page + 1});
              setCurrentPage(currentPage + 1)
            }}
            hasMore={listProductEnquiry && listProductEnquiry.length < total}
            loader={
              <div className="d-flex justify-content-center pt-20 mt-2">
                {/* <Spinner animation="grow" variant="success" /> */}
                {isProcessingProduct && <img style={{width: '20px', marginTop: '10px'}} src={iconLoading} alt="" />}
              </div>
            }
          >
            {renderListInquiryProduct}
          </InfiniteScroll>
        </div>
        }
        
      </div>

      {/* Popup delete */}
      <ModalPopup
        isOpen={isShowModal}
        isShowHeader
        // isShowFooter
        isShowTwoBtn
        handleCloseOvl={() => setIsShowModal(false)}
        // handleClose={() => setIsShowModal(false)}
        // handleSubmit={() => handleDeleteProduct()}
        textBtnLeft="아니요"
        textBtnRight="네, 삭제할게요."
        customClass="product-popup delete-comment-popup"
      >
        <div className="content">
          {/* <div>
            <img src={images.iconPopup} alt="" className="img-popup" />
          </div> */}
          <h3>글을 삭제하시겠습니까?</h3>
          <div className="group-button-popup">
            <Button
              type="button"
              customClass='button--half btn-left'
              onClick={() => setIsShowModal(false)}
            >
              취소
            </Button>
            <Button
              type="button"
              customClass='button--half btn-right'
              onClick={() => handleDeleteProduct()}
            >
              삭제
            </Button>
          </div>
        </div>
      </ModalPopup>

      <ModalPopup
        isOpen={isShowModalDeleteComment}
        isShowHeader
        // handleCloseOvl={() => setIsShowModalDeleteComment(false)}
        // handleClose={() => setIsShowModalDeleteComment(false)}
        // handleSubmit={() => handleDeleteComment()}
        // textBtnLeft="아니요"
        // textBtnRight="네, 삭제할게요."
        customClass="product-popup delete-comment-popup"
      >
        <div className="content">
          <h3> 댓글을 삭제하시겠어요?</h3>
          <div className="group-button-popup">
            <Button
              type="button"
              customClass='button--half btn-left'
              onClick={() => setIsShowModalDeleteComment(false)}
            >
              취소
            </Button>
            <Button
              type="button"
              customClass='button--half btn-right'
              onClick={() => handleDeleteComment()}
            >
              삭제
            </Button>
          </div>
        </div>
      </ModalPopup>

      {/* Popup view image */}
      <ModalPopup
        isOpen={isShowModalView}
        isShowIconClose
        handleClose={() => setIsShowModalView(false)}
        handleCloseOvl={() => setIsShowModalView(false)}
        customClass="view-product-popup"
      >
        <div className='review-modal'>
          <div className="img-review">
            <img className='img' src={imagesView} alt="" />
            {/* <div
              className='img'
              style={{
                backgroundImage: `url(${imagesView}), url(${replacePathImg(imagesView)})`,
                backgroundSize: 'contain'
              }}
            /> */}
          </div>
          {/* <button type='button' className='btn btn-close-review-img' onClick={() => setIsShowModalView(false)}>확인</button> */}
        </div>
      </ModalPopup>
      {/* Modal show list Store select chose  */}

            
      {
        isShowModalSelect && 
        <div className={`modal-touch-bottom ${isShowOverlay ? 'overlay' : ''}`}>
            <ModalTouchBottom 
              isShowOverlay={isShowOverlay}
              setIsShowOverlay={setIsShowOverlay}
              data={dataSelectModal}
              handleSelect={handleSelect}
              dataActive={dataSearch}
              customClass={typeSeachOrFilter === 'filter' ? 'filter_store' : ''}
            />
          </div>
      }
      
      {/* <div
        className={`modal-select ${isShowModalSelect ? 'open' : ''}`}
        ref={wrapperRef}
      >
        <div className="modal-select--header">
          <span>지점 선택</span>
          <div
            className="modal-close"
            onClick={() => {
              handleToggleModal();
            }}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
          >
            <img src={images.pop_X} alt="" />
          </div>
        </div>
        <div className="modal-content">
          <p>가맹점명</p>
          <p>문의 내용</p>
        </div>
      </div> */}

      <ModalPopup
        isOpen={showModal?.isShow}
        isShowFooter
        isShowHeader
        handleClose={() =>
          setShowModal({
            ...showModal,
            isShow: false,
            content: '',
          })
        }
        textBtnRight="확인"
      >
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: showModal?.content }}
        />
      </ModalPopup>
      {/* {
        isProcessingLike && <Loading />
      } */}
    </MainLayout>
  );
};

export default memo<Props>(ProductComponent);
