// @flow

import React, { useEffect, useState } from 'react';
import images from 'themes/images';
import {Map, MapMarker } from 'react-kakao-maps-sdk';

const KakaoMap = ({
  listLocationStore,
  keySearch,
  handleGetDataStore,
  isClickSearch,
  handleGotoStoreDetail,
  myLocationApp,
  getGPSCurrentLocation
}: Props) => {

  // const initLocation = {
  //   lat: window.lat ?? 37.564990479966966,
  //   lng: window.lng ?? 127.08167785379084
  // };
  const zoom = 5;
  const [zIndex, setZindex] = useState(0);

  const [isClick, setIsClick] = useState(false);
  const [initialCenter, setInitialCenter] = useState(myLocationApp);
  const [myLocation, setMyLocation] = useState(myLocationApp);
  const [level, setLevel] = useState(zoom);
  const [panto, setPanto] = useState(false);
  
  const [dataMarker, setDataMarker] = useState({
    showingInfoWindow: false,
    location: {},
    selectedPlace: '',
    address: '',
  });

  const [listLocationStoreLike, setListLocationStoreLike] = useState(
    listLocationStore || []
  );

  useEffect(() => {
    if (myLocationApp?.lat && myLocationApp?.lng) {
      setInitialCenter(myLocationApp);
      setMyLocation(myLocationApp)
    }
  }, [myLocationApp])

  const resetMap = () => {
    setIsClick(false);
    setInitialCenter(myLocationApp);
    setLevel(zoom)
    setDataMarker({
      location: {
        lat: '',
        lng: '',
      },
      showingInfoWindow: false,
      address: '',
      name: '',
    });
    handleGetDataStore({
      address: '',
      storeName: '',
    });
  }

  // useEffect(() => {
  //   const latAndLong = myLocationApp && myLocationApp.split(',');
  //   console.log(latAndLong,myLocationApp, 'latAndLong');
  //   if (myLocationApp) {
  //     // alert(latAndLong[0], 'lat');
  //     setInitialCenter({
  //       lat: latAndLong[0],
  //       lng: latAndLong[1],
  //     });
  //     setMyLocation({
  //       lat: latAndLong[0],
  //       lng: latAndLong[1],
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [isClick]);

  useEffect(() => {
    setListLocationStoreLike(listLocationStore);
    if (listLocationStore && listLocationStore[0] && keySearch) {
      if (listLocationStore.length === 1) {
        setIsClick(true);
        setDataMarker({
          location: {
            lat: listLocationStore[0].lat,
            lng: listLocationStore[0].lng,
          },
          showingInfoWindow: true,
          address: listLocationStore[0].address,
          name: listLocationStore[0].name,
        });
        handleGetDataStore({
          address: listLocationStore[0].address,
          storeName: listLocationStore[0].name,
        });
        setInitialCenter({
          lat: listLocationStore[0].lat,
          lng: listLocationStore[0].lng,
        });
      }else{
        setIsClick(false);
        setInitialCenter({
          lat: listLocationStore[0].lat,
          lng: listLocationStore[0].lng,
        });
        setLevel(zoom)
        setDataMarker({
          location: {
            lat: '',
            lng: '',
          },
          showingInfoWindow: false,
          address: '',
          name: '',
        });
        handleGetDataStore({
          address: '',
          storeName: '',
        });
      }
    } else {
      resetMap();
    }
    // eslint-disable-next-line
  }, [listLocationStore, isClickSearch]);

  

  const onMarkerClick = (item) => {
    setZindex(-1)
    setIsClick(true);
    setPanto(true)
    setDataMarker({
      location: {
        lat: item.lat,
        lng: item.lng
      },
      showingInfoWindow: true,
      address: item.address,
      name: item.name,
    });
    setInitialCenter({
      lat: item.lat,
      lng: item.lng
    });
    handleGetDataStore({
      address: item.address,
      storeName: item.name,
    });
  };

  const onMapClicked = () => {
    setIsClick(false);
    if (dataMarker?.showingInfoWindow) {
      setDataMarker({
        location: {
          lat: '',
          lng: '',
        },
        showingInfoWindow: false,
        address: '',
        name: '',
      });
      handleGetDataStore({
        address: '',
        storeName: '',
      });
    }
  };

  const onClickUpdateLocation = () => {
    getGPSCurrentLocation();
    setPanto(true);
    setZindex(9999);
    // console.log(myLocationApp, 'myLocationApp');
    if (myLocationApp) {
      setInitialCenter({
        lat: myLocationApp?.lat || '',
        lng: myLocationApp?.lng || ''
      });
      setMyLocation({
        lat: myLocationApp?.lat || '',
        lng: myLocationApp?.lng || ''
      });
    }else{
      setInitialCenter(myLocationApp);
      setMyLocation(myLocationApp);
    }

  };

  const handleClosePopup = () => {
    setIsClick(false);
  }

  const handleClickDetail = (data) => {
    handleGotoStoreDetail(data)
  }

  const showInfoStore = (data) => {
    return (
      <div className='info-window'>
        <div aria-hidden className='content' onClick={() => handleClickDetail(data)}>
          <h4 className='title-store'>
            {data.name}
          </h4>
          <div className='store-address'>
            {data.address}
          </div>
        </div>
        <div className='close' aria-hidden  onClick={handleClosePopup}>
          <img className='close-icon-info' src={images.iconClosePopup} alt=""/>
        </div>
      </div>
    )
  }

  return (
    <>
      <Map
        className="map"
        center={initialCenter}
        zoomable
        level={level}
        onClick={onMapClicked}
        isPanto={panto}
        onZoomChanged={(map) => {
          setLevel(map.getLevel())
        }}
        onDragEnd={(map) => {
          setZindex(-1)
          setInitialCenter({
            lat: map.getCenter().getLat(),
            lng: map.getCenter().getLng(),
          })
        }}
        // onCenterChanged={(map) => {
        // }}
      >
        {listLocationStoreLike.map((item) => (
          <MapMarker 
            key={`${item.id}-${item.lat}-${item.lng}`}
            position={{ lat: item.lat, lng: item.lng }}
            image={{
              src: images.iconPosition,
              size: {
                width: 18,
                height: 27
              },
              options: {
                offset: {
                  x: 10,
                  y: 27
                }
              }
            }}
            clickable
            onClick={() => onMarkerClick(item)}
          >
            {
              isClick && 
              item.lat === dataMarker.location?.lat && 
              item.lng === dataMarker.location?.lng && 
              showInfoStore(item)
            }
          </MapMarker>
        ))}

        <MapMarker
          position={myLocation}
          zIndex={zIndex}
          image={{
            src: images.icon_location_current,
            size: {
              width: 32,
              height: 32
            },
            options: {
              offset: {
                x: 17,
                y: 32
              }
            }
          }}
        />
      </Map>
      <div className="icon_gps" aria-hidden onClick={onClickUpdateLocation}>
        <div className="bgr-icon-gps"> 
          <img src={images.icon_gps} alt="" />
        </div>
      </div>
    </>
  );
};

export default KakaoMap
