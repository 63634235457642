import React, { useRef, useEffect } from 'react';

type Props = {
  data?: Array,
  isShowOverlay?: Boolean,
  setIsShowOverlay?: Function,
  handleSelect?: Function,
  dataActive?: Object,
  customClass?: String,
  type?: String,
  isShowChildren?: Boolean,
  children?: String,
};

const ModalTouchBottom = ({
  isShowOverlay,
  setIsShowOverlay,
  data,
  handleSelect,
  dataActive,
  customClass,
  type,
  children,
  isShowChildren,
}: Props) => {
  const wrapperRef = useRef(null);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        console.log(ref?.current, '11111111');
        console.log(event.target, '2222222');
        if (!ref?.current?.contains(event.target)) {
          setIsShowOverlay(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
  };
  useOutsideAlerter(wrapperRef);

  let startY;
  let endY;
  useEffect(() => {
    if (!isShowOverlay && document.body) {
      document.body.className = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowOverlay]);

  const handelOnTouchStart = (event) => {
    startY = event?.changedTouches[0]?.clientY;
  };

  // touch remove
  const handelOnTouchMove = () => {
    setIsShowOverlay(true);
  };
  // touch remove
  const handelOnTouchEnd = (event, cls = '') => {
    endY = event?.changedTouches[0]?.clientY;
    if (cls === 'list-store-more') {
      if (startY - endY < -300 && startY !== endY) {
        setIsShowOverlay(false);
      }
    } else if (startY - endY < -50 && startY !== endY) {
        setIsShowOverlay(false);
      }
  };

  const handleClickItem = (item) => {
    setIsShowOverlay(false);
    handleSelect(item);
  };

  const renderContent = (arr) => {
    if (arr.length > 0) {
      return (
        <ul className="content">
          {arr.map((item) => (
            <li
              key={item.id}
              className={`item ${
                dataActive.type === item.value || dataActive.sort === item.value
                  ? 'active'
                  : ''
              }`}
              aria-hidden
              onClick={() => handleClickItem(item)}
            >
              {item.label}
            </li>
          ))}
        </ul>
      );
    }
  };
  return (
    <div
      className={`touch-bottom ${
        isShowOverlay ? 'touchend' : 'back'
      } ${customClass}`}
      ref={wrapperRef}
      onTouchStart={(e) => handelOnTouchStart(e)}
      onTouchMove={() => handelOnTouchMove()}
      onTouchEnd={(e) => handelOnTouchEnd(e, customClass)}
    >
      {isShowChildren ? children : renderContent(data)}
    </div>
  );
};

ModalTouchBottom.defaultProps = {
  isShowOverlay: false,
  setIsShowOverlay: () => {},
  data: [],
  handleSelect: () => {},
  dataActive: {},
  customClass: '',
  type: '',
  isShowChildren: false,
  children: '',
};

export default ModalTouchBottom;
