import React, { useEffect } from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import images from 'themes/images';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import ROUTERS from '../../../constants/router';
import TimeCounter from '../../../components/TimeCounter/TimeCounter';
import { EVENT_STATUS, ORDER_STATUS_HISTORY } from '../../../constants/common';
import { priceFormat } from '../../../utils/Helpers';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

type Props = {
  dataBox: Object,
  url: String,
  validSoldProduct: boolean,
  timeCount: boolean,
  statusBox: boolean,
  showPriceEventSoldAvailable: boolean,
  classCustom: String,
  showPrice: boolean,
  showTime: boolean,
  indexEvent: number,
  eventProductId: number,
  setIdEventProduct: any,
  showProgress: any,
  percentProcess: any,
};

const BoxEventCommonComponent = ({
  dataBox,
  url = '',
  validSoldProduct = false,
  timeCount = false,
  statusBox = false,
  classCustom = '',
  showPriceEventSoldAvailable = false,
  showPrice,
  showTime,
  indexEvent = 1,
  eventProductId = 0,
  setIdEventProduct,
  showProgress = false,
  percentProcess = 0
}: Props) => {
  const history = useHistory();
  const toDetail = (urlPage) => {
    if (urlPage) {
      if(eventProductId && eventProductId != 0){
        setIdEventProduct(eventProductId)
      }
      history.push(urlPage);
    }
  };
  const currentTime = moment(new Date().getTime()).format(
    'YYYY-MM-DD HH:mm:ss'
  );

  useEffect(() => {}, []);
  const RenderStatusBox = (order_status, event_status) => {
    let result = '';
    if (event_status === 'FINISHED') {
      switch (order_status) {
        case 'COMPLETED':
          result = '구매완료';
          break;
        case 'AVAILABLE':
        case 'WAITING':
          result = '미수령';
          break;
        default:
          result = '';
          break;
      }
    } else if (event_status === 'IN_PROGRESS') {
      switch (order_status) {
        case 'COMPLETED':
          result = '구매완료';
          break;
        case 'AVAILABLE':
          result = '수령 가능';
          break;
        case 'WAITING':
          result = '예약 완료';
          break;
        default:
          result = '';
          break;
      }
    }
    return result;
  };
  const RenderBoxEnd = (data) => {
    return (
      <div
        className={`${classCustom} box-event-order`}
        onClick={() => toDetail(url)}
      >
        <div className="image-box ">
          {timeCount && data.e_status !== "FINISHED" && currentTime > dataBox.period_start && currentTime < dataBox.period_end ? (
            <div className="time-style">
              <span className="time-style-number">
                {data.e_status !== "FINISHED" && +data.total_available === +data?.orders_count ? (
                        <TimeCounter endTime={dataBox?.period_end} />
                    ) : ('00:00:00')}
              </span>
              <span className="time-style-letter">남음</span>
            </div>
          ) : (
            <div className="time-style">
              <span className="time-style-number">00:00:00</span>
              <span className="time-style-letter">남음</span>
            </div>
          )}
          <div className="image-box-card">
            {data?.imagesPath && data?.imagesPath.length > 0 ? (
              <img src={data?.imagesPath[0]} className="img_card" alt="" />
            ) : (
              <img src="" className="img_card" alt="" />
            )}
            <div className="image-cover" />
            {
              data.e_status === "FINISHED" ?
                  (
                      <div className="image-noti">
                        <span>기한이 마감되었습니다.</span>
                        <span>다음 기회에 또 만나요!</span>
                      </div>
                  )
                  : +data.total_available === +data?.orders_count && (
                  <div className="image-noti">
                    <span>준비된 수량이 </span>
                    <span>모두 소진되었습니다.</span>
                  </div>
              )
            }
          </div>
        </div>
        <div className='info-event-box'>
          <div className="Random-card">
          <span className="title-card" onClick={() => toDetail()}>
            {data && data.e_no ? `문구야 갖다줘 ${data.e_no}탄: ` : ''}
            {data.card_name}
          </span>
          </div>
          <div className="text-box">
            <div className="text-price">
              <span className="text-Pick-up-date">구매가</span>
              <span className="text-money">{priceFormat(data.price)}원</span>
            </div>
          </div>
          {showProgress ? (<div className='box-progress'>
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: (percentProcess +'%') }}
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <img src={images.icon_percen} className="icon-progress" style={{right:(percentProcess == 100 ? '-3px': '-18px')}} alt="" />
                <label className="d-none">progress</label>
              </div>

            </div>
            <div className='progress-text'>
              <label className='progress-text__number'>{percentProcess.toFixed(0)}%</label> <label className='progress-text__label'>판매되었어요!</label>
            </div>
          </div>) : ''}
        </div>
      </div>
    );
  };

  const RenderBoxInProgress = (data) => {
    if (validSoldProduct) {
      if (
        data.e_status === 'FINISHED' ||
        data.period_end <= currentTime ||
        data.total_available <= (data?.orders_count || 0)
      ) {
        return RenderBoxEnd(data);
      }
    }
    const checkWithdDiv = (data) => {
      let widthDiv = '59vw';
      if(data.total_available.length >= 3){
        widthDiv = '64'
        if(data?.orders_count.length > 3){
          widthDiv = '69vw'
        }
      }
      return widthDiv;
    }

    return (
      // history box
      <div
        className={`${classCustom} box-event-order`}
        onClick={() => toDetail(url)}
      >
        <div className="image-box">
          {timeCount ? (
            <div className="time-style">
              <span className="time-style-number">
                {timeCount && dataBox && dataBox.period_end && moment(dataBox.period_end)  > moment(currentTime) ? (
                  <TimeCounter endTime={dataBox?.period_end || currentTime} />
                ) : (
                  '00:00:00'
                )}
              </span>
              <span className="time-style-letter">남음</span>
            </div>
          ) : (
            ''
          )}
          {statusBox ? (
            <div className="reservation-complete">
              <span className="text-reservation-complete">
                {RenderStatusBox(dataBox.o_status, dataBox.e_status)}
              </span>
            </div>
          ) : (
            ''
          )}

          <div className="image-box-card">
            {/* <img src={images.img_card} alt="" className="img_card"/> */}
            {data?.imagesPath && data?.imagesPath.length > 0 ? (
              <img src={data?.imagesPath[0]} className="img_card" alt="" />
            ) : (
              <img src="" className="img_card" alt="" />
            )}
            {data.e_status == 'FINISHED' && <div className="image-cover" />}
          </div>
        </div>
        <div className='info-event-box'>
          <div className="Random-card">
          <span className="title-card">
            {data.e_no ? `문구야 갖다줘 ${data.e_no}탄: ` : ''}
            {data.card_name}
          </span>
          </div>
          <div className="text-box">
            {showPrice ? (
              <div className="text-price">
                <span className="text-Pick-up-date">구매가</span>
                <span className="text-money">{priceFormat(data.price)}원</span>
              </div>
            ) : (
              ''
            )}
            {/* {showTime ? (<div className="text-price"> */}
            {/*    <span className="text-Pick-up-date">수령가능일</span> */}
            {/*    <span className="text-money">{moment(data.delivery_from_at).format('Y-MM-DD')}~</span> */}
            {/* </div>) : ''} */}
            {showTime ? (
              <div className="store-time-history">
                <div className="store-time-history__item location">
                  <img
                    src={images.icon_location_history}
                    alt=""
                    className="icon_location_history icon-img"
                  />
                  <label className="store-name">{data.store_name}</label>
                </div>
                <div className="store-time-history__item">
                  <img
                    src={images.icon_date_history}
                    alt=""
                    className="icon_date_history icon-img"
                  />
                  <label className="label-time">수령가능일</label>
                  {data.available_at ? (
                    <label className="value-time">
                      {moment(data.available_at).format('Y-MM-DD')}~
                    </label>
                  ) : (
                    '수령 일자 정보 없음'
                  )}
                </div>
              </div>
            ) : (
              ''
            )}

          </div>
          {showProgress ? (<div className='box-progress'>
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: (percentProcess +'%') }}
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <img src={images.icon_percen} style={{right:(percentProcess == 100 ? '-3px': '-18px')}} className="icon-progress" alt="" />
                <label className="d-none">progress</label>
              </div>

            </div>
            <div className='progress-text'>
              <label className='progress-text__number'>{percentProcess.toFixed(0)}%</label> <label className='progress-text__label'>판매되었어요!</label>
            </div>
          </div>) : ''}

          {showPriceEventSoldAvailable && data.e_status != "COMMING_SOON" ? (
            <div className="text-third">
              <div className="position-relative">
                <div className="text-notification">
                  <img src={images.icon_bboki} alt="" className="icon-bboki" />
                  <span className="text-total">{data.total_available}개</span>중
                  <span className="text-multi-sale">{data?.orders_count || 0}개</span>가
                  판매됐어요. 서두르세요!
                </div>
                <div className="icon-image">
                  <img
                    style={{width:checkWithdDiv(data)}}
                    src={images.yellowbubble}
                    alt=""
                    className="yellowbubble"
                  />
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

        </div>
      </div>
    );
  };

  return <>{RenderBoxInProgress(dataBox)}</>;
};

export default BoxEventCommonComponent;
