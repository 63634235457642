
import React, { memo }  from 'react';

function  EmaiSMSRegisterComponent() {
  return (
    <>
      <div>
        <h2 className='title'>제3자 정보제공 및 위탁에 관한 안내	</h2>
        <div className='content'>
          문구야놀자는 정보주체의 동의, 법률의 특별한 규정 등 법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.					
        </div>
      </div>
      <div>
        <h2 className='title'>1. 개인정보의 제3자 제공	</h2>
        <div className='content'>
          문구야놀자는 다음과 같이 개인정보를 제3자에게 제공하고 있으며, 개인정보 제공에 대한 동의를 거부할 권리가 있으며, 동의를 거부할 경우 아래 서비스를 이용할 수 없습니다.							
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th>제공자</th>
              <th>제공<br />받는자</th>
              <th>이용 목적</th>
              <th>제공항목</th>
              <th>이용기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>문구야놀자</th>
              <td>아이오로라<br />KSPAY</td>
              <td>* 사용자간<br />이용내역<br />조회 서비스 </td>
              <td>성명,<br />생년월일,<br />성별,<br />이메일,<br />휴대전화번호,<br />카드 이용<br />내역</td>
              <td>문구야<br />놀자 앱<br />회원 탈퇴<br />시 까지</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  ) 
}

export default memo(EmaiSMSRegisterComponent)