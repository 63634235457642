import images from 'themes/images';

const listProduct = [
  {
    id: 1,
    contents: '눈오리집게',
    store_name: '중곡점',
    status: '재입고',
    image_path: images.product1,
  },
  {
    id: 2,
    contents: '워터슬라임',
    store_name: '중곡점',
    status: '재입고',
    image_path: images.product2,
  },
  {
    id: 3,
    contents: '눈오리집게',
    store_name: '중곡점',
    status: '재입고',
    image_path: images.product3,
  },
  {
    id: 4,
    contents: '눈오리집게',
    store_name: '중곡점',
    status: '재입고',
    image_path: images.product4,
  },
  {
    id: 5,
    contents: '눈오리집게',
    store_name: '중곡점',
    status: '재입고',
    image_path: images.product5,
  },
  {
    id: 6,
    contents: '눈오리집게',
    store_name: '중곡점',
    status: '재입고',
    image_path: images.product4,
  },
];
const listHistoryBuy = [
  {
    id: 1,
    title: '중곡시장점',
    status: '카드결제',
    type: 1,
    time: '2021-02-05 19:40:30',
    count: 10000,
    countP: 36,
  },
  {
    id: 2,
    title: '중곡시장점',
    status: '현금결제',
    type: 2,
    time: '2021-02-05 19:40:30',
    count: 10000,
    countP: 10000,
  },
  {
    id: 3,
    title: '중곡시장점',
    status: '포인트결제',
    type: 1,
    time: '2021-02-05 19:40:30',
    count: 10000,
    countP: -15000,
  },
  {
    id: 4,
    title: '중곡시장점',
    status: '포인트충전',
    type: 3,
    time: '2021-02-05 19:40:30',
    count: 10000,
    countP: 36,
  },
  {
    id: 5,
    title: '중곡시장점',
    status: '카드결제',
    type: 4,
    time: '2021-02-05 19:40:30',
    count: 10000,
    countP: -12000,
  },
];

const dataHistoryBuy = {
  pointNumber: '15,000',
  currentPoint: '15,000',
};

const listNotice = [
  {
    id: 1,
    time: '오후 3:30',
    point: '+32P',
    history: '문구야놀자',
    use: '중곡점에서',
    accumulated: '적립했어요',
    color: 'blue',
  },
  {
    id: 2,
    time: '어제',
    point: '-1500P',
    history: '문구야놀자',
    use: '[중곡점]에서',
    accumulated: '적립했어요',
    color: 'red',
  },
  {
    id: 3,
    time: '2021-02-05',
    point: '-3200P',
    history: '문구야놀자',
    use: '중곡점에서',
    accumulated: '적립했어요',
    color: 'red',
  },
  {
    id: 4,
    time: '2021-02-05',
    point: '오리집게가',
    history: '문구야놀자',
    use: '중곡점에서',
    accumulated: '적립했어요',
    color: 'other',
  },
  {
    id: 5,
    time: '2021-02-05',
    point: '오리집게가',
    history: '문구야놀자',
    use: '중곡점에서',
    accumulated: '적립했어요',
    color: 'yellow',
  },
];

const cartInfo = {
  totalPoint: 15000,
  cartNumber: '1203-21123-2152',
};

const levelInfo = {
  level: 1,
  pricePre: 3500,
  priceNext: 10000,
  total: '15,000',
  width: '45',
};

const listInquiryProduct = [
  {
    id: 1,
    ulrAvatar: images.product3,
    userName: '방탄소년단팬이야',
    productImg: 'https://i.ibb.co/s3d88zD/product4.png',
    content:
      '제가좋아하는 방탄 오빠들! \n오빠들 캐릭터로 된 지우개가 가지고... 싶어요! \n 저 그러면 여기서 마구마구 사가지구 완전 플렉스~!! \n 할수있어요!',
    date: '2021-02-05 19:40:30',
    commentNumber: '100',
    likeNumber: '2',
    userId: 1,
    likeNumberOfUser: 1,
    commentNumberOfUser: 1,
    comments: [
      {
        id: 1,
        content: '저도 진짜 가지고싶었는데!!',
        date: '2021-02-05 19:40:30',
        userId: 1,
        userName: '그린',
        ulrAvatar: images.product3,
      },
      {
        id: 2,
        content:
          '저도 진짜 가지고싶었는데!! \n 현기증 나니까 얼른 입고부탁 ㅜㅜ',
        date: '2021-02-05 19:40:30',
        userId: 1,
        userName: '그린',
        ulrAvatar: images.product3,
      },
    ],
  },
  {
    id: 2,
    ulrAvatar: images.product3,
    userName: '그린',
    productImg: '',
    content: '세일러문 요술봉 장난감이랑 스티커요!',
    date: '2021-02-05 19:40:30',
    commentNumber: '100',
    likeNumber: '2',
    userId: 1,
    likeNumberOfUser: 0,
    commentNumberOfUser: 0,
    comments: [
      {
        id: 1,
        content: '저도 진짜 가지고싶었는데!!',
        date: '2021-02-05 19:40:30',
        userId: 2,
        userName: '그린',
        ulrAvatar: images.product3,
      },
    ],
  },
  {
    id: 3,
    ulrAvatar: images.product3,
    userName: '어몽어스',
    productImg: 'https://i.ibb.co/gdZyR0q/product5.png',
    content:
      '어몽어스 캐릭터 피규어요! \n 그거 요즘 다들 가방에 달고다닌단 말이에요!',
    date: '2021-02-05 19:40:30',
    commentNumber: '100',
    likeNumber: '2',
    userId: 2,
    likeNumberOfUser: 1,
    commentNumberOfUser: 1,
    comments: [
      {
        ulrAvatar: images.product3,
        id: 1,
        content: '저도 진짜 가지고싶었는데!!',
        date: '2021-02-05 19:40:30',
        userId: 1,
        userName: '그린',
      },
    ],
  },
];

const listSelect = [
  {
    id: 0,
    title: '전체',
    type: '',
  },
  {
    id: 1,
    title: '카드결제',
    type: 'card',
  },
  {
    id: 2,
    title: '현금결제',
    type: 'money',
  },
  {
    id: 3,
    title: '포인트결제',
    type: 'point',
  },
  {
    id: 4,
    title: '포인트충전',
    type: 'point_charge',
  },
  { id: 5, title: '카카오페이 결제', type: 'kakao' },
  { id: 6, title: '페이코 결제', type: 'payco' },

];
const listStoreLike = [
  {
    id: 0,
    name: '지점 선택',
  },
  {
    id: 1,
    name: '염창점',
  },
  {
    id: 2,
    name: '중곡점',
  },
  {
    id: 3,
    name: '중곡점 A',
  },
  {
    id: 4,
    name: '중곡점 B',
  },
  {
    id: 5,
    name: '중곡점 C',
  },
];
const listNOticePage = [
  {
    id: 1,
    title: '상품을 구매하면 천 원 단위로 포인트를 적립해 드려요!',
    date: '2021-04-14 12:40:30',
  },
];
const listStoreLikeSelect = [
  {
    id: 1,
    value: 1,
    label: '염창점',
  },
  {
    id: 2,
    value: 2,
    label: '중곡점',
  },
  {
    id: 3,
    value: 3,
    label: '중곡점 A',
  },
  {
    id: 4,
    value: 4,
    label: '중곡점 B',
  },
  {
    id: 5,
    value: 5,
    label: '중곡점 C',
  },
];

const dataMyPage = {
  avatar: images.itemLevel4On,
  userName: '문구야죠아님',
  phone: '010-1234-1234',
  stores: [
    {
      id: 1,
      value: 1,
      label: '염창점',
    },
    {
      id: 2,
      value: 2,
      label: '중곡시장시내점',
    },
    {
      id: 3,
      value: 3,
      label: '중곡시장시내점',
    },
  ],
};

const listHistoryPushs = [
  { id: 1, title: '상품 구매 시 천 원 단위로 포인트 적립!' },
  // { id: 2, title: '포인트 충전하면 5% 더 추가 지급!' },
  // { id: 3, title: 'abc xzy' },
];

export {
  listProduct,
  listNotice,
  cartInfo,
  levelInfo,
  listSelect,
  listInquiryProduct,
  listHistoryBuy,
  listStoreLike,
  listStoreLikeSelect,
  listNOticePage,
  dataMyPage,
  dataHistoryBuy,
  listHistoryPushs,
};
