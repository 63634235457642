import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type props = {
  src: String,
  width: String,
  className: String,
  height: String,
  handleAfterLoad: Function
};

const LazyLoadImg = ({ src, className, height, width, handleAfterLoad }:props) => (
  <LazyLoadImage
    alt={''}
    height={height}
    src={src} // use normal <img> attributes as props
    width={width} 
    effect="blur"
    className={className}
    afterLoad={handleAfterLoad}
    beforeLoad={() => {
      console.log('beforeLoad');
    }}
    // placeholderSrc="loading"
    // visibleByDefault={true}
  />
);

LazyLoadImg.defaultProps = {
  src: '',
  className: '',
  height: '',
  width: '100%',
  handleAfterLoad: () => {}
};

export default LazyLoadImg;