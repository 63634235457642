import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from '../../../utils/Apis';

import { Types } from '../redux';

// worker Saga: will be fired on GET_DATA_PUSH actions
function* getDataPush() {
  try {
    /**
     * Example data
     * url: enpoint/login
     
     *
     */
    const response = yield call(() => API.get(ROUTES.API_GET_DATA_PUSH));

    if (response.ok) {
      const { data } = response;
      // In case: signup request success
      yield put({ type: Types.GET_DATA_PUSH_SUCCESS, data });
    } else {
      // In case: signup request failed
      yield put({
        type: Types.GET_DATA_PUSH_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.GET_DATA_PUSH_FAILED });
  }
}

/*
  Starts signupAccount on each dispatched `GET_DATA_PUSH` action.
*/
function* getDataPushSaga() {
  yield takeLatest(Types.GET_DATA_PUSH, getDataPush);
}

export default getDataPushSaga;
