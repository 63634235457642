import React from 'react';
import { Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';

type props = {
  type: String,
  width: Number,
  cssClass: String,
  count: Number,
  height: Number,
  circle: Boolean
};

const SkeletonLoadingComponent = ({type,width,cssClass,count,height,circle}: props) => {
  return <Skeleton
            containerClassName={cssClass}
            width={width}
            count={count}
            height={height}
            circle={circle}
          />
};

SkeletonLoadingComponent.defaultProps = {
  type: null,
  cssClass: '',
  count: 1,
  height: 20,
  width: '100%'
};

export default SkeletonLoadingComponent;
