/* eslint-disable camelcase */
import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from 'utils/Apis';
import { checkStatusCodeApi } from 'utils/Helpers';

import { Types } from '../redux';


function* getListAllStore(action) {
  try {
    const response = yield call(() =>
      API.get(ROUTES.API_FIND_STORE, action.storeName)
    );
    if (response.ok) {
      const { data } = response;
      yield put({ type: Types.GET_LIST_ALL_STORE_SUCCESS, data });
    } else {
      const { data } = response;
      console.log(data, 'ddddddđ');
      checkStatusCodeApi(data?.code || null)
      yield put({
        type: Types.GET_LIST_FIND_STORE_FAILED,
        data
      });
    }
  } catch (error) {
    // in case: server error
    // yield put({ type: Types.GET_LIST_FIND_STORE_FAILED, error });
  }
}

/*
  Starts getListAllStore on each dispatched `GET_LIST_FIND_STORE` action.
*/
function* getListAllStoreSaga() {
  yield takeLatest(Types.GET_LIST_ALL_STORE, getListAllStore);
}

export default getListAllStoreSaga;
