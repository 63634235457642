// import libs
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

// Define action creators
export const { Types, Creators } = createActions({
  getDataMyPage: null,
  getDataMyPageSuccess: null,
  getDataMyPageFailed: null,

  updateUserInfo: ['userInfo'],
  updateUserInfoSuccess: null,
  updateUserInfoFailed: null,

  getListStore: null,
  getListStoreSuccess: null,
  getListStoreFailed: null,

  getDataPush: null,
  getDataPushSuccess: null,
  getDataPushFailed: null,

  getMyInfo: null,
  getMyInfoSuccess: null,
  getMyInfoFailed: null,

  settingPush: ['data'],
  settingPushSuccess: null,
  settingPushFailed: null,

  resetType: null,
});

// Initial state
export const INITIAL_STATE = Immutable({
  isProcessing: false,
  errors: '',
  listStore: [],
  listStoreLike: [],
  statusCode: null,
  dataMyInfo: {},
});

const resetType = (state) => {
  return state.merge({
    type: '',
    statusCode: null,
  });
};

const getDataMyPage = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

const getDataMyPageSuccess = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

const getDataMyPageFailed = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

const updateUserInfo = (state, action) => {
  return state.merge({
    isProcessing: true,
    type: action.type,
  });
};

const updateUserInfoSuccess = (state, action) => {
  const { data } = action;
  return state.merge({
    isProcessing: false,
    type: action.type,
    statusCode: data?.code,
  });
};

const updateUserInfoFailed = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
    statusCode: '',
  });
};

const getListStore = (state, action) => {
  return state.merge({
    isProcessing: true,
    type: action.type,
  });
};

const getListStoreSuccess = (state, action) => {
  const { data } = action;
  console.log(data, 'datadatadata');
  const listStore =
    data &&
    (data || []).map((item, index) => ({
      id: item.id,
      value: index + 1,
      label: item.name,
    }));

  return state.merge({
    isProcessing: false,
    type: action.type,
    listStore: listStore || [],
  });
};

const getListStoreFailed = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

const getMyInfo = (state, action) => {
  return state.merge({
    isProcessing: true,
    type: action.type,
  });
};

const getMyInfoSuccess = (state, action) => {
  const { data } = action;
  const storeListLikeOfUser = ((data && data.storeList) || []).map((item) => ({
    id: item.id,
    value: item.id,
    label: item.name,
  }));
  return state.merge({
    isProcessing: false,
    type: action.type,
    listStoreLike: data && data.storeList || [],
    dataMyInfo: {
      name: data && data.name,
      phone_number: data && data.phone_number,
      profile: data && data.profile,
      push: data && data?.push,
      level: (data && parseInt(data.level, 10)) || 1,
    },
  });
};

const getMyInfoFailed = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

const settingPush = (state, action) => {
  return state.merge({
    type: action.type,
  });
};

const settingPushSuccess = (state, action) => {
  const { data } = action;
  return state.merge({
    type: action.type,
    statusCode: data?.code,
  });
};

const settingPushFailed = (state, action) => {
  return state.merge({
    type: action.type,
    statusCode: '',
  });
};

// Assign handler to types.
const HANDLERS = {
  [Types.GET_DATA_MY_PAGE]: getDataMyPage,
  [Types.GET_DATA_MY_PAGE_SUCCESS]: getDataMyPageSuccess,
  [Types.GET_DATA_MY_PAGE_FAILED]: getDataMyPageFailed,

  [Types.UPDATE_USER_INFO]: updateUserInfo,
  [Types.UPDATE_USER_INFO_SUCCESS]: updateUserInfoSuccess,
  [Types.UPDATE_USER_INFO_FAILED]: updateUserInfoFailed,

  [Types.GET_MY_INFO]: getMyInfo,
  [Types.GET_MY_INFO_SUCCESS]: getMyInfoSuccess,
  [Types.GET_MY_INFO_FAILED]: getMyInfoFailed,

  [Types.GET_LIST_STORE]: getListStore,
  [Types.GET_LIST_STORE_SUCCESS]: getListStoreSuccess,
  [Types.GET_LIST_STORE_FAILED]: getListStoreFailed,

  [Types.SETTING_PUSH]: settingPush,
  [Types.SETTING_PUSH_SUCCESS]: settingPushSuccess,
  [Types.SETTING_PUSH_FAILED]: settingPushFailed,

  [Types.RESET_TYPE]: resetType,
};

// Create reducers by pass state and handlers
export const myPageReducer = createReducer(INITIAL_STATE, HANDLERS);
