const ROUTERS = {
  MAIN: `/`,
  LOGIN: `/login`,
  WELCOME: `/welcome`,
  SETTING_PAGE: `/setting`,
  SIGN_UP: `/signup`,
  FIND_PASSWORD: `/find-password`,
  SEARCH: `/search`,
  PRODUCT: `/product`,
  HISTORY: `/history`,
  MY_PAGE: `/my-page`,
  FIND_STORE: `/find-store`,
  HISTORY_BUY: `/history-buy`,
  PUSH_NOTICE: `/push`,
  ADD_POINT: `/addPoint/step1`,
  ADD_POINT_STEP2: `/addPoint/step2`,
  LEVEL_PAGE: `/level-page`,
  INQUIRY_PRODUCT: `/product/inquiry`,
  INQUIRY_PRODUCT_DETAIL: `/product/inquiry/:id`,
  INQUIRY_PRODUCT_REGISTER: `/product/inquiry/register`,
  PRODUCT_ADD: `/product/register`,
  MY_PAGE_EDIT: `/my-page/edit`,
  NOTICE_PAGE: `/notice`,
  TERM_AND_POLICY: `/terms-and-policies`,
  POLICY_PAGE: `/policy`,
  TERM_OFF_SERVER: `/term-off-server`,
  NOTICE_DETAIL: '/notice/:id',
  SCAN_QR_CODE: `/scan-qr`,
  ORDER: `/order`,
  PUSCHASE_HISTORY:`/purchase-history`,
  ORDER_BOOK:`/order/book/:id`,
  STORE_SELECTION:`/store-selection`,
  AWS_PRODUCT_RECENT_PATH: process.env.REACT_APP_AWS_PRODUCT_RECENT_PATH,
  AWS_PRODUCT_INQUIRY_PATH: process.env.REACT_APP_AWS_PRODUCT_INQUIRY_PATH,
  ORDER_DETAIL: `/order/:id`,
  ORDER_COMPLETE: `/order-complete`,
  PUSCHASE_HISTORY_DETAIL: `/purchase-history/:id`,
  QA_CREATE: `/qa/create`,
  QA_DETAIL: `/qa/detail/:id`,
  QA_MANAGEMENT: `/qa/management`,
  QA_HISTORY: `/qa/history`,
  PRODUCT_DETAIL: `/product/:id`,
  FIND_STORE_FAVORITE: `/find-store-favorite`,
  FAQ_DETAIL: `/faq/:id`,
  ANNOUCEMENT_DETAIL: `/notification/:id`,
  EVENT: `/event`,
  EVENT_DETAIL: `/event/:id`,
  CONSULTION: `/consultion`,
  CONSULTION_DETAIL: `/consultion/detail/:id`,
  CONSULTION_CREATE: `/consultion/create`,
  PRIVACY_POLICY: `/privacy-policy`,
  POLICY_WEB: `/remove-user-data-policy`,
  POLICY_HANDLE_PERSONAL_INFORMATION: `/policy-handle-personal-infomation`
};
export default ROUTERS;
