const REGEX = {
  // PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)(?!\s)[A-Za-z\d]{8,18}[ $&+,:;=\\\\?@#|/'<>.^*()%!-]$/,
  PASSWORD: /^(?=.*[a-zA-Z])([A-Z]?)((?=.*\d)([!@#$%^&*()'"<>:;.,=-]?))(?!\s).{8,15}$/,
  USER_ID: /^(?=.*[a-zA-Z])([A-Z]?)(?!\s).{6,18}$/,
  EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  USERNAME: /[ $&+,:;=\\\\?@#|/'<>.^*()%!-]/,
  PHONE: /^([0,1]{2})(\d{1})\)?[-]?(\d{3,4})[-]?(\d{3,4})$/,
  LAST_CHARACTER_POLICY: /((?: ㅏ|ㅓ|ㅗ|ㅜ|ㅡ|ㅣ|ㅐ|ㅔ|ㅑ|ㅕ|ㅛ|ㅠ|ㅖ|ㅒ|ㅘ|ㅙ|ㅝ|ㅞ|ㅚ|ㅟ|ㅢ)+|((?:ㅏ|ㅓ|ㅗ|ㅜ|ㅡ|ㅣ|ㅐ|ㅔ|ㅑ|ㅕ|ㅛ|ㅠ|ㅖ|ㅒ|ㅘ|ㅙ|ㅝ|ㅞ|ㅚ|ㅟ|ㅢ)+(?:[\$\&\+\,\:\;\=\?\@\#\|\'\<\>\.\^\*\(\)\%\!\-\ ]+))+|((?:ㅏ|ㅓ|ㅗ|ㅜ|ㅡ|ㅣ|ㅐ|ㅔ|ㅑ|ㅕ|ㅛ|ㅠ|ㅖ|ㅒ|ㅘ|ㅙ|ㅝ|ㅞ|ㅚ|ㅟ|ㅢ)+(?:[\$\&\+\,\:\;\=\?\@\#\|\'\<\>\.\^\*\(\)\%\!\-\ ]+)(?:ㅏ|ㅓ|ㅗ|ㅜ|ㅡ|ㅣ|ㅐ|ㅔ|ㅑ|ㅕ|ㅛ|ㅠ|ㅖ|ㅒ|ㅘ|ㅙ|ㅝ|ㅞ|ㅚ|ㅟ|ㅢ)+)+|(?:ㅏ|ㅓ|ㅗ|ㅜ|ㅡ|ㅣ|ㅐ|ㅔ|ㅑ|ㅕ|ㅛ|ㅠ|ㅖ|ㅒ|ㅘ|ㅙ|ㅝ|ㅞ|ㅚ|ㅟ|ㅢ)+((?:[\$\&\+\,\:\;\=\?\@\#\|\'\<\>\.\^\*\(\)\%\!\-\ ]+)(?:ㅏ|ㅓ|ㅗ|ㅜ|ㅡ|ㅣ|ㅐ|ㅔ|ㅑ|ㅕ|ㅛ|ㅠ|ㅖ|ㅒ|ㅘ|ㅙ|ㅝ|ㅞ|ㅚ|ㅟ|ㅢ)+(?:[\$\&\+\,\:\;\=\?\@\#\|\'\<\>\.\^\*\(\)\%\!\-\ ]+)(?:ㅏ|ㅓ|ㅗ|ㅜ|ㅡ|ㅣ|ㅐ|ㅔ|ㅑ|ㅕ|ㅛ|ㅠ|ㅖ|ㅒ|ㅘ|ㅙ|ㅝ|ㅞ|ㅚ|ㅟ|ㅢ)+)+)$/gm
};

export default REGEX;
