// @flow
// libs
import React from 'react';
import { Spinner } from 'react-bootstrap';
import Lottie from 'react-lottie';
import { defaultProps } from 'react-mobile-datepicker';
// import images from 'themes/images';
// import iconLoading from '../../assets/json/MOMO_loading.json';
import iconLoading from '../../assets/images/loading1.gif'

const iconLogo = {
  loop: true,
  autoplay: true,
  animationData: iconLoading,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

type Props = {
  customClass: String,
}

const Loading = ({customClass}:Props) => (
  <div className={`loading-page ${customClass}`}>
    <div className="img-loading">
      <img src={iconLoading} alt="" />
    </div>
  </div>
  
);

Loading.defaultProps = {
  customClass: ''
}

export default Loading;
