import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { API } from 'utils/Apis';

// window.setUserInfo = function(dataInfo){
//   const dataParse = JSON.parse(dataInfo); 
//   console.log(dataParse, 'dataParse');
//   API.setHeader('Authorization', `Bearer ${dataParse.token}`);
//   localStorage.setItem('DATA_INFO', dataInfo);
//   document.getElementById('token').innerHTML = JSON.stringify(dataParse)
//   document.getElementById('time-set-header').innerHTML = (new Date()).getTime();
  
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
