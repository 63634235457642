import { useEffect, useState, useReducer } from 'react';
import useCountDownTime from "./useCountDownTime";

const getReturnValues = (countDown) => {
  // calculate time left
  let days = countDown > 0 ? Math.floor(countDown / (1000 * 60 * 60 * 24)) : 0;
  let hours = countDown > 0 ? Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  ) : 0;
  let minutes = countDown > 0  ? Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)) : 0;
  if (minutes <= 9) {
    minutes = '0' + minutes
  }
  let seconds = countDown > 0  ? Math.floor((countDown % (1000 * 60)) / 1000) : 0 ;
  if (seconds <= 9) {
    seconds = '0' + seconds
  }

  return {days, hours, minutes, seconds};
};

const useCountdown = (target, start = false) => {
  const [countDown, setCountDown] = useState(target);
  const [countDownEnd, setCountDownEnd] = useState(false);

  const [timerReset, resetTimer] = useReducer(p => !p, false);
  const [startTimer, setStartTimer] = useReducer(p => !p, start);

  useEffect(() => {
    if (startTimer) {
      setCountDownEnd(false)
      const timeout = setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);

      if (countDown ===  0) {
        clearTimeout(timeout)
        setCountDownEnd(true)
      }

      return () => {
        clearTimeout(timeout);
        setCountDownEnd(true)
      }
    }
  }, [countDown, startTimer]);

  useEffect(() => {
    setCountDown(target);
    setCountDownEnd(false);
  }, [timerReset]);

  const time = getReturnValues(countDown * 1000)
  return [time, countDownEnd, resetTimer, startTimer, setStartTimer] ;
};
export default useCountdown;
