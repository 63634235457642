// @flow

import React, { useState, useEffect } from 'react';
import MainLayout from 'layout/MainLayout';
import Loading from 'components/Loading';
import { useHistory } from 'react-router-dom';
import InputAutoCompleteSearch from 'components/AutoComplete';
import NoData from 'components/NoData';
import { ModalPopup } from 'components/Modal';
import Button from 'components/Button';
import KakaoMap from './KakaoMap';
import { GPS_DEFAULT } from 'constants/gpsDefault';
import { GPS_DEFAULT_STORE } from 'constants/gpsDefault';

type Props = {
  listStoreLike: any,
  isProcessingAllStore: Boolean,
  isProcessingFindStore: Boolean,
  getListFindStore: Function,
  listLocationStore: any,
  getListAllStore: Function,
  listAllStore: Array,
};
const myLocation = JSON.parse(localStorage.getItem('myLocation'));
console.log(myLocation, 'myLocation');
const FindStoreSettingComponent = (props: Props) => {
  // const history = useHistory();
  const {
    listStoreLike,
    isProcessingAllStore,
    isProcessingFindStore,
    getListFindStore,
    getListAllStore,
    listLocationStore,
    listAllStore,
  } = props;
  const [keySearch, setKeySearch] = useState('');
  const [storeSelected, setStoreSelected] = useState(null);
  const [isClickSearch, ] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);
  const [isShowData, setIsShowData] = useState(true);
  const [isDisable, setIsDisable] = useState(false);
  const [dataStore, setDataStore] = useState({
    storeName: '',
    address: '',
  });
  const [paramCoordinates, ] = useState({
    coordinates : [myLocation?.lat || '', myLocation?.lng || ''],
    radius: 1000
  })
  const [location, setLocation] = useState(myLocation || null);
  const [isLoadingNative, setIsLoadingNative] = useState(true);
  const [storeDefault, setStoreDefault] = useState({
    lat: 37.5571975318507,
    lng: 127.0825275965320
  })

  useEffect(() => {
    getListAllStore();
    window.getGPSLocationNative()
    setIsDisable(true);
    setLocation(myLocation);
    getListFindStore(paramCoordinates);
  }, []);

  useEffect(() => {
    if(!isProcessingAllStore && !isProcessingFindStore){
      setIsLoadingNative(false);
    }
  }, [isProcessingAllStore, isProcessingFindStore])


  window.setGPSLocation = function(lat, lng){
    localStorage.setItem('myLocation', JSON.stringify({lat, lng}));
    setLocation({lat, lng});
  }

  const getGPSCurrentLocation = () => {
    window.getGPSLocationNative()
  }

  useEffect(() => {
    if (myLocation?.lat && myLocation?.lng) {
      setLocation(myLocation);
    }
  }, [myLocation])

  useEffect(() => {
    if (listLocationStore.length > 0) {
      console.log(listLocationStore, 'listLocationStore');
      const gpsStoreDefault = listLocationStore.filter((item, idx) =>  
      item.lat !== null && item.lng !== null && item.lat !== 0 && item.lng !== 0 && item.name === '군자역점');
      console.log(gpsStoreDefault, 'gpsStoreDefault');
      if (gpsStoreDefault.length > 0) {
        setStoreDefault({
          lat: gpsStoreDefault[0].lat,
          lng: gpsStoreDefault[0].lng,
        })
      }
    }
  }, [listLocationStore])

  useEffect(() => {
    if (location?.lat === '' && location?.lng === '') {
      setLocation(storeDefault)
      getListFindStore({
        coordinates : [storeDefault?.lat || '', storeDefault?.lng || ''],
        radius: 1000
      });
    }
  }, [location])

  // const handleCancel = () => {
  //   setIsShowNonGPS(false);
  //   setLocation(GPS_DEFAULT_STORE)
  //   getListFindStore({
  //     coordinates : [GPS_DEFAULT_STORE?.lat || '', GPS_DEFAULT_STORE?.lng || ''],
  //     radius: 1000
  //   });
  // }
  
  // const handleSubmit = () => {
  //   setIsShowNonGPS(false);
  //   // setLocation({lat: 36.56522203657303, lng: 126.08174391734524});
  //   // localStorage.setItem('myLocation', JSON.stringify({lat: 36.56522203657303, lng: 126.08174391734524}));
  //   window.openLocationSettingNative()
  // }

  const handleGetDataStore = (data) => {
    setDataStore(data);
    // window.scrollTo(0, document.body.scrollHeight);
  };
  const handleSearch = (data) => {
    setResetSearch(false);
    setStoreSelected(null);
    getListFindStore({
      searchText: data?.name || '',
    });
    setDataStore({
      storeName: data?.name || '',
      address: data?.address || '',
    });
    setKeySearch(data?.name || '');
  };

  const handleSearchText = (text) => {
    setResetSearch(false);
    setStoreSelected(null);
    setKeySearch(text);
    getListFindStore({
      searchText: text,
    });
  };

  const onChangeInputSearch = (text) => {
    setIsDisable(false);
  }

  const handleGotoStoreDetail = (params) => {
    // history.push(ROUTERS.ORDER);
  };

  const handleSelectStore = (value) => {
    setStoreSelected(value);
    setResetSearch(true);
    getListFindStore({
      searchText: value?.name,
    });
    setKeySearch(value?.name || '');
  };

  const renderListStore = (data) => {
    if (data && data.length > 0) {
      return data.map((item, idx) => (
        <li
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          className={`item ${
            storeSelected && storeSelected?.store_id === item.store_id ? 'selected' : ''
          }`}
          aria-hidden
          onClick={() => handleSelectStore(item)}
        >
          {item.name}
        </li>
      ));
    }
  };

  const showDataProduct = (isShow) => {
    setIsShowData(!isShow)
  }

  const renderListStoreSearch = (data) => {
    if (dataStore && dataStore?.storeName) {
      return <div className="info-map">
                <div className="title">{dataStore?.storeName}</div>
                <div className="sub-title">{dataStore?.address}</div>
              </div>
    }
    if (data && data.length > 0) {
      return data.map((item) => {
        return <div className="info-map">
        <div className="title">{item?.name}</div>
        <div className="sub-title">{item?.address}</div>
      </div>
      })
    }
  }

  return (
    <MainLayout
      customClass="MainPage"
      activePage={3}
      // isShowFooter
      isShowHeader
      titleHeader="매장 검색"
      icoBackWhite
      isShowIcon
      isLink
      isBackNative
      isLoading={isLoadingNative}
    >
      <div className="setting-page page-store find-store">
        <div className="page-store-content">
          {
            listStoreLike.length > 0 && 
              <div className="list-store-select">
                <ul className="list">
                  {renderListStore(listStoreLike)}
                </ul>
              </div>
          }
          
          <InputAutoCompleteSearch
            data={listAllStore || []}
            handleSearch={handleSearch}
            handleSearchText={handleSearchText}
            resetSearch={resetSearch}
            placeholder="가맹점명을 입력해 주세요"
            // classDiv="find-store-search"
            showIconSearch
            classDiv="flex-search"
            showDataProduct={showDataProduct}
            onChangeInputSearch={onChangeInputSearch}
            disabledSearch={isDisable}
          />
          
          {
            isShowData && 
            <>
              <div className="wrapper-map-content">
                <div className="map-content">
                  <KakaoMap
                    listLocationStore={listLocationStore}
                    handleGetDataStore={handleGetDataStore}
                    isClickSearch={isClickSearch}
                    keySearch={keySearch}
                    handleGotoStoreDetail={handleGotoStoreDetail}
                    myLocationApp={location ?? storeDefault}
                    getGPSCurrentLocation={getGPSCurrentLocation}
                  />
                </div>
                <div className='box-list-stores'>
                  {renderListStoreSearch(listLocationStore)}
                </div>
              </div>
            </>
          }

          
        </div>
      </div>
      {/* <ModalPopup
        isOpen={isShowNonGPS}
        // isShowHeader
        // handleCloseOvl={() => setIsShowModalDeleteComment(false)}
        // handleClose={() => setIsShowModalDeleteComment(false)}
        // handleSubmit={() => handleDeleteComment()}
        // textBtnLeft="아니요"
        // textBtnRight="네, 삭제할게요."
        customClass="gps-request-popup "
      >
        <div className="content">
          <h3 className=''> 
            위치 서비스를 사용하기 위해서는 <br />
            위치 설정을 허용해야 합니다</h3>
          <p className=''>
            어플리케이션에서 위치 접근을 허용으로 <br />
            변경해 주세요.
          </p>
        </div>
        <div className="group-button-popup">
          <Button
            type="button"
            customClass='button--half btn-left'
            onClick={() => handleCancel()}
          >
            취소
          </Button>
          <Button
            type="button"
            customClass='button--half btn-right'
            onClick={() => handleSubmit()}
          >
            설정
          </Button>
        </div>
      </ModalPopup> */}
    </MainLayout>
  );
};

export default FindStoreSettingComponent;
