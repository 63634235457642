// eslint-disable-next-line import/prefer-default-export

import moment from 'moment';

/* eslint-disable no-plusplus */
const isWebview = require('is-ua-webview');

const isMobile = isWebview(navigator.userAgent);

export function isNumberKey(e) {
  const charCode = e.which ? e.which : e.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    e.preventDefault();
    return false;
  }
  return true;
}

export function isOnPasteNumber(e) {
  const pastedData = e.clipboardData.getData('text/plain');
  const regex = /[0-9]/;
  if (!regex.test(pastedData)) {
    e.preventDefault();
    return false;
  }
  return true;
}

export const formatValue = (value: any) => {
  let mask = '';
  switch (value?.length) {
    case 10:
      mask = 'XXX-XXX-XXXX';
      break;
    case 11:
      mask = 'XXX-XXXX-XXXX';
      break;
    default:
      mask = 'XXX-XXXXXX-XXXXXX';
      break;
  }
  const s = `${value}`;
  let r = '';
  for (let im = 0, is = 0; im < mask.length && is < s.length; im++) {
    r += mask[im] === 'X' ? s.charAt(is++) : mask.charAt(im);
  }
  return r;
};

export const checkPlatform = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const checker = {
    iphone:
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document),
    android: userAgent.match(/Android/),
  };
  return checker;
};

export const toDoQRReading = (userId) => {
  const currentOS = checkPlatform();
  if (isMobile) {
    if (currentOS.android && window) {
      window.AndroidApp.toDoQRReading(userId);
    } else if (currentOS.iphone) {
      window.webkit.messageHandlers.toDoQRReading.postMessage(userId);
    }
  }
};

export const getUserInfoNative = () => {
  const currentOS = checkPlatform();
  // if (isMobile) {
  //   if (currentOS.android && window) {
  //     window.AndroidApp.getUserInfo();
  //   } else if (currentOS.iphone) {
  //     window.webkit.messageHandlers.getUserInfo.postMessage('');
  //   }
  // }
};

export const getLocationUserApp = () => {
  const currentOS = checkPlatform();
  // if (isMobile) {
  //   if (currentOS.android && window) {
  //     window.AndroidApp.getLocationUserApp();
  //   } else if (currentOS.iphone) {
  //     window.webkit.messageHandlers.getLocationUserApp.postMessage('');
  //   }
  // }
};

export const formatNumberBarcode = (number) => {
  let result = '';
  if (number) {
    const newArr1 = number.slice(0, 4);
    const newArr2 = number.slice(4, 8);
    const newArr3 = number.slice(8, 12);
    const newArr4 = number.slice(12, 16);

    result = `${newArr1} ${newArr2} ${newArr3} ${newArr4}`;
  }

  return result;
};

export const formatDateTime = (time) => {
  const a = [];
  if (time) {
    const arr = time.split('');
    for (let i = 0; i < arr.length; i += 1) {
      if (arr[i] === '.' || arr[i] === '-') {
        a.push('/');
      } else {
        a.push(arr[i]);
      }
    }
  }
  return a.join('');
};

export const formatTimeZone = (time) => {
  return new Date(`${time} GMT+09:00`).getTime();
};

export const priceFormat = (price, n = 0, x = 3, s = ',', c = '.') => {
  if (price) {
    price = +price
    let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')'
    let num = price.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
  }
  return 0
}

export function formatDate(dateTime, type, format='Y-MM-DD') {
  if (!dateTime) return null;
  if (type == 'time') {
    // return moment(dateTime).tz(TIME_ZONE).format(IMAGE_DETAIL_FORMAT);
  } else {
    return moment(dateTime).format(format);
  }
}

export function checkPermissionCamera(isCheck) {
  console.log(155, isCheck, 'checkPermissionCamera');
  if (!isCheck) {
    window.callRequestPermissionNative(isCheck);
  }
}

export function nl2br (str, replaceMode, isXhtml) {

  var breakTag = (isXhtml) ? '<br />' : '<br>';
  var replaceStr = (replaceMode) ? '$1'+ breakTag : '$1'+ breakTag +'$2';
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
}

export function checkStatusCodeApi(code) {
  if (code && code === 401) {
    window.callExpiredTokenNative();
  }
}

export function randomProbability() {
  const createDistribution = (weights, size) => {
    const distribution = [];
    const sum = weights.reduce((a, b) => a + b);
    // console.log(sum, 'sum');
    const quant = size / sum;
    // console.log(quant, 'quant');
  	for (let i = 0; i < weights.length; ++i) {
      	const limit = quant * weights[i];
        // console.log(limit, 'limit');
      	for (let j = 0; j < limit; ++j) {
            // console.log(i, 'i');
          	distribution.push(i);
        }
    }
  	return distribution;
  };

  const randomIndex = (distribution) => {
      const index = Math.floor(distribution.length * Math.random());  // random index
      return distribution[index];  
  };

  const randomItem = (array, distribution) => {
      const index = randomIndex(distribution);
      return array[index];
  };


  // Usage example:

  const array =   ['0', '1,000', '5,000', '10,000', '100,000']; 
  const weights = [ 0.999999999999999999999999, 0.000000000000000000000001, 0, 0, 0];
  const distribution = createDistribution(weights, 100);
  return randomItem(array, distribution);
}