// @flow

import useOnClickOutside from 'customHooks/useClickOutSide';
import useDebounce from 'customHooks/useDebounce';
import React, { memo, useEffect, useState, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import images from 'themes/images';
import { ReactComponent as IconSearch } from '../../assets/icons/icon_search.svg';
import { ReactComponent as IconSearchDisabled } from '../../assets/icons/icon_search_disabled.svg';

type Props = {
  data?: Array,
  placeholder?: string,
  classDiv?: string,
  classResult?: string,
  handleSearch?: Function,
  handleSearchText?: Function,
  resetSearch?: Boolean,
  hasSelect?: Boolean,
  showIconSearch?: Boolean,
  handleClickSelectDropdown?: Function,
  onChangeInputSearch?: Function,
  showBoxResult: Boolean,
  dataSelected: Object,
  hasFilter: Boolean,
  showDataProduct: Function,
  showDataFocus?: Boolean,
  handleForcusIpt?: Function,
  showIconPositionSearch?: Boolean,
  disabledSearch?: Boolean
};

const InputAutoCompleteSearch = ({
  data = [],
  placeholder = '',
  classDiv = '',
  classResult = '',
  handleSearch = () => {},
  handleSearchText = () => {},
  resetSearch = false,
  hasSelect = false,
  showIconSearch = false,
  showIconPositionSearch = false,
  handleClickSelectDropdown = () => {},
  onChangeInputSearch = () => {},
  showBoxResult = true,
  dataSelected,
  hasFilter = false,
  showDataProduct = () => {},
  showDataFocus = false,
  handleForcusIpt = () => {},
  disabledSearch = false
}: Props) => {

  const searchRef = useRef();
  const [ searchText, setSearchText ] = useState('')
  const [ showResult, setShowResult ] = useState(false)
  const [ isFocus, setIsFocus ] = useState(false)
  const [ isClickSearch, setIsClickSearch ] = useState(false)
  const [ dataSearch, setDataSearch ] = useState([])
  const [ itemSelect, setItemSelect ] = useState(null)
  // useOnClickOutside(searchRef, () => setShowResult(false))
  const debouncedValue = useDebounce(searchText, 500);

  const filterByValue = (value, dataArr) => {
    if (dataArr) {
      return dataArr.filter((item) => item.name.toLowerCase().includes(value) || item.name.toUpperCase().includes(value));
    }
  }

  const getNameById = (id, dataArr) => {
    if (dataArr) {
      return dataArr.filter((item) => item.id === id);
    }
  }

  const hightLightitem = (value, data) => {
    if (data.length > 0) {
      const arr = data.map(item => {
        const regex = new RegExp(`(${value})`, "gi");
        const nameHightlight = item.name.replace(regex, "<span class='color-autocompletes'>$1</span>")
        return {
          ...item,
          name: nameHightlight
        }
      });
      return arr;
    }
  }

  useEffect(() => {
    if (resetSearch) {
      setSearchText('');
    }
  }, [resetSearch])

  useEffect(() => {
    showDataProduct(showResult)
  }, [showResult])

  useEffect(() => {
    handleForcusIpt(isFocus);
  }, [isFocus])

  useEffect(() => {
    if (debouncedValue) {
      // onChangeInputSearch(debouncedValue)
    }
  }, [debouncedValue])

  const onChangeSearchText = (e) => {
    const str = e.target.value;
    setSearchText(str);
    onChangeInputSearch(str);
    console.log(data, 'data');
    if (str !== '') {
      setShowResult(true);
      const result = filterByValue(str, data);
      // const dataHightLight = hightLightitem(str, result)
      console.log(result, 'result');
      setDataSearch(result);
    }else{
      setShowResult(false);
    }
    setIsClickSearch(false);
  }



  const handleSelectItem = (value) => {
    const nameStore = getNameById(value.id, data);
    setItemSelect(value.id)
    // call function search
    onChangeInputSearch(nameStore[0].name)
    setSearchText(nameStore[0].name);
    setShowResult(false);
    handleSearch(value);
    setIsFocus(false);
    setIsClickSearch(false);
  }

  const handleSearchStore = () => {
    setShowResult(false);
    setIsClickSearch(true);
    handleSearchText(searchText);
    const result = filterByValue(searchText, data);
    console.log(result,searchText, 'result');
    setDataSearch(result);
    setIsFocus(true);
  }

  const clickInput = () => {
    if (searchText) {
      const result = filterByValue(searchText, data);
      // const dataHightLight = hightLightitem(searchText, result)
      setDataSearch(result);
      setShowResult(true);
      setIsClickSearch(false);
    }
  }

  const handleForcus = () => {
    setIsFocus(true)
    if (searchText) {
      setShowResult(true);
    }
  }

  const handleBlur = () => {
    // setShowResult(false);
   
  }

  const handleSelectStore = (item) => {
    setItemSelect(item.id)
    onChangeInputSearch(item.name)
    setSearchText(item.name);
    setShowResult(false);
    handleSearch(item);
    setIsFocus(false);
    setIsClickSearch(false);
  };

  const renderListStore = lists => {
    if (lists && lists.length > 0) {
      return lists.map((item) => (
        <li
          key={item.id}
          className='item'
          aria-hidden
          onClick={() => handleSelectStore(item)}
        >
          {item.name}
        </li>
      ));
    }
  };

  useEffect(() => {
    if (dataSelected?.id) {
      setItemSelect(dataSelected.id)
      setSearchText(dataSelected.name);
    }
  }, [dataSelected])


  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className='auto-complete'>
      <div className={`search-store ${classDiv}`}>
          <input
            className={`form-control input-search 
              ${hasSelect ? 'has-select' : ''} 
              ${hasFilter ? 'has-filter' : ''} 
              ${showIconSearch ? 'has-search' : ''}
              ${showIconPositionSearch ? 'has-search-position' : ''}
            `}
            placeholder={placeholder}
            value={searchText}
            onChange={onChangeSearchText}
            onClick={clickInput}
            onFocus={handleForcus}
            onBlur={handleBlur}
          />
          {
            hasSelect && 
              <div className='select-dropdown' aria-hidden onClick={()=>handleClickSelectDropdown('select_store')}>
                <span>{dataSelected?.label || ''}</span>
                <img src={images.icon_miniarrow_up_down} className="icon-dropdown" alt="" />
              </div>
          }
          {
            showIconPositionSearch && 
              <div className="icon-search-position" aria-hidden="true" onClick={handleSearchStore} onKeyDown={handleSearchStore}>
                <img src={images.icon_search_ipt} alt="" />
              </div>
          }
          

          {
            showIconSearch && 
              <div className={`icon-search ${disabledSearch && searchText === '' ? 'disabled' : ''}`}  aria-hidden="true" onClick={handleSearchStore} onKeyDown={handleSearchStore}>
                {
                  disabledSearch && searchText === '' ? <IconSearchDisabled /> : <IconSearch />
                }
              </div>
          }

          {
            hasFilter && 
              <div className="icon-filter" aria-hidden="true" onClick={()=>handleClickSelectDropdown('select_filter')}>
                <IconSearch />
              </div>
          }
         
        </div>
        
        {
          showDataFocus && isFocus && (searchText === '' || isClickSearch) &&
          <div className='list-stores-like'>
            <h2>즐겨 찾는 매장</h2>
            <ul className='lists'>
              {renderListStore(isClickSearch ? dataSearch : data)}
            </ul>
          </div>
        }

        {
          showBoxResult && 
            <div className={`result-search ${classResult} ${!showResult ? 'd-none' : ''}`} id="scrollableDiv" ref={searchRef}>
              <InfiniteScroll
                dataLength={dataSearch?.length ?? 0}
                // next={fetchMoreData}
                // hasMore={resParams.current_page < resParams.last_page}
                loader={<small className="text-muted">검색중...</small>}
                scrollableTarget="scrollableDiv"
              >
                {/* {loading && <small className="text-muted">검색중...</small>} */}
                {dataSearch !== null && !dataSearch?.length && 
                  <div className='no-data'>
                    <small className="text-muted">검색 결과가 없습니다.</small>
                  </div>
                }
                {(dataSearch || []).map((e, key) => (
                  <div className="list-search-item" aria-hidden="true" key={key} onClick={() => handleSelectItem(e)}>
                    {/* <p 
                      className="list-search-item__text" 
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: e.name || '',
                      }}
                     /> */}
                     <p className="list-search-item__text" >
                      {e.name}
                     </p>
                     <p className='position'>
                        <img src={images.position_store} className="icon-position" alt="" />
                        <span className='address'>
                          {e.address}
                        </span>
                     </p>
                     
                  </div>
                ))}
              </InfiniteScroll>
            </div>
        }
        
    </div>
  );
};

InputAutoCompleteSearch.defaultProps = {
  data: [],
  placeholder: '',
  classDiv: '',
  classResult: '',
  handleSearch: () => {},
  handleSearchText: () => {},
  resetSearch: false,
  hasSelect: false,
  showIconSearch: false,
  handleClickSelectDropdown: () => {},
  onChangeInputSearch: () => {},
  showDataProduct: () => {},
  showBoxResult: true,
  dataSelected: {},
  hasFilter: false,
  showDataFocus: false,
  handleForcusIpt: () => {},
  showIconPositionSearch: false,
  disabledSearch: false
};

export default memo<Props>(InputAutoCompleteSearch);
