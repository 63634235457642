import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import MainLayout from 'layout/MainLayout';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { API, ROUTES } from 'utils/Apis';
import Lottie from 'react-lottie';
import { EVENT_TYPE } from "constants/common";
import { checkStatusCodeApi, priceFormat } from 'utils/Helpers';
import SkeletonLoadingComponent from 'components/SkeletonLoading';
import LazyLoadImg from 'components/LazyLoadImg';
import ROUTERS from 'constants/router';
import ModalPopup from '../../../components/Modal';
import imgEventMemberDetailWeb from '../../../assets/images/event_new_member_detail_web.webp';
import imgEvenHashtagDetailWeb from '../../../assets/images/event_hashtag_detail_web.webp';
import imgEventLotteryTop from '../../../assets/images/lottery_detail_top@2x.webp';
import imgEvenLotteryBottom from '../../../assets/images/lottery_detail_bottom@2x.webp';
import iconShareHashtag from '../../../assets/images/icon_share_hash_tag.webp';
// import { ReactComponent as EventLotteryDetail } from '../../../assets/images/event_lottery_detail.png';
import btnLotteryDisabled from '../../../assets/images/btn_lottery_disabled.png';
import btnLotteryEnabled from '../../../assets/images/btn_lottery_enabled.png';
import IconGiftJson from '../../../assets/json/gift.json';
// import Lottie from 'react-lottie';

type Props = {};

const noCardHtml = <>
                <label>카드 등록 후 복권을 뽑을 수 있습니다.</label><br /> 
                <label>카드를 등록하고 복권 리워드를</label><br /> 
                <label>받아보세요!</label> 
              </>

const EventDetailComponent = ({}: Props) => {

  const iconGift = {
    loop: true,
    autoplay: true,
    animationData: IconGiftJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  
  const params = useParams();
  // console.log(params, 'params');
  const [isShowPopupTicket, setIsShowPopupTicket] = useState({
    isShow: false,
    type: 'wait',
    bonus: 100,
    customClass: 'wait-ticket-popup',
    msg: ''
  });

  const history = useHistory();
  const location = useLocation();
  const [listCards, setListCards] = useState([]);

  const [paramListCard, ] = useState({
    sort_by: 'created_at',
    order_by: 'desc'
  });

  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loadingImg, setLoadingImg] = useState(true);
  const [loadingCard, setLoadingCard] = useState(true);
  const [totalLottery, setTotalLottery] = useState(0);
  const dataInfo = window.localStorage.getItem('DATA_INFO') ? JSON.parse(window.localStorage.getItem('DATA_INFO')) : '';

  const fetchTotalRemainLottery = async (param = '') => {
    setLoadingDetail(true);
    let url = ROUTES.API_TOTAL_REMAIN_LOTTERY;
    url = url.replace(':id', params.id);
    try {
      const { data: response } = await API.get(url);
      setLoadingDetail(false);
      if (response?.code !== 200) {
        checkStatusCodeApi(response.code);
      }
      // console.log(response, 'response');
      setTotalLottery(response?.data || 0);
    } catch (error) {
      setLoadingDetail(false);
      console.error(error.message);
    }
  };

  const fetchListCards = async (page = 1) => {
    try {
      const { data: response } = await API.get(
        ROUTES.API_GET_LIST_CARD,
        { ...paramListCard }
      );
      if (response?.code !== 200) {
        checkStatusCodeApi(response.code);
      }
      setLoadingCard(false);
      setListCards(response?.data || []);
    } catch (error) {
      setLoadingCard(false);
      console.error(error.message);
    }
  };

  const scratchLottery = async (param = '') => {
    const url = ROUTES.API_STARTCH_LOTTERY;
    try {
      const { data: response } = await API.post(url);
      console.log(response, 'response');
      if (response.code === 200) {
        fetchTotalRemainLottery();
        setIsShowPopupTicket({
          isShow: true,
          type: 'completed',
          customClass: 'give-ticket-popup',
          bonus: priceFormat(response?.data?.lottery?.value || 0)
        });
      }else{
        setIsShowPopupTicket({
          isShow: true,
          type: 'error',
          msg: response?.msg || '일시적인 오류로 복권을 뽑을 수 없습니다. 잠시 후 다시 시도해 주세요. 오류가 계속 발생할 경우 고객센터에 문의해 주세요.',
          customClass: 'no-lottery-popup',
          bonus: 0
        });
      }
    } catch (error) {
      setIsShowPopupTicket({
        isShow: true,
        type: 'error',
        msg: '일시적인 오류로 복권을 뽑을 수 없습니다. 잠시 후 다시 시도해 주세요. 오류가 계속 발생할 경우 고객센터에 문의해 주세요.',
        customClass: 'no-lottery-popup',
        bonus: 0
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (params && params.id === EVENT_TYPE.RECEIVE_LOTTERY) {
      fetchTotalRemainLottery();
      fetchListCards();
    }
  }, []);

  const handleGiveTicket = (e) => {
    e.preventDefault();
    if (listCards && listCards.length <= 0) {
      setIsShowPopupTicket({
        isShow: true,
        type: 'no_card',
        customClass: 'no-lottery-popup',
        bonus: 0,
        msg: noCardHtml
      });
    } else {
      setIsShowPopupTicket({
        isShow: true,
        type: 'wait',
        bonus: 100,
        customClass: 'wait-ticket-popup',
        msg: '',
      })
      setTimeout(() => {
        scratchLottery();
      }, 2000);
    }
  };

  const handleCompleted = () => {
    setIsShowPopupTicket({
      isShow: false,
      type: 'wait',
    });
  };

  const handleClosePopup = () => {
    setIsShowPopupTicket({
      isShow: false,
      type: 'no_card',
      customClass: 'no-lottery-popup',
      bonus: 0,
      msg: noCardHtml
    });
  }

  const renderContentReward = (data) => {
    let html = (
      <div className="icon_ticket">
        <Lottie options={iconGift} isStopped={false} isPaused={false} />
      </div>
    );
    switch (data.type) {
      case 'wait':
        html = (
          <div className="icon_ticket">
           <Lottie options={iconGift} isStopped={false} isPaused={false} />
          </div>
        );
        break;
      case 'completed':
        html = (
          <>
            <div className="content-head">
              <div className="ticket-number">
                <h2>{data.bonus}원</h2>
                <h2>당첨!</h2>
              </div>
              <div className="ticket-decription">
                <p>Pay Money {data.bonus}원이 리워드 보상으로</p>
                <p>
                  <span className="text-focus">고객님의 대표 카드</span>에
                  지급됩니다!
                </p>
              </div>
            </div>
            <div className="group-button-popup">
              <Button
                type="button"
                customClass="button-give-ticket"
                onClick={() => handleCompleted()}
              >
                확인
              </Button>
            </div>
          </>
        );
        break;
      default:
        html = (
          <div className="icon_ticket">
           <Lottie options={iconGift} isStopped={false} isPaused={false} />
          </div>
        );
        break;
    }
    return html;
  };

  const handleBack = () => {
    if (location && location?.search && location?.search.includes('from=recent-activity')) {
      // window.backToScreenByUrlNative('TabOther');
      window.backToScreenNative()
    }else{
      history.push(ROUTERS.EVENT);
    }
  }

  // window.backFromDeviceAndroid = function() {
  //   handleBack();
  // }
  

  const openUrlLink = () => {
    window.backToScreenByUrlNative(process.env.REACT_APP_HASH_TAG_URL || 'http://www.instagram.com/playmoonguya');
  }

  const handleAfterLoad = (e) => {
    console.log(e, 'handleAfterLoad');
    setLoadingImg(false);
  }

  return (
    <MainLayout
      customClass="MainPage"
      isShowHeader
      titleHeader="이벤트 상세"
      icoBackWhite
      isShowIcon
      isLink
      isShowIconBackFunction
      iconBackFunction={() => handleBack()} 
      // isBackNative={true}
      isLoading={false}
    >
      <div className="event-detail-page">
        {
          params && params.id === EVENT_TYPE.RECEIVE_LOTTERY && <>
            <div className="event-detail-page__content">
              <div className="event-detail-page__content__event-item">
                <div className={`content-top ${loadingImg ? 'no-background' : ''}`}>
                  
                  <div className="content-img">
                    <LazyLoadImg src={imgEventLotteryTop} handleAfterLoad={(e) => handleAfterLoad(e)} width='100%'/>
                    {
                      loadingImg && <SkeletonLoadingComponent width={'100%'} height={421}/>
                    }
                  </div>
                  <div className='content-lottery'>
                    <h3 className='lottery-number-title'>{dataInfo?.profile?.nickname ?? location?.state?.nickname}님의 복권 개수</h3>
                    <div className="number-box">
                      {
                        loadingCard ? <SkeletonLoadingComponent width={80} height={30}/> : <span className="number">{totalLottery}개</span>
                      }
                    </div>
                    <div className="event-detail-page__button-tickets">
                      {/* <Button
                        type="button"
                        customClass="button-give-ticket"
                        onClick={() => handleGiveTicket()}
                        isDisabled={loadingCard || totalLottery === 0}
                      >
                        내 복권 뽑기
                      </Button> */}
                      {
                        loadingCard || totalLottery === 0 ? <>
                        <img src={btnLotteryDisabled} alt="" />
                        </> : <>
                        <img src={btnLotteryEnabled} alt="" onClick={(e) => handleGiveTicket(e)}/>
                        </>
                      }
                    </div>
                  </div>
                  <div className="content-img">
                    <LazyLoadImg src={imgEvenLotteryBottom} handleAfterLoad={(e) => handleAfterLoad(e)} width='100%'/>
                    {
                      loadingImg && <SkeletonLoadingComponent width={'100%'} height={3909}/>
                    }
                  </div>
                </div>
                
                <div className='content-bottom'>
                  <div className="content-text">
                    <p>이벤트 안내 사항</p>
                    <label>
                      - 회원 가입 시 추천인 코드를 입력하지 않은 경우 이벤트 혜택이<br />
                        <span>제공되지 않습니다.</span>
                    </label><br />
                    <label>
                      - 친구가 회원가입 시 추천인 코드를 입력하고 이후 플레이페이<br />
                      <span>카드를 등록하여 결제할 경우 복권이 지급됩니다.</span>
                    </label><br />
                    <label>
                      - 이벤트 종료 시, 사용되지 않은 복권은 모두 소멸됩니다.<br />
                    </label><br />
                    <label>
                      - 본 이벤트는 당사 사정에 따라 조기 종료될 수 있습니다.
                    </label><br />
                    <label>
                      - 이벤트로 지급되는 페이 머니는 최소 1일 ~ 최대 14일 <br />
                        <span>이내 지급 됩니다.</span>
                    </label><br />
                    <label>
                      - 복권은 플레이페이 카드로 1,000원 이상 결제할 경우에만<br />
                        <span>지급됩니다.</span>
                    </label><br />
                  </div>
                </div>
              </div>
            </div>
            
          </>
        }

        {
          params && params.id === EVENT_TYPE.NEW_MEMBER && <>
            <div className="event-detail-page__content">
              {/* <img src={imgEventMemberDetail} alt="" /> */}
              <div className="event-detail-page__content__event-item">
                {/* <LazyLoadImg src={imgEventMemberDetailWeb} width='100%'/> */}
                <LazyLoadImg src={imgEventMemberDetailWeb} handleAfterLoad={(e) => handleAfterLoad(e)} width='100%'/>
                {
                  loadingImg && <SkeletonLoadingComponent width={'100%'} height={1157}/>
                }
                <div className='content-bottom'>
                    <div className="content-text">
                      <p>이벤트 안내 사항</p>
                      <label>
                        - 신규 가입 시 ID 당 1회 자동 지급 됩니다.<br />
                      </label>
                      <label>
                        - 플레이 머니는 현금과 동일하게 사용 가능 합니다.<br />
                      </label>
                      <label>
                        - 본 이벤트는 당사 사정에 따라 조기 종료될 수 있습니다.<br />
                      </label>
                      <label>
                        - 이벤트로 지급되는 페이 머니는 최소 1일 ~ 최대 14일<br />
                        <span>이내 지급 예정입니다.</span>
                      </label>
                    </div>
                </div>
              </div>
              
            </div>
          </>
        }

        {
          params && params.id === EVENT_TYPE.HASH_TAG && <>
            <div className="event-detail-page__content">
              {/* <img src={imgEvenHashtagDetail} alt="" /> */}
              <div className="event-detail-page__content__event-item ">
              <div className="hashtag">
                {/* <LazyLoadImg src={imgEvenHashtagDetailWeb} className="hashtag" width='100%'/> */}
                <LazyLoadImg src={imgEvenHashtagDetailWeb} className="hashtag" handleAfterLoad={(e) => handleAfterLoad(e)} width='100%'/>
                {
                  loadingImg && <SkeletonLoadingComponent width={'100%'} height={2618}/>
                }
                <div className="hashtag-icon" onClick={() => openUrlLink()}>
                  {!loadingImg && <img className='icon' src={iconShareHashtag} alt="" />}
                </div>
              </div>
              <div className='content-bottom'>
                  <div className="content-text">
                    <p>이벤트 유의 사항</p>
                    <label>
                      - 본 이벤트는 당사 사정에 따라 조기 종료될 수 있습니다.
                    </label><br />
                    <label>
                      - 이벤트 참여 조건을 충족하지 않으실 경우, 이벤트 참여 대상<br />
                      <span>에서 제외됩니다.</span>
                    </label><br />
                    <label>
                      - 본 이벤트는 중복 참여가 가능합니다.
                    </label><br />
                  </div>
                </div>
              </div>
            </div>
          </>
        }

      </div>
      <ModalPopup
        isOpen={isShowPopupTicket.isShow || false}
        customClass={isShowPopupTicket.customClass}
        isShowHeader
      >
         <div className="content">
          {
            isShowPopupTicket.type === 'no_card' || isShowPopupTicket.type === 'error' ? 
            <div className='content-no-lottery'>
              <div className="note">
                {/* <label>카드 등록 후 복권을 뽑을 수 있습니다.</label><br /> 
                <label>카드를 등록하고 복권 리워드를</label><br /> 
                <label>받아보세요!</label>  */}
                {
                  isShowPopupTicket?.msg || ''
                }
              </div>
              <Button
                type="button"
                customClass="button-close"
                onClick={() => handleClosePopup()}
              >
                확인
              </Button>
            </div> : renderContentReward(isShowPopupTicket)
          }
        </div>
      </ModalPopup>
    </MainLayout>
  );
};

export default EventDetailComponent;
