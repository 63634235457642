import ROUTERS from './router';
import ico_tab1_action from '../assets/images/GNB_01.svg';
import ico_tab2_action from '../assets/images/gnb-03_white_gift.svg';
import ico_tab5_action from '../assets/images/GNB_05-1.svg';
import ico_tab1 from '../assets/images/GNB_01.svg';
import ico_tab2 from '../assets/images/gnb-03_white_gift.svg';

// import ico_tab4 from '../assets/images/GNB_04-1.svg';
import ico_tab5 from '../assets/images/GNB_05-1.svg';

import ico_tab3_action from '../assets/images/GNB_03_search_action.svg';
import ico_tab4_action from '../assets/images/GNB_02-1.svg';
import ico_tab3 from '../assets/images/GNB_03_search.svg';
import ico_tab4 from '../assets/images/GNB_02-1.svg';

const footerTabs = [
  {
    key: 1,
    classAction: ico_tab1_action,
    class: ico_tab1,
    classActive: 'btn-tab-1-on',
    name: 'main',
    link: ROUTERS.MAIN,
  },
  {
    key: 2,
    classAction: ico_tab2_action,
    class: ico_tab2,
    name: 'order',
    link: ROUTERS.ORDER,
    classActive: 'btn_tab_on',
  },
  {
    key: 3,
    classAction: ico_tab3_action,
    class: ico_tab3,
    name: 'search',
    link: ROUTERS.FIND_STORE,
    classActive: 'btn_tab_on',
  },
  {
    key: 4,
    classAction: ico_tab4_action,
    class: ico_tab4,
    classActive: 'btn_tab4_on',
    name: 'product',
    link: ROUTERS.INQUIRY_PRODUCT,
  },
  // {
  //   key: 4,
  //   classAction: ico_tab4_action,
  //   class: ico_tab4,
  //   classActive: 'btn_tab4_on',
  //   name: 'history',
  //   link: ROUTERS.HISTORY_BUY,
  // },
  {
    key: 5,
    classAction: ico_tab5_action,
    class: ico_tab5,
    classActive: 'btn_tab5_on',
    name: 'my',
    link: ROUTERS.MY_PAGE,
  },
];

export default footerTabs;
