/* eslint-disable camelcase */
import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from 'utils/Apis';

import { Types } from '../redux';

// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* updateComment(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() =>
      API.post(ROUTES.API_UPDATE_COMMENT, action.data)
    );

    if (response.ok) {
      // In case: signup request success
      yield put({
        type: Types.UPDATE_COMMENT_SUCCESS,
        enquiryId: action?.enquiryId,
        params: action?.data,
      });
    } else {
      yield put({
        type: Types.UPDATE_COMMENT_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.UPDATE_COMMENT_FAILED, error });
  }
}

/*
  Starts updateComment on each dispatched `UPDATE_COMMENT` action.
*/
function* updateCommentSaga() {
  yield takeLatest(Types.UPDATE_COMMENT, updateComment);
}

export default updateCommentSaga;
