import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from '../../utils/Apis';

import { Types } from '../redux';

// worker Saga: will be fired on SIGN_IN actions
function* signIn(action) {
  try {
    /**
     * Example data
     * url: enpoint/login
     
     *
     */
    const response = yield call(() =>
      API.post(ROUTES.API_LOGIN, action.loginInfo, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    );

    if (response.ok) {
      const { data } = response;
      // In case: signup request success
      // console.log(data, 'data');
      yield put({ type: Types.SIGN_IN_SUCCESS, data });
    } else {
      // In case: signup request failed
      yield put({
        type: Types.SIGN_IN_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.SIGN_IN_FAILED });
  }
}

/*
  Starts signupAccount on each dispatched `SIGN_IN` action.
*/
function* signInSaga() {
  yield takeLatest(Types.SIGN_IN, signIn);
}

export default signInSaga;
