
import React, { memo }  from 'react';

function  CollectionUsePersonalRegisterComponent() {
  return (
    <>
      <div>
        <div className='content text-right'>
          개정 2021.07.22
        </div>
      </div>
      <div>
          <h2 className="title">제 1 조(수집하는 개인정보의 항목 및 수집 방법)</h2>
          <div className="content">
            회사는 아래와 같이 서비스 제공을 위해 필요한 최소한의 개인정보를 수집하고 있습니다.<br /><br />
            <span>①</span> 개인정보 수집항목<br />
            1. 회원가입 시<br />
            필수: 휴대전화번호, 이름, 이메일주소, 생년월일, 성별<br />
            2. 서비스 이용, 처리과정 시<br />
            필수: 서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보, MAC 주소, 운영체제 종류 및 버전 정보, 기기 정보, 회원 계좌정보(은행명, 계좌번호, 예금주성명), 이용자 위치정보, 휴대전화번호, 이름, 생년월일, 성별, 이메일<br />
            3. 충전, 결제, 환불과정 시<br />
            필수: 가맹점 명, 단말기 정보, 카드정보(카드번호, CVC 번호, 유효기간, 최근 거래내역, 보유잔액), 계좌정보(은행 명, 계좌번호, 예금주성명)<br />

            <span>②</span> 개인정보 수집방법<br />
            1. 어플리케이션, 홈페이지, 서면, 전자우편, 팩스, 전화 등을 통해 이용자가 제시<br />
            2. 컴퓨터나 휴대전화 등 모바일 기기의 자동수집 장치를 통한 수집<br />
            3. 협력업체로부터의 제공<br />
            회사는 개인을 식별할 수 없는 온라인상의 이용자 활동정보(행태정보)를 협력회사로부터 제공받거나 생성정보 수집 툴 등을 통해 직접 수집할 수 있으며, 회사가 보유한 개인(식별)정보와 결합하여 처리할 경우 본 동의 약관에서 정한 이용 목적 및 보유기간을 따릅니다.<br />
            또한, 회사는 서비스 이용 도중 추가적인 개인정보를 수집할 수 있으며, 이 경우 별도의 개인정보 수집 및 이용 동의를 받습니다.
          </div>
      </div>
      <div>
        <h2 className="title">제 2 조(개인정보의 수집 및 이용목적)</h2>
        <div className="content">
          회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br /><br />

          <span>①</span> 서비스 이용자 관리 및 서비스 제공<br />
          1. 서비스 제공에 관한 계약 체결, 이행 및 해지를 위한 이용자 식별<br />
          2. 나만의 카드 발급, 충전, 결제, 환불<br />
          3. 서비스 이용 관련 상담, 민원 처리분쟁 조정을 위한 기록 보존<br />
          4. 서비스 변경 등 고지사항 전달<br />
          5. 이용자의 서비스 부정 이용 또는 비인가 사용 방지<br />
          6. 분쟁 조정을 위한 기록 보존<br />
          7. 온라인몰 상품 배송<br />
          8. 카드수신 확인, 카드등록 및 사용법 안내 목적 아웃바운드 콜<br />
          9. 소득공제 신청<br />

          <span>②</span> 신규 서비스 개발 및 마케팅·광고에 활용<br />
          1. 서비스 이용에 대한 통계 수집 등을 통한 신규 서비스 개발 및 맞춤 서비스 제공<br />
          2. 이벤트 등 광고성 정보 전달과 참여기회 제공<br />
        </div>
      </div>
      <div>
        <h2 className="title">제 3 조(개인정보의 제 3 자 취급 위탁)</h2>
        <div className="content">
          회사는 서비스 제공을 위해 필요한 이용자의 개인정보 및 업무의 일부를 외부 협력업체(이하 “수탁자”라 한다)에 취급 위탁하고 있으며, 현재 회사가 수탁자에게 위탁하고 있는 개인정보 내역은 어플리케이션 화면 또는 홈페이지 등에 게시된 개인정보 취급방침에서 확인할 수 있습니다.
        </div>
      </div>
      <div>
        <h2 className="title">제 4 조(개인정보의 보유 및 이용기간)</h2>
        <div className="content">
          회사는 원칙적으로 이용자에게 동의 받은 개인정보 수집 및 이용목적이 달성된 후 해당 정보를 지체 없이 파기합니다. 단, 내부 방침에 의하거나 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 일정한 기간 동안 이용자의 개인정보를 보관합니다.<br />
          <br />
          <span>①</span> 내부 방침에 의한 정보 보유 사유<br />
            1. 부정 이용 기록<br />
            - 보존 이유: 반복적 임의 해지 통한 경제상 이익 불·편법적으로 수취하는 행위 방지 및 차단된 계정 재가입 통한 부정 이용 방지<br />
            - 보존 기간: 1 년<br />

            2. 전자우편 주소, 아이디, 전화번호<br />
            - 보존 이유: 삭제된 회원의 민원처리 및 제휴 기관과의 거래 대금 정산 등 기타 필요한 경우 대비 목적<br />
            - 보존 기간: 1 년<br />
            <br />
            <span>②</span> 관련 법령에 의한 정보 보유 사유<br />
            1. 계약 또는 청약철회 등에 관한 기록<br />
            - 보존 근거: 전자상거래 등에서의 소비자보호에 관한 법률<br />
            - 보존 기간: 5 년<br />
            <br />
            2. 대금결제 및 재화 등의 공급에 관한 기록<br />
            - 보존 근거: 전자상거래 등에서의 소비자보호에 관한 법률<br />
            - 보존 기간: 5 년<br />
            <br />
            3. 소비자의 불만 또는 분쟁 처리에 관한 기록<br />
            - 보존 근거: 전자상거래 등에서의 소비자보호에 관한 법률<br />
            - 보존 기간: 3 년<br />
            <br />
            4. 건당 거래금액이 1 만원을 초과하는 전자금융 거래에 관한 기록<br />
            - 보존 근거: 전자금융거래법시행령<br />
            - 보존 기간: 5 년<br />
            <br />
            5. 건당 거래금액이 1 만원 이하인 전자금융 거래에 관한 기록<br />
            - 보존 근거: 전자금융거래법시행령<br />
            - 보존 기간: 1 년<br />

            6. 홈페이지 방문기록<br />
            - 보존 근거: 통신비밀보호법<br />
            - 보존 기간: 3 개월<br />
            <br />
            7. 본인확인에 관한 기록<br />
            - 보존 근거: 정보통신망 이용촉진 및 정보보호 등에 관한 법률<br />
            - 보존 기간: 6 개월<br />
        </div>
      </div>
      <div>
        <h2 className="title">제5조(이용자의 개인정보 수집·이용 거부권 및 거부에 따른 불이익 등)</h2>
        <div className="content">
          <span>①</span> 이용자는 위와 같은 개인정보 수집·이용에 동의하지 않을 수 있습니다. 다만, 위와 같은 개인 정보는 카드 서비스 제공을 위해 필수적인 내용으로 이용자가 동의하지 않을 경우 나만의 카드 회원 가입 및 서비스 이용이 불가능합니다.<br />
          <span>②</span> 회사는 마케팅, 이벤트 행사 등 카드 서비스 제공에 필수적이지 않은 개인정보의 경우 이용자의 별도 동의를 얻어 개인정보를 수집·이용하고 있습니다.<br />
          <span>③</span> 이용자는 어플리케이션 화면 또는 홈페이지에 게시된 회사의 개인정보 취급방침에서 개인정보와 관련된 기타 내용을 확인할 수 있습니다.<br /><br />
          부칙<br />
          제 1 조 (시행일)<br />
          이 약관은 2021년 7 월 22일부터 시행합니다.<br />
        </div>
      </div>
    </>
  ) 
}

export default memo(CollectionUsePersonalRegisterComponent)