import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from 'utils/Apis';

import { Types } from '../redux';
import { Types as TypesAcount } from '../../../account/redux';


// worker Saga: will be fired on GET_SMS_CODE actions
function* getDataMainPage() {
  try {
    /**
     * Example data
     * url: enpoint/register/send-confirm-code
     *
     */
    const response = yield call(() =>
      API.get(ROUTES.API_MAIN, { ran: Math.random() })
    );
    const data = response?.data;
    if (response.ok) {
      // In case: get sms code request success
      yield put({ type: Types.GET_DATA_MAIN_SUCCESS, data });
    } else {
      // In case: get sms code request failed
      const status = response.status || response.response.status;
      console.log(status, 'status')
      if(status == 401){
        yield put({
          type: TypesAcount.LOG_OUT,
        });
      }else{
        yield put({
          type: Types.GET_DATA_MAIN_FAILED,
        });
      }

    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.GET_DATA_MAIN_FAILED, error });
  }
}

/*
  Starts signupAccount on each dispatched `SIGN_IN` action.
*/
function* getDataMainPageSaga() {
  yield takeLatest(Types.GET_DATA_MAIN, getDataMainPage);
}

export default getDataMainPageSaga;
