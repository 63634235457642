
import React, { useState, useEffect } from "react";
import NoData from "components/NoData";
import MainLayout from "layout/MainLayout";
import { useHistory } from "react-router-dom";
import { API, ROUTES } from "utils/Apis";
import moment from "moment";
import LazyLoadImg from "components/LazyLoadImg";
import SkeletonLoadingComponent from "components/SkeletonLoading";
import ROUTERS from "constants/router";

import ClockIcon from "assets/icons/clock.webp";
// import ClockIcon from "assets/icons/clock_icon.png";
import OopsIcon from "assets/icons/oops.svg";
import { checkStatusCodeApi } from "utils/Helpers";

type Props = {
  userInfo: any,
  token: String
};


const ConsultionComponent = ({
  userInfo,
  token,
}:Props) => {

  const history = useHistory();

  console.log(25, token);
  console.log(26, userInfo);

  const [list, setList] = useState({
    loading: true
  })

  const fetchConsultionLists = async (page = 1, pageSize = 99999999) => {
    try {
      const { data: response } = await API.get(
        ROUTES.API_GET_CONSUTANT+'?pageSize='+pageSize
      );
      if (response?.code !== 200) {
        checkStatusCodeApi(response?.code || null);
      }

      setList({loading: false, ...response.data})
    } catch (error) {
      console.error(error.message);
      setList({...list, loading: false})
    }
  };

  useEffect(() => {
    fetchConsultionLists();
  }, [])

  const goToDetail = (id) => {
    const url = ROUTERS.CONSULTION_DETAIL.replace(':id', id);
    // type=web-view(web-view: back to list) or nothing (back to native)
    history.push(`${url}?type=web-view`);
  }

  const goToCreate = () => {
    const url = ROUTERS.CONSULTION_CREATE;
    // type=web-view(web-view: back to list) or nothing (back to native)
    history.push(`${url}?type=web-view`);
  }

  const renderList = () => {
    if(list.loading) return <></>
    if(list.data && list.data.length > 0){
      return list.data.map((item, k) => {
        return <div className={item.state === 'COMPLETED' ? "consult-item completed": "consult-item"} onClick={()=>goToDetail(item.id)}>
          <label>{item.state === 'COMPLETED' ? "답변 완료": "답변 대기"}</label>
          <h3>{item.subject}</h3>
          <div className="info">
            <span>{moment(item.created_at).format('YY.MM.DD')}</span><span>|</span><span>{item.user?.nickname}</span>
          </div>
        </div>
      })
    }else {
      return <div className="empty-list">
        <img src={OopsIcon}/>
        <p>문의 목록이 없습니다.</p>
      </div>
    }
  }

  return (
    <MainLayout
      customClass="MainPage"
      isShowHeader
      titleHeader="1:1 문의"
      icoBackWhite
      isShowIcon
      isLink
      isBackNative
      isLoading={list.loading}
    >
      <div className="consultion-page">
        {/*<p>*/}
        {/*  {token}*/}
        {/*</p>*/}
        {/*<br />*/}
        {/*<a href="#" onClick={() => goToDetail()}>*/}
        {/*  Detail*/}
        {/*</a>*/}
        <div className="support-heading">
          <img src={ClockIcon} alt=""/> <strong>운영 시간 안내</strong>
        </div>
        <div className="support-panel">
          <p>- 접수: 24시간 접수 가능</p>
          <p>- 답변: 평일 9:00 ~ 18:00 (토, 일 공휴일 휴무)</p>
        </div>
        {renderList()}
      </div>
      <div className="bottom-panel">
        <button onClick={goToCreate}>문의 작성하기</button>
      </div>
    </MainLayout>
  )     
    
}

export default ConsultionComponent;