// @flow
import React, { memo } from 'react';
import Button from 'components/Button';
import Input from 'components/Input';
import images from 'themes/images';
import REGEX from 'constants/regex';

type Props = {
  itemObj: Object,
  handleDeleteComment: Function,
  handleGetContentOfUser: Function,
  userId: any,
  handleChange: Function,
  handleSubmitEditComment: Function,
  idCommentOfUser: Function,
  contentEditComment: string,
  handleOnFocusInput: Function,
  handleOnBlurInput: Function,
  avatarOffUser: Function,
  handleShowCmtEditDel: Function,
  showEditDel: any
};

const ItemComment = ({
  itemObj,
  handleDeleteComment,
  userId,
  handleGetContentOfUser,
  contentEditComment,
  handleChange,
  handleSubmitEditComment,
  idCommentOfUser,
  handleOnFocusInput,
  handleOnBlurInput,
  avatarOffUser,
  handleShowCmtEditDel,
  showEditDel
}: Props) => {

  return (
    <div className="item-comment">
      <div className="inquiry-head">
        <div className="inquiry-user">
          <div className="inquiry-user-img">
            <img
              src={
                itemObj?.user_image_path ||
                itemObj?.user_profile ||
                (itemObj?.requested === 1
                  ? avatarOffUser?.avatar?.thumbnail
                  : images.itemLevel4On)
              }
              alt=""
            />
          </div>
          <div className="left-box-info">
            <div className="inquiry-user-name">
              {itemObj?.user_name || itemObj?.nickname}
            </div>
            <div className="inquiry-time">{itemObj?.created_at}</div>
          </div>
        </div>

        {itemObj?.requested === 1 && (
          <div className="action-edit-delete-comment">
            <img className='icon-dot' src={images.three_dot} aria-hidden onClick={() => handleShowCmtEditDel(itemObj?.id)} alt="" />
            <div className={`edit-delete ${showEditDel.isShow && showEditDel.idCmtShow === itemObj?.id ? '' : 'd-none'}`}>
              <div
                className="action-edit"
                onClick={() => handleGetContentOfUser(itemObj)}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
              >
                수정
              </div>
              <div
                className="action-delete pr-0"
                onClick={() => handleDeleteComment(itemObj)}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
              >
                삭제
              </div>
            </div>

            {/* <div
              className="action-edit"
              onClick={() => handleGetContentOfUser(itemObj)}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
            >
              수정
            </div>
            <span>|</span>
            <div
              className="action-delete pr-0"
              onClick={() => handleDeleteComment(itemObj)}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
            >
              삭제
            </div> */}
          </div>
        )}
      </div>
      <div className="content-comment">{itemObj.comment ? itemObj.comment.trim().replace(REGEX.LAST_CHARACTER_POLICY, '') : ""}</div>
      
      <div
        className={`form-addComment ${
          idCommentOfUser === itemObj?.id ? 'd-block' : 'd-none'
        }`}
      >
        <div className="group-form  d-flex align-items-end">
          <Input
            placeholder=""
            type="text"
            name="content"
            value={contentEditComment}
            maxLength="50"
            onChange={(e) => handleChange(e.target.value, 'editComment')}
            onFocus={handleOnFocusInput}
            onBlur={handleOnBlurInput}
          />
          <div className="login-button edit-comment">
            <Button
              isDisabled={!contentEditComment.trim()}
              onClick={() => handleSubmitEditComment()}
            >
              수정
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo<Props>(ItemComment);
