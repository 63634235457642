// @flow
// libs
import React, { memo } from 'react';
import IMAGES from 'themes/images';

type Props = {
  onChange: Function,
  isChecked: boolean,
};

export const Radio = ({ isChecked, onChange }: Props) => {
  return (
    <div className={`wrap-radio-cus ${isChecked ? 'checked' : ''}`} onClick={onChange} onKeyDown={onChange} role="presentation">
      <label className='status-check'/>
    </div>
  );
};

export default memo<Props>(Radio);
