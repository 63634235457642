import React, { memo } from 'react';

function TermsConditionsRegisterComponent({ data }: Props) {
  console.log(data, 'data');
  return (
    <>
      <div>
        <div className="content text-right">개정시행 2022. 3 . 21</div>
      </div>
      <div>
        <h2 className="title">제1조 (목적)</h2>
        <div className="content">
          문구야놀자 서비스 이용약관(이하 “이 약관”이라 합니다)은 주식회사
          문구야놀자(이하 “회사”라 합니다)가 제공하는 문구야놀자 서비스를
          이용하는 개인과 회사 간에 문구야놀자 서비스 이용에 필요한 조건, 절차,
          당사자의 권리, 의무 등 필요한 사항을 규정하는 것을 목적으로 합니다.
        </div>
      </div>
      <div>
        <h2 className="title">제2조 (용어의 정의)</h2>
        <div className="content">
          <span>①</span> 이 약관에서 사용하는 용어의 정의는 다음 각 호와
          같습니다.
          <br />
          1. 선불전자지급수단이란 '이전 가능한 금전적 가치가 전자적 방법으로
          저장돼 발행된 증표 또는 그 증표에 관한 정보로서 발행인 외의
          제3자로부터 재화, 용역을 구입하고 그 대가를 지급하는 데 사용할 수 있는
          수단 (단, 구입할 수 있는 재화 또는 용역의 범위가 2개 업종 이상)'을
          말합니다
          <br />
          2. “문구야놀자”는 선불전자지급수단입니다. 선불전자지급수단의 한도는
          50만원이며, 기명과 무기명으로 구분됩니다.
          <br />
          가. 무기명: 실지명의 확인이 없거나 예금계좌와 연결되지 않고 발행된
          선불전자지급수단
          <br />
          나. 기명: 실지명의 확인되거나 예금계좌와 연결되어 발행된
          선불전자지급수단
          <br />
          3. “문구야놀자”의 접근 매체는 전자식 카드입니다. “접근매체”라 함은
          전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과
          정확성을 확보하기 위하여 사용되는 수단 또는 정보를 말합니다.
          <br />
          4. “문구야놀자 서비스(이하 “서비스”라 합니다)”는 이용자가 문구야놀자를
          결제매체로 사용하여 재화와 용역을 구매할 수 있도록 회사가 제공하는
          카드발급, 충전, 결제, 환급, 이용내역 조회 등의 제반 서비스를 말합니다.
          <br />
          가. 카드발급: 이용자는 회원가입 시 또는 외부 유통채널을 통해서 회사가
          발행하는 전자식카드를 신청 또는 구매하여 카드를 발급받을 수 있습니다.
          <br />
          나. 충전: 현금과 동일하게 인정하는 금전적 가치를 문구야놀자에 이전하여
          재화 및 용역의 구입 등에 사용할 수 있도록 저장하는 것을 말합니다.
          이용자는 보유한 전자식카드를 문구야놀자 어플리케이션에 등록하여
          충전하거나 문구야놀자 키오스크에서 충전할 수 있습니다.
          <br />
          다. 결제: 회사 또는 제휴사의 가맹점에서 문구야놀자로 재화나 용역을
          구매하는 행위를 말합니다. 이용자는 충전된 잔액만큼 가맹점에서
          전자식카드로 결제할 수 있습니다.
          <br />
          라. 환급: 문구야놀자에 기록된 잔액을 회사가 정한 방법과 절차에 따라
          이용자에게 돌려주는 것을 말합니다. 교통카드의 충전금은 문구야카드로
          전액을 이전하여 환급받을 수 있습니다. 환급 방식은 계좌환불, 충전취소
          2가지로 구분합니다.
          <br />
          1). “계좌환불”: 보유한 문구야놀자 잔액의 해당 금액을 은행계좌로
          이체하는 것을 말합니다.
          <br />
          2). “충전취소": 문구야놀자 잔액을 충전 후 1주일 이내 미사용 시 잔액의
          전부를 충전 취소하는 것을 말합니다.
          <br />
          마. 이용내역조회: 이용자는 보유한 전자식카드를 문구야놀자
          어플리케이션에 등록하여 충전, 결제, 환급 내역을 확인할 수 있습니다.
          <br />
          5. “문구야놀자 서비스 이용자(이하 “이용자”라 합니다)”는 “회사” 및/또는
          “제휴사”가 정한 기준에 따라 이용약관 동의, “제휴사”가 제공하는
          이용약관(이하 “제휴사약관”) 동의, 를 완료하여 “문구야놀자” 및/또는
          “제휴서비스” 사용 권한을 부여 받은 자를 의미하며 회원과 비회원으로
          구분됩니다.
          <br />
          6. “회원”은 회사가 정한 절차에 따라서 모바일 기기 등에 어플리케이션을
          설치하고 본 약관에 동의한 후, 회사가 요청하는 정보를 입력하여 가입을
          승인 받아 서비스를 이용하는 자를 말합니다. 회원은 문구야놀자
          어플리케이션의 모든 서비스를 이용할 수 있습니다.
          <br />
          7. “비회원”은 키오스크에서 본 약관에 동의한 후 문구야놀자 상품을
          구매하거나, 어플리케이션을 설치하고 본 약관에 동의한 후, 회원으로
          가입하지 않고 전자식카드를 구매 또는 취득하여 회사가 제공하는 서비스를
          이용하는 자를 말합니다. 비회원은 문구야놀자 어플리케이션 내 카드 관리
          서비스를 이용할 수 없으며 서비스에 대한 안내를 확인할 수 있습니다.
          <br />
          8. “문구야놀자 어플리케이션(이하 “어플리케이션”이라 합니다)”은
          이용자가 모바일 기기 등에서 회사가 제공하는 서비스를 이용할 수 있도록
          회사 또는 제휴사가 운영하는 응용 소프트웨어를 말합니다.
          <br />
          9. “문구야놀자 가맹점(이하 “가맹점”이라 합니다)”은 문구야놀자 가맹점
          약관에 의하여 가맹점 가입을 신청하고 회사와 가맹계약을 맺어 이용자에게
          지불거래 또는 충전거래 서비스를 제공하는 시설 또는 업소 등을 말합니다.
          <br />
          10. “문구야놀자 리워드”는 회원이 서비스를 이용하여 일정 금액 이상
          충전하거나 프로모션 이벤트 등에 참여하는 경우 회사의 정책에 따라
          회원에게 무상으로 지급되는 것을 말합니다. 회원은 일정 기준 이상 적립된
          리워드를 경품들과 교환하거나, 이벤트 응모 시 사용할 수 있습니다.
          <br />
          11. “부가 서비스”는 어플리케이션 안에서 회원을 대상으로 제공하는
          기능들로 카드상품구매 및 선물하기, 잔액이전, 분실신고, 충전용
          카드/계좌등록, 환불 신청, 송금 등의 서비스를 말합니다.
          <br />
          가. “분실신고 서비스”: 어플리케이션이나 고객센터를 통해 회사가 정하는
          방식에 따라 이용자가 보유한 문구야놀자의 분실 또는 도난 사실을
          신고하여, 해당 카드의 사용을 중지시키는 서비스를 말합니다. 회사는
          이용자로부터 문구야놀자 분실이나 도난 사실이 접수된 이후에 제 3 자가
          문구야놀자를 사용함으로 인하여 이용자에게 발생한 손해를 배상할 책임이
          있습니다.
          <br />
          나. “잔액이전”: 회원이 다수의 문구야놀자를 보유한 경우 문구야놀자 간
          잔액 전부 또는 일부를 옮길 수 있는 서비스를 말합니다.
          <br />
          다. 송금서비스: 회원이 보유한 문구야놀자 잔액의 전부 또는 일부를 다른
          사람의 문구야놀자 또는 별도로 지정하는 계좌로 보내는 서비스를
          말합니다.
          <br />
          12. “문구야놀자 키오스크(이하 “키오스크"라 합니다)”는 카드를
          오프라인에서 구매할수 있도록 서비스를 제공합니다. 고객은 카드를
          키오스크에 태그하여 구매할 수 있습니다.
          <br />
          13. “제휴사”는 회사가 제공하는 서비스를 이용하여 제휴사의 사업을
          영위하기 위하여 카드발행 제휴계약, 어플리케이션 임대계약(ASP계약) 등
          회사와 별도의 서비스이용 계약을 체결하는 사업자를 말합니다. 제휴사를
          통해 제공되는 서비스는 아래 각 호와 같습니다.
          <br />
          가. “교통카드 서비스”: 전자식 교통카드를 통해 계약을 체결한 교통기관에
          대금을 지불하거나, 기타 “제휴사”의 온ㆍ오프라인 가맹점에서 관련
          서비스(충전, 결제 등)를 이용∙관리할 수 있게 하는 서비스
          <br />
          <span>②</span> 이 약관에 사용하는 용어 중 본 조에서 정하지 아니한 것은
          관계 법령 및 전자금융거래 이용약관과 제휴약관에서 정하는 바에 따르며,
          그 외에는 일반 관례에 따릅니다.
          <br />
        </div>
      </div>
      <div>
        <h2 className="title">제3조 (약관의 효력 및 변경)</h2>
        <div className="content">
          <span>①</span> 이 약관은 정당한 방법으로 문구야놀자를 구입하여
          사용하는 이용자에게 적용됩니다.
          <br />
          <span>②</span> 회사는 이용자의 요청이 있는 경우 전자문서 전송,
          전자우편, 모사전송, 우편 또는 직접 교부의 방식(이하 “전자문서 전송
          등의 방식”이라 합니다)으로 이 약관의 사본을 이용자에게 교부합니다.
          <br />
          <span>③</span> 회사가 이 약관을 변경하고자 할 경우에는 변경될 약관의
          시행일자 및 변경사유를 명시하여, 시행일 1월 전부터 어플리케이션 화면
          등에 게시합니다. 다만, 법령의 개정 등으로 인하여 긴급하게 약관을
          변경한 경우에는 변경된 약관을 어플리케이션 화면 등에 1개월 이상
          게시하고 이용자에게 전자문서 전송 등의 방식을 통해 사후 통지합니다.
          <br />
          <span>④</span> 약관이 변경되는 경우에는 제3조의 공지 외에도 전자우편,
          휴대전화 문자메시지 전송 또는 PUSH 발송 등(이하 “전자우편 전송 등”이라
          합니다)의 방법으로 이용자에게 개별 통지할 수 있습니다. 이 경우
          이용자가 연락처를 기재하지 않았거나 변경된 연락처를 회사에 알리지 않은
          경우와 같이 이용자에 대한 개별 통지가 어려운 경우에는 어플리케이션
          화면 등을 통하여 공지함으로써 개별 통지한 것으로 간주합니다.
          <br />
          <span>⑤</span> 회사가 본 조의 게시나 통지를 하는 경우에는 이용자가
          약관의 변경내용이 게시되거나 통지된 후부터 변경되는 약관의 시행일 전의
          영업일까지 이내에 계약을 해지할 수 있으며, 약관의 변경내용에 이의를
          제기하지 아니하는 경우 약관의 변경내용에 승인한 것으로 본다는 내용을
          통지합니다.
          <br />
          이용자는 본 조에 따라 변경된 약관에 동의하지 않는 경우 서비스 이용을
          중단하고 회사와의 서비스 이용 계약을 해지할 수 있습니다. 다만,
          이용자가 변경된 약관의 적용일 전일까지 명시적으로 약관 변경에 대해
          회사에 거부 의사를 표시하지 아니할 경우 약관의 변경에 동의한 것으로
          간주하고 변경된 약관을 적용합니다.
          <br />
          <span>⑥</span> “제휴약관”에 대한 동의 및 약관개정절차 등 제반 사항은
          해당 “제휴사”가 정한 바에 따릅니다.
          <br />
        </div>
      </div>
      <div>
        <h2 className="title">제4조 (약관 외 준칙)</h2>
        <div className="content">
          이 약관에 명시되지 않은 사항에 대해서는 약관의 규제에 관한 법,
          전자상거래 등에서의 소비자보호에 관한 법, 전자금융거래법, 정보통신망
          이용촉진 및 정보보호 등에 관한 법, 신용정보의 이용 및 보호에 관한 법,
          통신비밀보호법, 전기통신사업법 등 관계 법령, 회사가 이용자와 맺은 기타
          약관, 회사가 정한 각 서비스의 세부 이용지침 및 상 관례에 의합니다.
        </div>
      </div>
      <div>
        <h2 className="title">제5조 (서비스 이용 신청)</h2>
        <div className="content">
          <span>①</span> 본 서비스는 정당한 방법으로 문구야놀자 상품/카드 등을
          구입하여 사용하는 이용자에게 계약이 성립됩니다.
          <br />
          <span>②</span> 회사는 다음 각 호에 해당하는 서비스 이용 신청의
          경우에는 승낙을 유보하거나 사후에 이용 계약을 취소할 수 있습니다.
          <br />
          1. 기술상, 보안상 또는 설비상 문제로 서비스 제공이 불가능한 경우
          <br />
          2. 본인의 명의가 아닌 타인의 명의를 사용하는 등 허위로 신청하는 경우
          <br />
          3. 회사가 요구하는 신청양식에 기입하여야 할 사항을 누락하거나 오기하여
          신청하는 경우
          <br />
          4. 제10조에 의하여 회사로부터 이용정지를 당한 이용자가 그 이용정지
          기간 중 이용계약을 임의 해지하고 재이용신청을 하는 경우
          <br />
          5. 과거 이 약관의 위반 등의 사유로 본 서비스 이용계약이 해지 당한
          경력이 있는 경우
          <br />
          6. 회사가 정한 이용 신청 요건이 충족되지 않은 경우
          <br />
          <span>③</span> 이용자는 회사가 제공하는 각 부가 서비스 별로 이용을
          신청할 수 있으며, 신청 절차 및 이용자의 신청 자격 등에 대해서는
          어플리케이션 화면의 안내에 의합니다.
          <br />
          <span>④</span> 이용자는 그 자격에 따라 개별 서비스 이용의 일부가
          제한될 수 있으며, 휴대전화를 포함한 모바일 기기 등의 사용자 명의 변경,
          해지 등 서비스 변동에 따라 이용 자격 및 서비스 이용 범위가 변동될 수
          있습니다.
          <br />
        </div>
      </div>
      <div>
        <h2 className="title">제6조 (서비스의 이용, 변경 및 중지)</h2>
        <div className="content">
          <span>①</span> 이용자는 회사가 정한 절차 및 회사가 부여한 자격에 따라
          즉시 서비스를 신청, 이용할 수 있습니다.
          <br />
          <span>②</span> 서비스의 이용은 연중무휴 1 일 24 시간 가능한 것을
          원칙으로 합니다. 단, 회사는 다음 각 호에 해당하는 경우 이용자에 대한
          서비스의 전부 또는 일부를 제한, 변경 및 중지할 수 있습니다.
          <br />
          1. 정전, 설비 장애 및 이용량의 폭주 등으로 인해 서비스의 정상적인
          이용에 장애가 있는 경우
          <br />
          2. 서비스의 정상적인 제공을 위한 설비 점검, 보수 및 개발 등으로 인해
          부득이한 경우
          <br />
          3. 제휴사 또는 회사의 협력업체와의 계약 종료 등과 같이 회사의 서비스
          운영, 업무상 사정 또는 법률상의 장애 등으로 인해 서비스를 유지할 수
          없는 경우
          <br />
          4. 천재지변, 국가 비상 사태 등 기타 불가항력 사유가 있는 경우
          <br />
          <span>③</span> 서비스 제한, 변경 및 중단의 경우에는 회사는
          어플리케이션 화면 등을 통해 사전에 그 사유, 일시, 기간 등을 공지하는
          방법으로 이용자에게 통지합니다. 다만, 운영자의 고의나 과실 없이 발생한
          설비의 장애처럼 회사가 통제할 수 없는 사유로 발생하여 사전 통지가
          불가능한 경우에는 사후에 통지합니다.
          <br />
        </div>
      </div>
      <div>
        <h2 className="title">제7조 (충전 및 재충전)</h2>
        <div className="content">
          <span>①</span> 이용자는 현금 또는 회사가 현금과 동일하게 인정하는
          금전적 가치(계좌이체, 카드 등)를 제공하여 어플리케이션 또는 오프라인
          가맹점 등을 통해 문구야놀자에 직접 저장하는 방법으로 해당 가치를
          충전할 수 있으며, 회사 또는 회사가 지정한 제 3 자는 충전 서비스 제공
          시 경우에 따라 소정의 이용료를 징수할 수 있습니다.
          <br />
          <span>②</span> 문구야놀자의 가맹점에 따라 충전 서비스가 제한되거나
          또는 제휴 서비스 종류에 따라 충전 기능이 제한될 수 있습니다.
          <br />
          <span>③</span> 이용자의 과실로 인하여 문구야놀자를 재충전하는 경우
          이에 따르는 비용은 실비의 범위 내에서 이용자가 부담합니다.
          <br />
          <span>④</span> 회사는 이용자에게 다음 각 호에 해당하는 방법으로
          자동재충전 서비스를 제공할 수 있습니다.
          <br />
          1. 이용자가 지정하는 최저 잔액 이하로 문구야놀자의 잔액이 하락하는
          경우 이용자가 사전에 선택한 결제 수단으로 지정한 금액을 재충전
          <br />
          2. 이용자가 지정하는 특정 일자에 사전에 선택한 결제 수단으로 지정한
          금액을 재충전
          <br />
          (단, 회사의 정책에 의해 문구야놀자 충전 금액 한도가 있는 경우 이에
          의하여 충전을 제한)
          <br />
          <span>⑤</span> 회사는 문구야놀자 충전 금액에 대해서 이자를 지급하지
          않습니다.
          <br />
        </div>
      </div>
      <div>
        <h2 className="title">제8조 (송금)</h2>
        <div className="content">
          <span>①</span> 이용자는 본 약관의 이용한도 범위 내에서 문구야놀자
          잔액의 전부 또는 일부를 제3자(다른 회원이나 비회원)에게 송금할 수
          있습니다.
          <span>②</span> 회원은 문구야놀자 잔액의 송금 시 회사가 요구하는
          상대방(다른 회원 또는 비회원)의 정보를 정확히 입력하여야 하며, 해당
          정보를 잘못 입력함으로 인한 문제에 대하여 회사는 책임을 지지 않습니다.
          다만, 제16조의2 착오송금 반환절차를 이용할 수 있습니다.
          <span>③</span> 회사는 문구야놀자 잔액의 송금 시 상대방(문구야놀자의
          잔액을 송금받는 자)이 송금인의 성명을 확인할 수 있도록 할 수 있습니다.
          <span>④</span> 회원은 문구야놀자 잔액을 회사가 제시하는 가맹점에서
          해당 가맹점과의 계약에 따라 허용된 경우, 해당 상품 또는 서비스 구매
          등에 관한 지급 및 결제 수단으로 사용할 수 있습니다. 회사는 문구야놀자
          잔액을 사용하여 결제를 할 수 있는 가맹점의 정보에 관하여 본 약관
          제6조에 정한 방법으로 회원에게 알립니다.
        </div>
      </div>
      <div>
        <h2 className="title">제9조 (추심이체의 동의 및 철회)</h2>
        <div className="content">
          <span>①</span> 이용자가 추심이체의 동의를 함에 있어서는 회사가
          제공하는 방식과 요건에 따른 전자서면으로 동의를 제공하여야 합니다.
          <span>②</span> 회사는 전자금융감독규정에서 정한 요건에 부합하는 방식과
          요건의 전자서면을 통한 동의 방식을 제공하며, 추심이체의 실행을 위하여
          이용자로부터 수령한 동의 사항을 금융결제원 및 해당 금융기관 등에게
          제출합니다.
          <span>③</span> 이용자는 회사의 거래지시에 따라 이용자의 계좌 원장에
          출금기록이 기입되기 전까지 회사에 대하여 전항의 규정에 따른 동의의
          철회를 요청할 수 있습니다. 이용자는 어플리케이션을 통하여 회사에
          등록된 계좌의 등록의 말소를 하는 방법으로 출금이체 동의를 철회할 수
          있습니다. 다만, 이용자는 동 철회의 의사표시 이전에 발생한 출금건에
          대하여 이의를 제기할 수 없습니다.
          <span>④</span> 만 14세 미만의 자는 추심이체 출금동의를 이용할 수
          없습니다.
        </div>
      </div>
      <div>
        <h2 className="title">제10조 (환급, 유효기간 등)</h2>
        <div className="content">
          <span>①</span> 회사는 사전에 문구야놀자에 대하여 다음 각 호와 같이
          유효기간을 설정할 수 있으며, 이용자는 회사에서 정한 유효기간 내에서만
          문구야놀자를 사용할 수 있습니다.
          <br />
          1. 전자식카드의 경우
          <br />
          가. 발행일로부터 5년
          <br />
          <span>②</span> 이용자는 다음 각 호와 같이 유효기간 연장을 처리합니다.
          <br />
          1. 전자식카드의 경우
          <br />
          가. 유효기간 내에는 회사에게 유효기간의 연장을 신청할 수 없고,
          유효기간 만료 전 회사의 정책에 따라 이용자에게 만료 예정사실을
          통보하여 갱신 의사를 확인한 후 이용자의 요청에 따라 카드를 재발급할 수
          있습니다.
          <br />
          <span>③</span> 회사는 유효기간이 도래하기 7 일전 통지를 포함하여 3 회
          이상 이용자에게 유효기간의 도래, 카드 구매 및 잔액 이전 방법 등을
          어플리케이션 등을 통하여 통지합니다.
          <br />
          <span>④</span> 회사는 다음 각 호의 경우 수수료를 공제하지 않고
          문구야놀자의 잔액 전부를 환급합니다. 다만, 회사는 다음 각호에 해당하지
          않고 이용자의 단순 변심에 의한 경우 및 잔액 일부에 대한 환급을
          요청하는 경우에는 환급을 거절하거나 수수료를 공제한 후 환급할 수
          있습니다.
          <br />
          1. 문구야놀자의 충전∙구매일로부터 7일 이내에 충전∙구매액 전부를 환불을
          요청하는 경우
          <br />
          2. 천재지변 등의 사유로 가맹점이 재화 또는 용역을 제공하기가 곤란하여
          문구야놀자의 사용이 불가능한 경우
          <br />
          3. 문구야놀자의 결함으로 인해 가맹점이 재화 또는 용역을 제공하지
          못하게 된 경우
          <br />
          4. 이용자가 정상적으로 구매하거나 충전한 문구야놀자 잔액이 구매시점
          또는 최종 충전시점의 잔액을 기준으로 60% 이상을 재화나 용역의 구매에
          사용한 후 잔액의 환불을 요청하는 경우
          <br />
          <span>⑤</span> 교통카드의 충전금은 문구야놀자의 충전금으로 전액
          이전하여 환불을 받을 수 있습니다. 교통카드의 충전금을 제휴사에서 직접
          환불받는 경우, 환불 조건 및 방법은 제휴사 약관에 따릅니다.
          <br />
          <span>⑥</span> 설비 점검, 통신회선 불량, 안정화 및 보수 작업 등
          기술상, 설비상 문제가 발생하거나 현금 융통 목적의 환불이라고 판단되는
          경우 등에는 환불이 제한될 수 있으며, 이 경우 회사는 별도의 환불 절차
          및 방법을 어플리케이션 화면 또는 고객센터 등을 통해 이용자에게
          고지합니다.
          <br />
          <span>⑦</span> 이벤트 등을 통하여 무상 제공받은 문구야놀자는 환급
          대상에서 제외되며, 본 조 제 4 항 제 4 호의 사용액 산정에 포함되지
          않습니다. 또한 회사가 이용자에게 본 조의 문구야놀자 잔액을 환급하는
          경우 무상 제공받은 문구야놀자 잔액은 유효기간이 만료된 경우
          소멸합니다.
          <br />
          <span>⑧</span> 유효기간이 경과하였으나 소멸시효가 완성되지 않은
          문구야놀자의 경우 이용자는 회사에 문구야놀자 잔액의 90%에 대해서
          반환을 요청할 수 있습니다.
          <br />
          <span>⑨</span> 이용자가 본인의 환불 신청 계좌를 잘못 지정하여 발생하는
          손해에 대하여 회사는 책임지지 않습니다.
          <br />
          <span>⑩</span> 문구야놀자의 유효기간이 만료되거나 이용자가 문구야놀자
          삭제 또는 서비스 이용계약 해지를 한 경우 이전의 사용내역 취소 및 환불
          등에 관한 처리는 불가합니다. 단, 회사는 카드 삭제 또는 서비스 이용계약
          해지 이전에 해당 사실을 이용자에게 고지합니다.
          <br />
          <span>⑪</span> 이용자가 문구야놀자를 구매한 날 또는 충전한 날로부터
          5년이 경과하면 해당 구매 및 충전 금액은 소멸시효가 완성되어 이용자는
          회사에게 문구야놀자 환불, 잔액반환 및 재화 또는 용역의 제공을 요청할
          수 없습니다.
          <br />
        </div>
      </div>
      <div>
        <h2 className="title">제11조 (서비스 제한, 정지 및 계약 해지)</h2>
        <div className="content">
          <span>①</span> 회사는 이용자가 다음 각 호에 해당하는 경우 이용자의
          서비스 이용을 제한하거나 정지할 수 있으며 이용자와의 서비스 이용계약을
          전자우편, 휴대전화 문자메시지 전송 등을 통하여 사전통지 후 직권으로
          해지할 수 있습니다. 다만, 긴급한 경우 직권으로 해지 후 사후 통지할 수
          있습니다.
          <br />
          1. 타인의 명의 등 개인정보를 이용하여 서비스를 이용하거나 타인의
          서비스 이용을 방해하는 경우
          <br />
          2. 현금 융통 등 비정상적인 목적을 갖고 서비스를 이용하는 경우
          <br />
          3. 이 약관에서 규정하는 이용자의 의무를 위반하여 회사의 시정요구 또는
          제재를 받고도 위반 행위를 중단하지 않은 경우
          <br />
          4. 이용자가 회사가 인정하지 아니하는 방법으로 문구야놀자를 취득 또는
          사용하는 경우
          <br />
          <span>②</span> 이용자는 회사가 정한 해지 절차에 따라 서비스 계약의
          해지를 신청할 수 있습니다. 단, 서비스 계약 해지 시 이용자가 무상으로
          보유한 혜택(리워드, 쿠폰 등)은 회사의 정책에 따라 소멸됩니다. 이용자가
          유상으로 보유한 혜택(리워드, 쿠폰 등)은 환불처리 후 계약 해지가
          가능합니다.
          <br />
          <span>③</span> 서비스 계약을 해지한 회원이 재가입을 희망하는 경우,
          회사의 정책에 따라 일정기간 동안 재가입이 불가능할 수 있으며, 서비스
          계약 해지 전에 보유한 혜택(리워드, 쿠폰 등)은 새로운 회원 계정에
          연결되지 않습니다.
          <br />
          <span>④</span> 회원이 부정한 목적 또는 비정상적인 목적을 갖고 탈퇴와
          재가입을 반복하는 경우, 회사의 정책에 따라 회원 재가입이 불가능할 수
          있습니다.
          <br />
        </div>
      </div>
      <div>
        <h2 className="title">제12조 (부가 서비스의 이용 및 해지)</h2>
        <div className="content">
          <span>①</span> 부가 서비스의 세부 내용, 가입 절차, 이용 요금 및 조건
          등은 어플리케이션 화면의 각 부가 서비스 별 안내에 의합니다.
          <br />
          <span>②</span> 각 부가 서비스의 개시 시점과 이용 가능 시점 등은 다를
          수 있으며, 회사는 어플리케이션 화면 등에 공지 또는 각 부가 서비스 별
          안내를 통해 이용자에게 서비스 이용 가능 시점 등을 알립니다.
          <br />
          <span>③</span> 각 부가 서비스 별 이용 요금은 원가 상승 및 기타 이유로
          변경될 수 있으며, 회사는 어플리케이션 화면 등을 통한 공지 또는 각 부가
          서비스 별 안내를 통해 이용자에게 변경 사항을 알립니다.
          <br />
          <span>④</span> 이용자는 회사가 정한 해지 절차에 따라 개별 부가
          서비스의 해지를 신청할 수 있으며 회사는 특별한 사정이 없는 한,
          이용자의 서비스 해지 신청을 즉시 처리합니다.
          <br />
        </div>
      </div>
      <div>
        <h2 className="title">제13조 (회사의 의무)</h2>
        <div className="content">
          <span>①</span> 회사는 특별한 사정이 없는 한 이용자가 신청한 서비스를
          이 약관이 정한 바에 의하여 계속적이고 안정적으로 제공할 의무가
          있습니다.
          <br />
          <span>②</span> 회사는 약관의 규제에 관한 법, 전자상거래 등에서의
          소비자보호에 관한 법, 전자금융거래법, 정보통신망 이용촉진 및 정보보호
          등에 관한 법, 신용정보의 이용 및 보호에 관한 법, 통신비밀보호법,
          전기통신사업법 등 관계 법령을 준수합니다.
          <br />
          <span>③</span> 회사가 이용자에 대하여 통지할 사항이 있는 경우에는 유선
          또는 전자우편 전송 등의 방식을 통해 개별 통지할 수 있습니다. 다만,
          회사는 불특정 다수 이용자에 대한 통지의 경우 어플리케이션 화면 등에
          게시함으로써 개별 통지에 갈음할 수 있습니다.
          <br />
          <span>④</span> 회사는 이용자로부터 문구야놀자 또는 모바일 기기 등
          접근매체의 분실이나 도난 사실이 접수된 이후에 제 3 자가 그 접근매체를
          사용함으로 인하여 이용자에게 발생한 손해를 배상할 책임이 있습니다.
          <br />
          <span>⑤</span> 회사는 접근매체의 위조나 변조로 발생한 사고, 계약 체결
          또는 거래지시의 전자적 전송이나 처리 과정에서 발생한 사고,
          전자금융거래를 위한 전자적 장치 또는 ‘정보통신망 이용촉진 및 정보보호
          등에 관한 법률’ 제 2 조 제 1 항 제 1 호에 따른 정보통신망에 침입하여
          거짓이나 그 밖의 부정한 방법으로 획득한 접근매체의 이용으로 발생한
          사고로 인하여 이용자에게 손해가 발생하는 경우 발생한 손해를 배상할
          책임을 집니다.
          <br />
          <span>⑥</span> 회사는 제 5 항에도 불구하고 다음 각 호의 어느 하나에
          해당하는 사유로 인하여 이용자에게 발생한 손해에 대하여는 이용자가 그
          책임의 전부 또는 일부를 부담하게 할 수 있습니다.
          <br />
          1. 이용자가 접근매체를 제3자에게 대여하거나 사용을 위임하거나 양도
          또는 담보 목적으로 제공한 경우
          <br />
          2. 제3자가 권한 없이 이용자의 접근매체를 이용하여 전자금융거래를 할 수
          있음을 알았거나 쉽게 알 수 있었음에도 불구하고 이용자가 자신의
          접근매체를 누설 또는 노출하거나 방치한 경우
          <br />
          3. 법인(「중소기업기본법」 제2조 제2항에 따른 소기업을 제외합니다)인
          이용자에게 손해가 발생한 경우로 회사가 사고를 방지하기 위하여
          보안절차를 수립하고 이를 철저히 준수하는 등 합리적으로 요구되는 주의
          의무를 다한 경우
          <br />
          4. 회사가 전자금융거래법 제6조 제1항에 따른 확인 외에 보안강화를
          위하여 전자금융거래 시 추가적인 보안조치를 요구하였으나 이용자가
          회사의 요구를 정당한 사유 없이 거부하여 전자금융거래법 제9조 제1항
          제3호의 사고가 발생한 경우
          <br />
          5. 이용자가 제5호에 따른 추가적인 보안조치에 사용되는 매체, 수단 또는
          정보에 대하여 다음 각 목의 어느 하나에 해당하는 행위를 하여
          전자금융거래법 제9조 제1항 제3호에 따른 사고가 발생한 경우
          <br />
          가. 제 3 자에게 누설, 노출하거나 방치한 행위
          <br />
          나. 제 3 자에게 대여하거나 그 사용을 위임한 행위
          <br />
          다. 제 3 자에게 양도하거나 담보의 목적으로 제공한 행위
          <br />
          <span>⑦</span> 회사는 제 5 항 내지 제 6 항에도 불구하고 이용자에게
          유리하게 적용될 수 있는 다른 법령이 있는 경우 그 법령을 우선하여
          적용합니다.
          <br />
        </div>
      </div>
      <div>
        <h2 className="title">제14조 (이용자의 의무)</h2>
        <div className="content">
          <span>①</span> 이용자는 서비스 이용을 위해 필요한 제반 정보를
          제공하거나 등록할 경우 사실과 일치하는 정보를 제공하거나 등록하여야
          합니다.
          <br />
          <span>②</span> 이용자는 서비스 이용을 위해 등록한 계정, 비밀번호 관리
          의무를 부담합니다.
          <br />
          <span>③</span> 이용자는 서비스 이용 시 다음 각 호에 해당하는 행위를
          하여서는 아니 됩니다.
          <br />
          1. 서비스 이용 신청 또는 변경 시 허위 사실을 기재하는 행위
          <br />
          2. 타인의 개인정보 등을 이용하여 타인으로 가장하는 행위 및 타인과의
          관계를 허위로 명시하는 행위
          <br />
          3. 타인의 명예를 손상시키거나 불이익을 주는 행위
          <br />
          4. 법령, 공공질서 및 미풍양속에 위반되는 내용의 정보 등을 타인에게
          유포하는 행위
          <br />
          5. 자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할
          목적으로 허위의 정보를 유통시키는 행위
          <br />
          6. 서비스와 관련된 설비의 오작동이나 정보 파괴 및 시스템 혼란 등을
          유발시키는 컴퓨터 바이러스 감염 자료를 등록하거나 유포하는 행위
          <br />
          7. 서비스의 이용과 관련하여 취득한 타인의 개인정보를 무단으로
          유용하거나 유출하는 행위
          <br />
          8. 기타 법령에 위배되거나 부당한 행위
          <br />
          <span>④</span> 이용자는 관계 법령, 이 약관의 규정, 이용자가 회사와
          맺은 여타 약관의 규정, 어플리케이션 화면 등에 공지한 서비스 이용 안내
          및 주의사항, 회사가 통지하는 기타 사항 등을 준수하여야 하며, 회사의
          업무에 방해되는 행위를 하여서는 아니 됩니다.
          <br />
          <span>⑤</span> 이용자는 서비스를 이용하여 영업활동을 할 수 없으며,
          이로 인하여 발생하는 이용자의 불이익에 대해서도 회사는 책임지지
          않습니다. 만약 이로 인하여 제 3 자에게 불이익이 발생할 경우 이용자가
          책임질 수 있습니다.
          <br />
          <span>⑥</span> 이용자는 회사가 서비스를 안전하게 제공할 수 있도록
          회사에 협조하여야 하며, 회사가
          <br />
          이용자의 본 약관 위반행위를 발견하여 이용자에게 해당 위반행위에 대하여
          소명을 요청할 경우 이용자는 회사의 요청에 적극 응하여야 합니다.
          <br />
          <span>⑦</span> 이용자가 서비스를 이용함에 있어 행한 불법행위나 이 약관
          위반 행위로 인하여 회사가 제 3 자로부터 손해배상 청구, 보전처분 신청,
          민원 제기 등 각종 불이익을 받거나 손해가 발생하게 되는 경우 당해
          이용자는 그로 인하여 회사에 발생한 손해에 대하여 배상 의무를 부담할 수
          있습니다.
          <br />
        </div>
      </div>

      <div>
        <h2 className="title">제15조 (정보의 제공 및 광고의 게재)</h2>
        <div className="content">
          <span>①</span> 회사는 서비스를 운영함에 있어 회사의 서비스 관련 각종
          정보 또는 회사 및 제휴사의 광고를 어플리케이션 화면 등 서비스 화면에
          게재할 수 있습니다. 회사는 광고성 정보 및 회원 맞춤형 정보를 문자
          메세지나 PUSH 형식으로 제공할 수 있으며, 이는 광고성 정보에 대한 사전
          수신 동의가 있는 경우 등 법령상 허용된 경우에 한하여 제공됩니다.
          <br />
          <span>②</span> 이용자가 서비스 화면에 게재되어 있는 광고를 이용하거나
          서비스를 통해 판촉 활동에 참여하는 등의 방법으로 광고주와 의사소통을
          하거나 거래하는 것은 이용자와 광고주 간에 발생하는 법률관계이며
          회사와는 관련이 없습니다. 만약 이용자와 광고주 간에 문제가 발생할 경우
          이용자와 광고주가 직접 해결하여야 하며, 이와 관련하여 회사는 책임을
          지지 않습니다.
          <br />
        </div>
      </div>
      <div>
        <h2 className="title">제16조 (거래내역 정보의 수집 및 정정)</h2>
        <div className="content">
          <span>①</span> 회사는 관계 법령 또는 이용자의 동의에 따라 이용자가
          문구야놀자를 사용하여 재화 구입 및 용역 이용 시 문구야놀자 카드번호,
          거래 일시, 거래 금액, 단말기 및 가맹점 정보 등 이용자와 가맹점 간
          이용대금의 정산, 확인, 오류정정에 필요한 최소한의 거래 내역 정보를
          수집하고 보존할 수 있습니다.
          <br />
          <span>②</span> 이용자는 어플리케이션 화면 등에서 “내역 조회 서비스”
          등을 통해 문구야놀자 거래내역을 확인할 수 있으며, 비회원의 경우
          거래내역 확인이 제한될 수 있습니다. 무기명 문구야놀자 이용자 또는
          비회원이 거래내용을 확인하고자 할 경우 회사는 고객의 본인확인 절차와
          별도의 양식을 갖추어 요청하도록 요구할 수 있습니다.
          <br />
          <span>③</span> 이용자가 본인이 확인한 거래내역에 오류를 발견한 경우 이
          약관 제 20 조 제 1 항에 기재된 연락처를 통해 정정을 요청할 수
          있습니다.
          <br />
          <span>④</span> 회사는 이용자로부터 거래내역 오류 정정을 요청 받은
          날로부터 2 주 이내에 거래내역을 검토하여 이용자에게 그 결과를
          통보합니다.
          <br />
        </div>
      </div>
      <div>
        <h2 className="title">제16조의2 (착오송금에 관한 사항)</h2>
        <div className="content">
          <span>①</span> 회원이 착오로 수취금융회사, 수취계좌번호 등을 잘못
          기재하거나 입력하여 수취인에게 선불전자지급수단 등의 자금이 이동(이하
          ‘착오송금’이라 합니다.)된 경우, 회사에 통지하여 회사 또는 수취
          금융회사 등을 통해 수취인에게 연락하여 착오 송금액 반환을 요청할 수
          있습니다.
          <br />
          <span>②</span> 회사는 수취인에 대한 연락 사실, 수취인의 반환의사 유무,
          수취인이 반환의사가 없는 경우 그 사유 등 회원 요청사항에 대한 처리결과
          또는 관련 처리 진행상황을 회원이 전항의 ‘착오송금’ 발생사실을 회사에
          통지한 날로부터 15일 이내에 회원에게 알려야 합니다.
          <br />
          <span>③</span> 회사 또는 수취 금융회사를 통한 착오송금 반환 요청에도
          수취인이 반환하지 않는 경우, 회원은 예금자보호법 제5장(착오송금
          반환지원)에 따라 예금보험공사에 착오송금 반환지원 제도 이용을 신청할
          수 있습니다. (개정 예금자보호법 시행일인 2021년 7월 6일 이후 발생한
          착오송금에 대해 신청 가능) 단, 연락처를 통한 송금, SNS회원 간 송금
          거래 등 예금보험공사가 수취인의 실지명의를 취득할 수 없는 거래는
          반환지원 신청이 제한됩니다.
          <br />
          <span>④</span> 회사는 예금보험공사가 착오송금 반환지원 업무의 원활한
          수행을 위해 회사에 착오송금 수취인의 반환불가 사유, 실지명의, 주소 및
          연락처, 착오송금 발생 현황 등의 자료를 요청하는 경우 정당한 사유가
          없으면 이에 따라야 합니다.
          <br />
          <span>⑤</span> 회원이 예금보험공사를 통해 착오송금 반환지원을 신청한
          내역이 다음 각 호에 해당하는 경우 관련 법령에 따라 예금보험공사의 지원
          절차가 중단될 수 있습니다.
          <br />
          1. 회원이 거짓이나 부정한 방법으로 반환지원을 신청한 경우
          <br />
          2. 착오송금이 아님이 객관적인 자료로 확인되는 경우
          <br />
          3. 신청일 이전 반환지원을 신청한 착오송금과 관련된 소송 등이 진행
          중이거나 완료된 경우
          <br />
          4. 그 밖에 예금보험위원회가 인정하는 경우
          <br />
        </div>
      </div>
      <div>
        <h2 className="title">제 17조 (대리 및 보증의 부인)</h2>
        <div className="content">
          <span>①</span> 회원과 제3자와의 거래에 있어서 회사는 회원이나 제3자를
          대리하지 않습니다. 회사가 관련 서비스의 개별 이용약관 등을 통하여 달리
          명시하지 아니하는 한, 회사의 어떠한 행위도 제3자나 회원을 대리하는
          행위로 간주되지 않습니다.
          <br />
          <span>②</span> 회사가 문구야놀자를 통하여 제3자의 서비스를 이용할 수
          있도록 하는 경우, 해당 서비스 제공의 주체는 그 제3자이며, 회사는 그
          제3자가 제공하는 정보를 회원에게 전달되도록 하는 도구를 제공합니다.
          제3자가 제공하는 서비스는 문구야놀자를 통하여 관련 추가 약관 등에 대한
          동의절차를 거친 회원에 한하여 이용할 수 있습니다.
          <br />
          <span>③</span> 회사는 서비스를 통하여 교환되는 회원과 제3자간의 정보와
          관련하여 회원 또는 제3자의 거래의사의 존부 및 진정성, 제3자가 제공하는
          상품의 품질, 완전성, 안전성, 적법성, 정보의 진실성, 타인의 권리에 대한
          비침해성, 제3자와 회원이 제공하는 정보의 진실성 또는 적법성에 대하여
          보증하지 아니합니다.
          <br />
        </div>
      </div>
      <div>
        <h2 className="title">제18조 (이용자의 개인정보 보호 및 취급)</h2>
        <div className="content">
          <span>①</span> 이용자의 개인정보 수집, 이용, 제공 등에 관한 제반
          사항과 관련하여 회사는 개인정보보호법, 전자금융거래법, 정보통신망
          이용촉진 및 정보보호 등에 관한 법, 신용정보의 이용 및 보호에 관한 법
          등 관계 법령이 정한 사항을 준수하며 이용자의 개인정보 또는 신용정보를
          보호하기 위해 노력합니다.
          <br />
          <span>②</span> 회사는 이용자의 요구 및 편의에 최적화된 서비스를
          제공하고, 신상품이나 이벤트 정보안내, 설문조사 등 이용자의 편익을 위한
          마케팅을 수행하기 위해 서비스 이용계약 체결 시 이용자의 동의를 얻어
          수집한 이용자의 개인정보 및 서비스 이용과 관련한 정보를 활용할 수
          있습니다. 단, 이용자의 동의 범위를 초과하여 정보를 이용하거나 제 3
          자에게 제공하고자 하는 경우에는 해당 이용자로부터 사전 동의를 받아야
          하며 이 경우 이용자는 회사의 동의 요청을 거절할 수 있습니다.
          <br />
          <span>③</span> 회사는 이용자의 귀책 사유로 인해 외부에 노출된 정보에
          대해서 책임을 지지 않습니다.
          <br />
          <span>④</span> 회사는 양질의 서비스를 제공하기 위하여 필요한 경우
          개인정보보호법 등 관계 법령에 따라 회원의 동의를 받고 회원의
          개인정보를 제휴사 등에게 제공하거나 업무 대행 업체에 위탁 할 수
          있습니다.
          <br />
        </div>
      </div>
      <div>
          <h2 className="title">제19조 (지적재산권의 보호)</h2>
          <div className="content">
            <span>①</span> 이용자에게 제공하는 어플리케이션 화면에 게시된 문구,
            디자인 및 서비스 내용 등에 관한 저작권, 특허권 등 일체의
            지적재산권은 회사 및 제휴사 등 협력업체에게 소유권이 있습니다.
            <br />
            <span>②</span> 이용자는 회사의 사전 동의 없이 서비스를 이용하여 얻은
            정보를 출판, 방송, 배포, 복제 기타 방법을 통해 영리목적으로 이용할
            수 없으며 제 3 자에게 제공하거나 이용하게 할 수 없습니다.
            <br />
            <span>③</span> 이용자는 회사 또는 제휴사 등 협력업체의 지적재산권을
            침해함으로써 발생하는 손해를 배상할 수 있습니다.
            <br />
          </div>
        </div>
        <div>
          <h2 className="title">제20조 (면책사항)</h2>
          <div className="content">
            <span>①</span> 회사는 제휴사가 제공하는 서비스를 이유로 제휴사와
            이용자 간에 분쟁이 발생하는 경우, 회사는 이에 개입할 의무가 없으며
            분쟁에 따른 손해배상 책임을 지지 않습니다. 또한, 회사는 제휴사와
            이용자 간에 상품 등의 거래에 관여하지 않으며, 이에 대하여 책임을
            지지 않습니다.
            <br />
            <span>②</span> 회사에서 제공하는 서비스 및 서비스를 이용하여 얻은
            정보에 대한 최종 판단은 이용자가 직접 하여야 하고, 그에 따른 책임은
            이용자 자신에게 있으며, 회사는 그로 인하여 발생하는 손해에 대해서
            책임을 지지 않습니다.
            <br />
          </div>
        </div>
        <div>
          <h2 className="title">제21조 (분쟁의 처리 및 조정)</h2>
          <div className="content">
            <span>①</span> 이용자는 어플리케이션 화면 등에 게시된 고객센터
            담당자 또는 제20조 제1항에 기재된 연락처를
            <br />
            통하여 서비스와 관련한 의견 및 불만의 제기, 손해배상의 청구 등 분쟁
            처리를 요청할 수 있습니다.
            <br />
            <span>②</span> 이용자가 회사에 분쟁의 처리를 요청한 경우 회사는
            요청을 수령한 날로부터 15일 이내에 이에 대한조사 또는 처리 결과를
            이용자에게 안내합니다.
            <br />
            <span>③</span> 이용자는 회사의 분쟁 처리 결과에 이의가 있으면
            금융위원회의 설치 등에 관한 법률 제51조의 규정에 따른 금융감독원의
            금융분쟁조정위원회나 소비자기본법 제31조 제1항의 규정에 따른
            한국소비자원의 소비자분쟁조정위원회에 회사의 서비스 이용과 관련한
            분쟁조정을 신청할 수 있습니다.
            <br />
          </div>
        </div>
        <div>
          <h2 className="title">제22조 (민원의 해결 및 관할법원)</h2>
          <div className="content">
            <span>①</span> 이용자는 서비스 이용과 관련하여 정당한 의견 또는 요구
            사항이 있는 경우(분실신고 접수, 환불 문의, 고장카드 접수 등) 회사의
            아래 연락처를 통해 의견을 제시할 수 있습니다.
            <br />
            1. 상호: 주식회사 문구야놀자
            <br />
            2. 주소: 서울특별시 광진구 능동로 239 지온270빌딩 1동 2층
            <br />
            3. 전화번호: 02-6356-1500
            <br />
            4. 전자우편: gold2020@palymoongu.com
            <br />
            <span>②</span> 회사는 서비스와 관련한 이용자의 불만사항이 접수되는
            경우 이를 신속하게 처리하며, 신속한 처리가 곤란한 경우 그 사유와
            처리 일정을 이용자에게 통지합니다.
            <br />
            <span>③</span> 서비스 이용과 관련하여 회사와 이용자 사이에 분쟁이
            발생한 경우, 회사와 이용자는 분쟁의 해결을 위해 성실히 협의합니다.
            다만, 협의에도 불구하고 분쟁이 해결되지 않을 경우 양 당사자는
            민사소송법상 관할 법원에 소를 제기할 수 있습니다.
            <br />
            부칙
          </div>
          <div className="content">제 1 조 (시행일)</div>
          <div className="content">
            이 약관은 2022년 3월 21일부터 시행합니다.
          </div>
        </div>
    </>
  );
}

export default memo(TermsConditionsRegisterComponent);
