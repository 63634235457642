import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from '../../utils/Apis';

import { Types } from '../redux';

// worker Saga: will be fired on GET_SMS_CODE actions
function* getSmsCode(action) {
  try {
    /**
     * Example data
     * url: enpoint/register/send-confirm-code
     * params:
     *  {
     *    phone: '123445566666'
     *  }
     *
     */
    const response = yield call(() =>
      API.get(ROUTES.API_GET_SMS, { phone: action.phone })
    );
    const data = response?.data;
    if (response.ok) {
      // In case: get sms code request success
      yield put({ type: Types.GET_SMS_CODE_SUCCESS, data });
    } else {
      // In case: get sms code request failed
      yield put({
        type: Types.GET_SMS_CODE_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.GET_SMS_CODE_FAILED, error });
  }
}

/*
  Starts signupAccount on each dispatched `SIGN_IN` action.
*/
function* getSmsCodeSaga() {
  yield takeLatest(Types.GET_SMS_CODE, getSmsCode);
}

export default getSmsCodeSaga;
