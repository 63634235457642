// import libs
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { checkStatusCodeApi } from 'utils/Helpers';

// Define action creators
export const { Types, Creators } = createActions({
  getListFindStore: ['storeName'],
  getListFindStoreSuccess: null,
  getListFindStoreFailed: null,
  getListAllStore: ['storeName'],
  getListAllStoreSuccess: null,
  removeStoreLikeById: ['storeId'],
  removeStoreLikeByIdSuccess: null
});

// Initial state
export const INITIAL_STATE = Immutable({
  isProcessingAllStore: false,
  isProcessingFindStore: false,
  errors: '',
  listStoreLikeOfUser: [],
  listLocationStore: [],
  listAllStore: [],
  isProcessingRemove: false,
});

const getListFindStore = (state, action) => {
  return state.merge({
    isProcessingFindStore: true,
    type: action.type,
  });
};


const getListFindStoreSuccess = (state, action) => {
  const { data } = action;
  const store_list =
    data &&
    data.store_list.map((item) => ({
      lat: item.lat && parseFloat(item.lat),
      lng: item.lon && parseFloat(item.lon),
      id: item.id,
      name: item.name,
      address: item.address,
    }));

  return state.merge({
    isProcessingFindStore: false,
    type: action.type,
    listStoreLikeOfUser: data?.favorites_list || [],
    listLocationStore: store_list || [],
  });
};

const getListFindStoreFailed = (state, action) => {
  console.log(action, 'action');
  // checkStatusCodeApi
  return state.merge({
    isProcessingFindStore: false,
    type: action.type,
  });
};

const getListAllStore = (state, action) => {
  return state.merge({
    isProcessingAllStore: true,
    type: action.type,
  });
};

const getListAllStoreSuccess = (state, action) => {
  const { data } = action;
  const store_list_all =
    data &&
    data.store_list.map((item) => ({
      lat: item.lat && parseFloat(item.lat),
      lng: item.lon && parseFloat(item.lon),
      id: item.id,
      name: item.name,
      address: item.address,
    }));

  return state.merge({
    ...state,
    isProcessingAllStore: false,
    type: action.type,
    listAllStore: store_list_all || [],
    listStoreLikeOfUser: data?.favorites_list || [],
    listLocationStore: store_list_all || [],
  });
};

const removeStoreLikeById = (state, action) => {
  return state.merge({
    isProcessingRemove: true,
    type: action.type,
  });
}

const removeStoreLikeByIdSuccess = (state, action) => {
  return state.merge({
    isProcessingRemove: false,
    type: action.type,
  });
}

// Assign handler to types.
const HANDLERS = {
  [Types.GET_LIST_FIND_STORE]: getListFindStore,
  [Types.GET_LIST_FIND_STORE_SUCCESS]: getListFindStoreSuccess,
  [Types.GET_LIST_FIND_STORE_FAILED]: getListFindStoreFailed,

  [Types.GET_LIST_ALL_STORE]: getListAllStore,
  [Types.GET_LIST_ALL_STORE_SUCCESS]: getListAllStoreSuccess,

  [Types.REMOVE_STORE_LIKE_BY_ID]: removeStoreLikeById,
  [Types.REMOVE_STORE_LIKE_BY_ID_SUCCESS]: removeStoreLikeByIdSuccess,

};

// Create reducers by pass state and handlers
export const searchReducer = createReducer(INITIAL_STATE, HANDLERS);
