/* eslint-disable camelcase */
import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from 'utils/Apis';

import { Types } from '../redux';

// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* getListStore() {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() => API.get(ROUTES.API_GET_LIST_STORE));

    if (response.ok) {
      const { data } = response;
      // In case: signup request success
      yield put({ type: Types.GET_LIST_STORE_SUCCESS, data });
    } else {
      yield put({
        type: Types.GET_LIST_STORE_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.GET_LIST_STORE_FAILED, error });
  }
}

/*
  Starts getListStore on each dispatched `GET_LIST_STORE` action.
*/
function* getListStoreSaga() {
  yield takeLatest(Types.GET_LIST_STORE, getListStore);
}

export default getListStoreSaga;
