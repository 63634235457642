// @flow
// libs
import React, {useEffect, useState} from 'react';

import {Dropdown, DropdownButton} from "react-bootstrap";
import images from "../../themes/images";


type Props = {
    handleClick: Function,
    isDisabled?: any,
    customClass?: string,
    orderBy: String
};

const DropDownCommon = ({
                    isDisabled = false,
                    handleClick,
                    customClass = '',
                    errorMes = '',
                    dataList = [],
                    idActive = '',
                    nameDisplay = '',
                    keyId='id',
                    keyName='name',
                    orderBy,
                    textIsDisabled = ''
                }: Props) => {

    const [nameShow, setNameShow] = useState('');

    useEffect(() => {
        if(idActive){
            let nameActive = dataList.length > 0 && dataList.filter(item =>item[keyId] == idActive)
            if(nameActive && nameActive.length > 0) {
                setNameShow(nameActive[0][keyName])
            }
        }
    }, [idActive,dataList]);

    useEffect(() => {
        setNameShow(nameDisplay)
    }, [nameDisplay]);

    const _handleClick = (data) => {

        if(typeof handleClick == 'function') {
            setNameShow(data[keyName])
            handleClick(data)
        }
    }

    return (
        <div className={'input-group select-type dropdown-common '+ customClass + (errorMes ? ' error-show' : '')}>

            <DropdownButton id="dropdown-item-button" title={`${nameShow}`}>
                {(orderBy && orderBy == 'DESC' ? dataList.slice(0).reverse() : dataList).map((item,index) => (
                    <Dropdown.Item as="button" disabled={item?.isDisabled || false} className={idActive == item[keyId] ? 'active' : ''} key={index} onClick={()=>_handleClick(item)}>
                        {item[keyName]}{item?.isDisabled ? textIsDisabled : ''}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
            <img className='icon-arrow-down' alt='' src={images.icon_arrow_down_dropdown} />
        </div>
    );
};

DropDownCommon.defaultProps = {
    isDisabled: false,
    customClass: '',
    orderBy: null
};

export default DropDownCommon;
