// import libs
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

// Define action creators
export const { Types, Creators } = createActions({
  getDataLevel: null,
  getDataLevelSuccess: null,
  getDataLevelFailed: null,
});

// Initial state
export const INITIAL_STATE = Immutable({
  isProcessing: false,
  errors: '',
  dataLevel: {
    level: 1,
    percent: 0,
    amount: '0원',
  },
});

const getDataLevel = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

const getDataLevelSuccess = (state, action) => {
  const { data } = action;
  return state.merge({
    isProcessing: false,
    type: action.type,
    dataLevel: {
      level: (data?.level && parseInt(data?.level)) || 1,
      percent: data?.level && parseInt(data?.level) === 5 ? 100 : data?.percent,
      amount:
        (data &&
          data.amount &&
          `${parseInt(data.amount, 10).toLocaleString('en')}원`) ||
        '0원',
    },
  });
};

const getDataLevelFailed = (state, action) => {
  return state.merge({
    isProcessing: false,
    type: action.type,
  });
};

// Assign handler to types.
const HANDLERS = {
  [Types.GET_DATA_LEVEL]: getDataLevel,
  [Types.GET_DATA_LEVEL_SUCCESS]: getDataLevelSuccess,
  [Types.GET_DATA_LEVEL_FAILED]: getDataLevelFailed,
};

// Create reducers by pass state and handlers
export const levelReducer = createReducer(INITIAL_STATE, HANDLERS);
