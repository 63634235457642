// @flow

import React, { useEffect, useState } from 'react';
import MainLayout from 'layout/MainLayout';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import images from 'themes/images';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import ROUTERS from '../../../constants/router';
import useCountDownTime from '../../../customHooks/useCountDownTime';
import useCountdown from '../../../customHooks/useCountdown';
import TimeCounter from '../../../components/TimeCounter/TimeCounter';
import BoxEventCommonComponent from './boxEvent';
import Loading from '../../../components/Loading';
import {Checkbox} from "../../../components/Checkbox";
import {Accordion,Card} from "react-bootstrap";
import {API, ROUTES} from "../../../utils/Apis";
import ModalPopup from "../../../components/Modal";
import {formatTimeToHHmm, nl2br} from "../../../constants/common";
import {NoData} from "../../../components/NoData";


SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);



type Props = {
  isProcessing: boolean,
  dataList:Array,
  userId:number,
  getSortListQa:Function,
  idEventProduct:number,
  keyToggleShowReply:any,
  scrollToQna:Function,
  userInfo:Object,
  updateQuantityQnaAfterDelete:Function,
};

const QaTabComponent = ({
  isProcessing,
  dataList,
  userId,
  getSortListQa,
  idEventProduct,
  keyToggleShowReply,
  scrollToQna,
  userInfo,
  updateQuantityQnaAfterDelete
}: Props) => {
  const history = useHistory();
  const [stateOnlyMe, setStateOnlyMe] = useState(false);
  const [keyAccording, setKeyAccording] = useState(['nothing']);
  const [typeSort, setTypeSort] = useState('all');
  const [filterQa, setFilterQa] = useState({user_id:'all',type:'all'});
  const [isShowModalConfirmDelete, setIsShowModalConfirmDelete] = useState({show:false,data:{}});
  const [statusSubmitDeleteQa, setStatusSubmitDeleteQa] = useState(true);


  const toogleAccordion = (key, firstLoad = false) => {
    console.log(keyAccording,'keyToggleShowReply22222',key)
    if(keyAccording.includes(key)){
      if(keyAccording.length > 1){
        let keyAccordingFormat = [...keyAccording]
        const index = keyAccording.indexOf(key);
        if (index > -1) { // only splice array when item is found
          keyAccordingFormat.splice(index, 1); // 2nd parameter means remove one item only
        }
        setKeyAccording(keyAccordingFormat)
      }else {
        setKeyAccording(['nothing'])
      }
      
    }else {
      console.log(firstLoad,'firstLoad1111',key)
      if(firstLoad){
        setKeyAccording([...keyAccording,key])
      }else {
        setKeyAccording([key])
      }
      
    }
  }

  useEffect(() => {
    // console.log(keyToggleShowReply,'keyToggleShowReply3333',!keyToggleShowReply.includes('key'))
    if(!keyToggleShowReply.includes('key')){
      setKeyAccording(keyToggleShowReply)
      // keyToggleShowReply.map(item =>(
      //   toogleAccordion(item,true)
      // ))
    }else {
      setKeyAccording(['nothing'])
    }
  }, [keyToggleShowReply]);

  useEffect(() => {
    if(dataList.length > 0){
      setTimeout(() => {
        scrollToQna()
      }, 500);
    }
  }, [dataList]);

  const toPageCreate = () => {
    document.body.classList.remove('order-detail-bg');
    document.body.classList.remove('fixed-nav-tab');
    let urlPage = ROUTERS.QA_CREATE+('?idEventProduct='+idEventProduct)
    history.push(urlPage);
  };
  const handChangeCheckOnlyMe = (state) => {
    getSortListQa({...filterQa,user_id:(!state ? userInfo?.uid : 'all')})
    setFilterQa({...filterQa,user_id:(!state ? userInfo?.uid : 'all')})
    setStateOnlyMe(!stateOnlyMe)
  }

  const sortListQa = (type) => {
    getSortListQa({...filterQa,type:type})
    setTypeSort(type)
    setFilterQa({...filterQa,type:type})
  }
  const toEditQaPage = (data) => {
    document.body.classList.remove('order-detail-bg');
    document.body.classList.remove('fixed-nav-tab');
    let urlPage = ROUTERS.QA_DETAIL.replace(':id',data?.id)+('?idEventProduct='+data?.event_product_id)
    history.push(urlPage);
  }
  const closeModalConfirmDeleteQa = () => {
    if(statusSubmitDeleteQa){
      setIsShowModalConfirmDelete({...isShowModalConfirmDelete,show:false})
    }
  }
  const callDeleteQa = () => {
    if(!statusSubmitDeleteQa){
      return false
    }
    setStatusSubmitDeleteQa(false)
    const formData = new FormData();
    let dataEdit = isShowModalConfirmDelete.data
    formData.append('inquiry_id',dataEdit.id)
    document.getElementById(`id-delete-${dataEdit.id}`).classList.add('action-delete')
    document.getElementById(`loading-model-delete`).classList.remove('d-none')
    API.post(ROUTES.API_DELETE_QA, formData)
      .then((res) => {
        console.log(res,'res sb')
        getSortListQa(filterQa)
        setIsShowModalConfirmDelete({data:{},show:false})
        setStatusSubmitDeleteQa(true)
        updateQuantityQnaAfterDelete()
      })
      .catch((e) => {
        setStatusSubmitDeleteQa(true)
      })

  }
  const toDeleteQa = (e,dataEdit) => {
    setIsShowModalConfirmDelete({data:dataEdit,show:true})
  };
  const RenderDataEmpty = () => {
      return <NoData text='문의내역이 없습니다.' />
      // return <div className="data-empty text-center mb-4 mt-4"><label>작성된 상품 Q&A가 없습니다.</label></div>
  }

  const showAnswer = (string) => {
    const firstLine = string.split('\n');
    if (firstLine.length > 0) {
      return firstLine[0]
    }
    return string;
  }

  const htmlItemAnswer = (item,itemCmt,keyCmt) => {
    let contentAnswer = itemCmt.content
    let notForMeClass = ' not-for-me'
    if(item.is_private == 1 && item?.user_id != userInfo?.uid && keyCmt > 0){
      return ''
    }
    if(item.is_private == 1 && item?.user_id != userInfo?.uid){
      contentAnswer = '작성자만 확인 가능합니다.'
    }
    if(item.is_private != 1){
      notForMeClass = ''
    }
    return (
      <div
        key={keyCmt}
        className={`info-question__has-answer ${
          keyAccording.includes(itemCmt.id) ? 'show' : ''
        } ${notForMeClass}`}
        onClick={() => toogleAccordion(itemCmt.id)}
        style={{
          height:
            keyAccording.includes(itemCmt.id) &&
            document.getElementById(`answer-content-${itemCmt.id}`)
              ? document.getElementById(`answer-content-${itemCmt.id}`)
                  .offsetHeight + 50
              : '16px',
        }}
      >
        <label className="info-question__has-answer__icon-first">A.</label>
        <img className="icon-arrow" src={images.icon_arrow_bottom} alt="" />
        <div
          className={`info-question__has-answer__value ${
            keyAccording.includes(itemCmt.id) ? 'show' : ''
          }`}
        >
          <label className="text-truncate">
            {keyAccording.includes(itemCmt.id) ? contentAnswer : showAnswer(contentAnswer)}
          </label>
          <label
            className="text-truncate hidden"
            id={`answer-content-${itemCmt.id}`}
          >
            {contentAnswer}
          </label>
          <span>{formatTimeToHHmm(itemCmt.created_at)}</span>
        </div>
      </div>
    );
  }

  const RenderItemAnswer = (item,index) => {
    return item?.comment.length > 0 && item?.comment.map((itemCmt,keyCmt) => (htmlItemAnswer(item,itemCmt,keyCmt)))
  }

  // console.log(userId,'userId',userInfo)
  return (
    <>
      <div className='qa-group-content'>
        {userInfo?.is_admin != 'Y' ? (<div className='info-qa-create'>
          <img className='info-qa-create__icon' src={images.icon_qna_create} alt=''/>
          <h3 className='info-qa-create__title'>상품 문의 게시판</h3>
          <label className='info-qa-create__description'>문의 게시판을 통해 궁금한점을 남겨주세요.</label>
          <div className='info-qa-create__btn'>
            <button onClick={()=>toPageCreate()}>
              <label>상품 문의하러 가기</label>
            </button>
          </div>

        </div>) : ''}
        <div className='line-under'></div>
        <div className='info-qa-list'>
          <div className='tab-filter' id='tab-filter-qa'>
            <div className='tab-filter__sort'>
              <button className={`btn ${typeSort == 'all' ? 'active' : ''}`} onClick={()=>sortListQa('all')}>전체</button>
              <button className={`btn ${typeSort == 'answer' ? 'active' : ''}`} onClick={()=>sortListQa('answer')}>답변완료</button>
              <button className={`btn ${typeSort == 'unanswer' ? 'active' : ''}`} onClick={()=>sortListQa('unanswer')}>미답변</button>
            </div>

            {userInfo?.is_admin != 'Y' ? (<div className='tab-filter__only-me'>
              {/*<Checkbox*/}
              {/*  label="내 문의 보기"*/}
              {/*  checked={stateOnlyMe}*/}
              {/*  onChange={(e) => handChangeCheckOnlyMe(stateOnlyMe)}*/}
              {/*  name="term"*/}
              {/*/>*/}
              <button className={`btn ${stateOnlyMe ? 'active' : ''}`} onClick={(e)=>handChangeCheckOnlyMe(stateOnlyMe)}>내문의</button>
            </div>) : ''}

          </div>
          <div className='list-qa'>
            {isProcessing ? (
              <div className='show-loading-qna'>
                <div className="spinner-border text-warning" />
              </div>
            ) : (
              ''
            )}
            {dataList.map((item,index)=>(
              <div className='item-qa' key={index} id={`qna-${item.id}`}>
                <div className='info-answer'>
                  <label className='info-answer__state'>{item?.comment_cnt > 0 ? '답변완료': '미답변'}</label>
                  <div className='info-answer__qa'>
                    {item?.user_id == userInfo?.uid ? (<label className='info-answer__qa__only-me'>my</label>) : ''}
                    <label className='info-answer__qa__nick-name'>{item?.user_name ? item?.user_name.slice(0,-2) : ''}**</label>
                    <label className='info-answer__qa__arrow-top'>|</label>
                    <label className='info-answer__qa__time'>{formatTimeToHHmm(item?.created_at)}</label>
                  </div>
                </div>
                <div className={`info-question ${item?.comment_cnt > 0 ? '' : ' mb-3'}`}>
                  <div className='info-question__text'>
                    <span className='icon-question'>Q.</span>
                    <div
                      className={`info-question__text__value ${item.is_private == 1 ? 'is-has-private' : ''}`}
                      dangerouslySetInnerHTML={{
                        __html: item.is_private == 1 && item?.user_id != userInfo?.uid ? '비밀글입니다.' : nl2br(item?.content),
                      }}
                    />
                  </div>
                  {(item?.user_id == userInfo?.uid ? (<div className='info-question__action'>
                    <label className='info-question__action__btn' onClick={()=>toEditQaPage(item)}>수정</label>
                    <label className='info-question__action__arrow'>|</label>
                    <label className='info-question__action__btn' id={`id-delete-${item.id}`} onClick={(e)=>toDeleteQa(e,item)}>삭제</label>
                  </div>) : '')}
                  {item?.comment.length > 0 ?
                    (RenderItemAnswer(item,index)) : ''
                  }


                </div>
              </div>
            ))}
            {dataList.length == 0 ? RenderDataEmpty() : ''}

          </div>
        </div>

        <ModalPopup
          isOpen={isShowModalConfirmDelete.show}
          isShowFooter
          isShowTwoBtn
          handleClose={() =>closeModalConfirmDeleteQa()}
          handleSubmit={() => callDeleteQa()}
          textBtnRight="확인"
          textBtnLeft="취소"
          customClass="modal-login modal-alert-delete-qa"
        >
          <div className="content" id='modal-alert-delete-qa'>
            <div className="modal-content-head">
              <h3 className="modal-content-title">
              </h3>
            </div>
            <div className="modal-content-body">
              <div className='show-loading-qna d-none' id='loading-model-delete'>
                <div className="spinner-border text-warning" />
              </div>
              <label style={{whiteSpace:'nowrap'}}>
                삭제하시겠습니까?
              </label>
            </div>
          </div>
        </ModalPopup>

      </div>
    </>
  );
};

export default QaTabComponent;
