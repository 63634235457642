/* eslint-disable camelcase */
import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from 'utils/Apis';
import { checkStatusCodeApi } from 'utils/Helpers';

import { Types } from '../redux';

// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* deleteProductEnquiry(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() =>
      API.post(ROUTES.API_DELETE_PRODUCT_ENQUIRY, action.data)
    );

    if (response.ok) {
      const { data } = response;
      // In case: signup request success
      yield put({
        type: Types.DELETE_PRODUCT_ENQUIRY_SUCCESS,
        data,
        enquiryId: action?.data?.inquiry_id,
      });
    } else {
      const { data } = response;
      checkStatusCodeApi(data?.code || null)

      yield put({
        type: Types.DELETE_PRODUCT_ENQUIRY_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.DELETE_PRODUCT_ENQUIRY_FAILED, error });
  }
}

/*
  Starts deleteProductEnquiry on each dispatched `DELETE_PRODUCT_ENQUIRY` action.
*/
function* deleteProductEnquirySaga() {
  yield takeLatest(Types.DELETE_PRODUCT_ENQUIRY, deleteProductEnquiry);
}

export default deleteProductEnquirySaga;
