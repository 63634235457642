/* eslint-disable camelcase */
import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from 'utils/Apis';

import { Types } from '../redux';

// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* updateUserInfo(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */

    const response = yield call(() =>
      API.post(ROUTES.UPDATE_USER_INFO, action.userInfo, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    );

    if (response.ok) {
      const { data } = response;
      // In case: signup request success
      yield put({ type: Types.UPDATE_USER_INFO_SUCCESS, data });
    } else {
      yield put({
        type: Types.UPDATE_USER_INFO_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.UPDATE_USER_INFO_FAILED, error });
  }
}

/*
  Starts updateUserInfo on each dispatched `UPDATE_USER_INFO` action.
*/
function* updateUserInfoSaga() {
  yield takeLatest(Types.UPDATE_USER_INFO, updateUserInfo);
}

export default updateUserInfoSaga;
