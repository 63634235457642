// @flow

import MainLayout from 'layout/MainLayout';
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import React, {memo, useEffect, useRef, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import images from 'themes/images';
import { Tab, Tabs } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import TimeCounter from '../../../components/TimeCounter/TimeCounter';
import ROUTERS from '../../../constants/router';
import ModalPopup from '../../../components/Modal';
import { Creators } from '../../mainPage/redux';
import { Creators as CreatorsOrder } from '../redux';
import { API, ROUTES } from '../../../utils/Apis';
import Loading from '../../../components/Loading';
import QaTabComponent from "./qaTab";
import IMAGES from "../../../themes/images";
import Button from "../../../components/Button";
import {listSelect} from "../../../mockData/data";
import Radio from "../../../components/RadioCustom";
import ModalTouchBottom from "../../../components/Modal/ModalTouchBottom";
import Immutable from "seamless-immutable";
import SelectDropdown from "../../../components/Select";
import DropDownCommon from "../../../components/Dropdown";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
type Props = {
  isProcessing: boolean,
  isProcessingEventStore: Boolean,
  isLoading: boolean,
  accmulutePointTimes: number,
  dataGroupBuying: Object,
  dataGroupBuyingHistory: Object,
  getDataGroupBuyingHistory: Function,
  userInfo: Object,
  indexEvent: number,
  idQnaScroll: any,
  setIdQnaScroll: Function,
  idEventProduct: any,
  setIdEventProduct: Function,
  setOptionEventSelected:Function,
  setListOptionsEvent:Function,
  listOptionsEvent: Array,
  pointUser: any,
  getDataEventStore: Function,
  dataEventStore: Array,
  setStoreSelected: Function,
  activeTab: any
};

const PageOrderDetailComponent = ({
  isProcessing,
  isProcessingEventStore,
  isLoading,
  accmulutePointTimes,
  dataGroupBuying,
  dataGroupBuyingHistory,
  getDataGroupBuyingHistory,
  userInfo,
  storeSelected,
  indexEvent,
  // idQnaScroll,
  // setIdQnaScroll,
  idEventProduct,
  setIdEventProduct,
  setOptionEventSelected,
  setListOptionsEvent,
  listOptionsEvent,
  optionEventSelected,
  pointUser,
  getDataEventStore,
  dataEventStore,
  setStoreSelected,
  activeTab
}: Props) => {
  const refNav = useRef(null);
  const [countQa, setCountQa] = useState(0);
  const [stateChangeTab, setStateChangeTab] = useState('home');
  const [stateChangeTabScroll, setStateChangeTabScroll] = useState('');
  const [idQnaScroll, setIdQnaScroll] = useState(0)
  const [keyToggleShowReply, setKeyToggleShowReply] = useState(['key'])
  const [loadingQa, setLoadingQa] = useState(false)
  const [isNodataQA, setIsNodataQA] = useState(false)
  var element = document.getElementById("tab-guide-tab-qa")
  const [isLoadingNative, setIsLoadingNative] = useState(true);

  const [activeItemSlide, setActiveItemStore] = useState('');
  const [activeSlide, setActiveSlide] = useState(0);
  const [isShowModal, setShowModal] = useState(false);
  const [isShowModalOnePoint, setShowModalOnePoint] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [dataDetail, setDataDetail] = useState({});
  const [dataListQa, setDataListQa] = useState({});
  const [loading, setLoading] = useState(true);
  const [textStoreSelectedError, setTextStoreSelectedError] = useState({
    isShow: false,
  });
  const [orderEventDetailHistory, setOrderEventDetailHistory] = useState({});
  const [isModalSelect, setIsModalSelect] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [stateModalConfirmPay1Won, setStateModalConfirmPay1Won] = useState(false);

  const params = useParams();
  const id = params?.id ? params?.id : '';

  const myLocationOffUserApp = localStorage.getItem('myLocation');
  const latAndLong = myLocationOffUserApp ? JSON.parse(myLocationOffUserApp) : ''

  const [stateOptionStore, setStateOptionStore] = useState(null);

  const [listItemStrore, setListItemStore] = useState([]);
  const [pointUserLogin, setPointUserLogin] = useState(0);

  const [dataManager, setDataManager] = useState({});
  const [activeDropDown, setActiveDropDown] = useState({
    id: storeSelected?.store_id,
  });
  const [isCheckShowPopup, setIsCheckShowPopup] = useState(false);

  const fetchDataUserPoint = async (param = '') => {
    let url = ROUTES.API_GET_POINT_USER;
    try {
      const { data: response } = await API.get(url);
      setPointUserLogin(response?.data?.ammount || 0)
    } catch (error) {
      console.log(error,'fetchDataUserPointerror')
    }
  };

  const checkIsExistQA = (dataQA) => {
    if (dataQA && dataQA.length > 0 && location?.search.includes(`qaScroll=${idQnaScroll}`)) {
      
      const isCheck = dataQA.filter(item => idQnaScroll === item.id);
      console.log(isCheck);
      if (isCheck.length < 1 || isCheck[0].status === 0) {
        setIsNodataQA(true);
      }
    }
  }




  const fetchDataListQa = async (param = '') => {
    setLoadingQa(true);
    const url = ROUTES.API_GET_LIST_QA;
    param.page_size = 9999
    if(document.getElementById('tab-filter-qa')){
      document.getElementById('tab-filter-qa').classList.add('disabled')
    }
    try {
      const { data: response } = await API.get(url,param);
      checkIsExistQA(response?.data)
      setDataListQa(response);
      // setCountQa(response?.total)

      // let elementTabQa = document.getElementById("tab-guide-tab-qa")
      // elementTabQa.setAttribute('data-after', response?.total);
      setLoadingQa(false);
      if(document.getElementById('tab-filter-qa')){
        document.getElementById('tab-filter-qa').classList.remove('disabled')
      }

      // setIdEventProduct(0)
    } catch (error) {
      setLoadingQa(false);
      if(document.getElementById('tab-filter-qa')){
        document.getElementById('tab-filter-qa').classList.remove('disabled')
      }
    }
  };

  const fetchDataDetailOrder = async (param = '') => {
    setLoading(true);
    let url = ROUTES.API_EVENT_DETAIL;
    url = url.replace(':id', id);
    try {
      const { data: response } = await API.get(url);
      setDataDetail(response);
      setListOptionsEvent(response?.options || [])
      setLoading(false);
      setCountQa(response?.inquires_count || 0);
      // setIdEventProduct(response?.event_product_id)
      let elementTabQa = document.getElementById("tab-guide-tab-qa")
      elementTabQa.setAttribute('data-after', (response?.inquires_count || ''));

    } catch (error) {
      setLoading(false);
    }
  };

  const toggleBoxReplyFromHistoryPage = (data,id) => {
    for (var i=0;i <= data.length;i++){
      if(data[i].id == +id){
        console.log(i,'iiiiiiiii')
        if(data[i].comment.length > 0){
          let keyMapArray = []
          data[i].comment.map(item =>(
            keyMapArray.push(item.id)
          ))
          setKeyToggleShowReply(keyMapArray)
          // setKeyToggleShowReply(data[i].comment[0].id)
        }

        break;
      }
    }
  }

  const scrollToQna = () => {

    if(idQnaScroll != 0 && idQnaScroll != 'tabQa'){
      let findIdScroll = document.getElementById(`qna-${+idQnaScroll}`)
      if(findIdScroll){
        let positonQna = findIdScroll.getBoundingClientRect()
        console.log(positonQna?.top, 'positonQna?.top');
        window.scrollTo({
          top: (positonQna?.top - 92),
          behavior: 'smooth',
        });
        toggleBoxReplyFromHistoryPage(dataListQa?.data, idQnaScroll)
        // setTimeout(() => {
        //   setIdQnaScroll(0);
        // }, 5000);
      }
    }
  }

  useEffect(() => {
    // console.log(dataListQa, 'dataListQa');
    // if (dataListQa && dataListQa?.data?.length > 0) {
    //   console.log(1111111111111111);
    //   scrollToQna();
    // }
  }, [dataListQa])


  const changeTabs = (e,typeLoad = '') => {
    console.log(e, 'eeee');
    if(!typeLoad){
      setStateChangeTabScroll(e)
    }
    setStateChangeTab(e)
  }

  useEffect(() => {
    if (activeTab === 'tabQa') {
      changeTabs('qa')
    }else if(activeTab !== 'tabQa' && activeTab !== 0){
      changeTabs('qa')
      const findIdScroll = document.getElementById(`qna-${+activeTab}`)
      if(findIdScroll){
        const positonQna = findIdScroll.getBoundingClientRect()
        window.scrollTo({
          top: (positonQna?.top - 92),
          behavior: 'smooth',
        });
        toggleBoxReplyFromHistoryPage(dataListQa?.data, activeTab)
      }
    }
  }, [activeTab])


  useEffect(() => {
    setIsCheckShowPopup(true);
    fetchDataUserPoint()
    console.log(latAndLong,'latAndLong')
    getDataEventStore({
      lat: latAndLong?.lat || '',
      lng: latAndLong?.lng || '',
      event_id: id || '',
    });

    document.body.classList.add('order-detail-bg');

    // if(idEventProduct && idEventProduct != 0){
    //   fetchDataListQa({sort:'all',event_product_id:idEventProduct,user_id:'all'})
    // }
    fetchDataDetailOrder();
    getDataGroupBuyingHistory();

    console.log(idQnaScroll,'idQnaScrollidQnaScroll')
    if(idQnaScroll && idQnaScroll != 0){
      changeTabs('qa','firstLoad')
    }else {
      if(window.location.search && window.location.search.includes('qaScroll=')){
        changeTabs('qa','firstLoad')
        const urlParams = new URLSearchParams(window.location.search);
        let id_qna = urlParams.get('qaScroll');
        setIdQnaScroll(+id_qna)
      }
    }

    const handleScroll = () => {

      if (refNav && window.scrollY >= refNav?.current?.offsetTop) {
        document.body.classList.add('fixed-nav-tab');
      } else {
        document.body.classList.remove('fixed-nav-tab');
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }


  }, []);


  useEffect(() => {
    let listStoreMap = []
    if(dataEventStore && dataEventStore.length > 0){
      listStoreMap = dataEventStore
    }
    let dataTest = [
      {
        store_id:10,
        store_name:'이벤트 이름'
      },
      {
        store_id:11,
        store_name:'이벤트 이름1'
      },
      {
        store_id:12,
        store_name:'이벤트 이름2'
      },
      {
        store_id:13,
        store_name:'이벤트 이름3'
      },
    ]
    // listStoreMap = listStoreMap.concat(dataTest)
    console.log(listStoreMap,'listStoreMap33')
    setListItemStore(listStoreMap)
  }, [dataEventStore]);

  useEffect(() => {
    if(dataDetail?.event_id && dataDetail?.event_product_id) {
      fetchDataListQa({sort:'all',event_product_id: dataDetail.event_product_id, user_id:'all'})
    }

    // if(dataDetail?.event_product_id && idEventProduct != 0 && (dataDetail?.event_product_id != idEventProduct)) {
    //   fetchDataListQa({sort:'all',event_product_id:dataDetail.event_product_id,user_id:'all'})
    // }
  }, [dataDetail]);


  useEffect(() => {
    console.log(idQnaScroll, 'idQnaScroll');
    // if(idQnaScroll && window.location.search && window.location.search.includes('qaScroll=')) {
    //   scrollToQna()
    // }

  }, [idQnaScroll]);



  useEffect(() => {
    let elementTabQa = document.getElementById("tab-guide-tab-qa")
    if(elementTabQa){
      elementTabQa.setAttribute('data-after', countQa || '');
    }
    if(stateChangeTab != 'qa'){
      setKeyToggleShowReply(['key'])
    }
  }, [stateChangeTab,element,loading,isProcessingEventStore]);

  useEffect(() => {
    // window.scrollTo(0,refNav.current.offsetTop,{behavior: 'smooth',})
    if(stateChangeTabScroll){
      if(window.scrollY == 0){
      }else {
        document.body.classList.add('fixed-nav-tab');
      }
      window.scrollTo({
        top: refNav.current.offsetTop - 30,
        behavior: 'smooth',
      });
    }

  }, [stateChangeTabScroll]);




  useEffect(() => {
    const dataOrderEventDetailHistory = dataGroupBuyingHistory?.data?.[0] || {};
    setOrderEventDetailHistory(dataOrderEventDetailHistory);
  }, [dataGroupBuyingHistory]);



  useEffect(() => {
    if (
      dataGroupBuyingHistory &&
      dataDetail &&
      dataGroupBuyingHistory?.data &&
      dataGroupBuyingHistory?.data.length > 0
    ) {
      setOrderEventDetailHistory(
        dataGroupBuyingHistory?.data.find(
          (event) => event.event_id === dataDetail.event_id
        )
      );
    }
  }, [dataDetail, dataGroupBuyingHistory]);



  const fetchData = async (param = '') => {
    setLoading(true);
    let url = ROUTES.API_EVENT_DETAIL;
    url = url.replace(':id', id);
    try {
      const { data: response } = await API.get(url);
      setDataDetail(response);
      setListOptionsEvent(response?.options || [])
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(listOptionsEvent.length > 0){
      var listOptionCanOrder = listOptionsEvent.filter(item =>item.order_quantity > item.total_order)
      console.log(listOptionCanOrder,'listOptionCanOrder',listOptionsEvent)
      setOptionEventSelected(listOptionCanOrder.length > 0 ? listOptionCanOrder[0] : listOptionsEvent[0])
    }else{
      setOptionEventSelected({})
    }
  }, [listOptionsEvent]);


  const disableSubmit = !(
    storeSelected &&
    storeSelected.store_id &&
    storeSelected.store_id != undefined &&
    storeSelected.store_id != ''
  );

  useEffect(() => {
    // setShowModal(accmulutePointTimes <= 1);
    document.body.classList.add('order-detail-bg');
    document.getElementById("myFrame").height = "100%";
  }, []);
  const wasBought =
    orderEventDetailHistory &&
    orderEventDetailHistory.event_id == dataDetail.event_id &&
    orderEventDetailHistory.user_id == userInfo?.uid;

  const currentTime = moment(new Date().getTime()).format(
    'YYYY-MM-DD HH:mm:ss'
  );
  const isOutOfTime = currentTime >= dataDetail.period_end;
  const isOutOfStock = dataDetail.orders_count >= dataDetail.total_available;
  const listSlider =
    dataDetail.imagesPath && dataDetail.imagesPath.length > 0
      ? dataDetail.imagesPath
      : [];

  // check active btn order event
  // in status ['IN_PROGRESS'] && event 
  const activeOrder =
    dataDetail &&
    dataDetail.e_status == "IN_PROGRESS" &&
    // pointUserLogin > 1 &&
    !wasBought && //pass
    !isOutOfTime && //pass
    !isOutOfStock;//pass

  console.log(wasBought, isOutOfTime, isOutOfStock, 'wasBought, isOutOfTime, isOutOfStock');
  console.log(activeOrder, 'activeOrder');
    // && !textStoreSelectedError.isShow;
  const percentProcess =
    ((+dataDetail?.orders_count || 0) * 100) /
      (+dataDetail?.total_available || 0) || 0;

  const renderListSlider =
    listSlider &&
    listSlider.map((item, index) => {
      const activeList =
        activeItemSlide && activeItemSlide === index ? 'active' : '';
      return (
        <SwiperSlide key={index}>
          <div className={`item ${activeList}`}>
            <img src={item} alt="" />
          </div>
        </SwiperSlide>
      );
    });

  const backPage = () => {
    document.body.classList.remove('order-detail-bg');
    document.body.classList.remove('fixed-nav-tabsdcsdc');
    if(window.location.search && (window.location.search.includes('qaScroll=') || window.location.search.includes('from=recent-activity'))){
      window.backToScreenNative()
      // window.backToScreenByUrlNative('TabOther');
    }else {
      history.push(ROUTERS.ORDER);
    }
  };
  const orderBookPageUrl = (dataUrl, event_id) => {
    const url = dataUrl.replace(':id', event_id);
    return url ?? '';
  };

  const toPageOrderBook = () => {
    if (pointUserLogin < 1) {
      setShowModal(pointUserLogin <= 1);
      return false;
    }
    if (pointUserLogin == 1) {
      setShowModalOnePoint(true);
      return false;
    }
    if (disableSubmit) {
      // setTextStoreSelectedError({
      //   isShow: true,
      // });
      setIsModalSelect(true)
      // if(listOptionsEvent.length > 0){
      //   setIsModalSelect(true)
      // }else{
      //   document.body.classList.remove('order-detail-bg');
      //   history.push(orderBookPageUrl(ROUTERS.ORDER_BOOK, id))
      // }
    } else {
      setIsModalSelect(true)
      // if(listOptionsEvent.length > 0){
      //   setIsModalSelect(true)
      // }else{
      //   document.body.classList.remove('order-detail-bg');
      //   history.push(orderBookPageUrl(ROUTERS.ORDER_BOOK, id))
      // }
    }
  };

  const handClickOrder = () => {
    setIsModalSelect(false)
    setStateModalConfirmPay1Won(true)
  }

  const toOrderPageSuccess = () => {
    document.body.classList.remove('order-detail-bg');
    history.push(orderBookPageUrl(ROUTERS.ORDER_BOOK, id))
  }
  window.addEventListener('click', function (e) {

    if(document.getElementById('modal-select-update') && document.getElementById('modal-select-update').contains(e.target)){
      // console.log()
    }else {
      if(document.getElementById('modal-select-update') && document.getElementById('modal-select-update').classList.contains('open')){
        if(e.target.className.includes('show-modal')){
          setIsModalSelect(false);
        }
      }
    }
  })

  const sortListQa = (data) => {
    let paramSort = {sort:data?.type,event_product_id:dataDetail.event_product_id,user_id:data?.user_id || ''}
    fetchDataListQa(paramSort)
  }

  const updateQuantityQnaAfterDelete = () => {
    let elementTabQa = document.getElementById("tab-guide-tab-qa")
    elementTabQa.setAttribute('data-after', (countQa-1 || ''));
    setCountQa(countQa-1)
  }

  const handleOnFocusSelect = () => {};
  const handleOnMenuClose = () => {};

  const handleSelectChange = (e) => {
    setStateOptionStore(e)
  };
  const handleChangeDropdown = (e) => {
    console.log(581, e);
    setStoreSelected(e)
    setActiveDropDown({
      id: e.store_id
    })
    setDataManager({
      ...dataManager,
      id: e.store_id
    })
  }


  const handleConfirmNodata = () => {
    setIsNodataQA(false);
    const url = window.location.search;
    console.log(url, 'url');
    const urlReplace = url.replace(`qaScroll=${idQnaScroll}`, 'qaScroll=');
    console.log(urlReplace, 'urlReplace');
    // window.location.replace(urlReplace)
    history.push({
      pathname: window.location.pathname,
      search: urlReplace
    })
  }

  useEffect(() => {
    if (!isProcessingEventStore && !loading) {
      setIsLoadingNative(false)
    }
  }, [isProcessingEventStore, loading])

  return (
    <>
        <MainLayout
          isMarginBottom
          isCenter
          isShowIcon
          isLink
          isLoading={isLoadingNative}
          customClass="order-detail"
        >
          <div className="order-detail-content">
            
            <div className="order-detail-content__slide">
              <Swiper
                spaceBetween={0}
                pagination
                className="mySwiper"
                onSlideChange={(e) => {
                  setActiveSlide(e.realIndex);
                  setActiveItemStore(e.realIndex);
                }}
                initialSlide={activeSlide}
              >
                {renderListSlider}
              </Swiper>
              <div className="icon-back" onClick={() => backPage()}>
                <img
                  className="icon-back__img"
                  src={images.icon_arrow_left}
                  alt=""
                />
              </div>
              {dataDetail &&  dataDetail.period_end && dataDetail.e_status !== 'FINISHED' && moment(dataDetail.period_end)  > moment(currentTime)
              && (<div className="time-style">
                <span className="time-style-number">
                  <TimeCounter endTime={dataDetail.period_end} />
                </span>
              </div>
              )}
            </div>
            <div className="order-detail-content__progress" style={{visibility:(dataDetail.e_status != "COMMING_SOON" ? 'visible' : 'hidden')}}>
              {
                dataDetail &&  dataDetail.period_end && dataDetail.e_status !== 'FINISHED' && moment(dataDetail.period_end)  >
                moment(currentTime) && (<div className='box-progress'>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: (percentProcess +'%') }}
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <img src={images.icon_percen} className="icon-progress" alt="" />
                      <label className="d-none">progress</label>
                    </div>

                  </div>
                  <div className='progress-text'>
                    <label className='progress-text__number'>{percentProcess.toFixed(0)}%</label> <label className='progress-text__label'>판매되었어요!</label>
                  </div>
                </div>)}

            </div>
            <div className="order-detail-content__info-box">

              <h3 className="info-box-name">
                {indexEvent ? `문구야 갖다줘 ${dataDetail?.e_no || 0}탄: ` : ''}
                {dataDetail?.card_name || ''}
              </h3>
              <label className="progress-sell-box__price__value">
                {parseInt(dataDetail?.price || 0, 10).toLocaleString('en')}
                원
              </label>
            </div>
            <div className="order-detail-content__info-guide-product" ref={refNav}>
              <Tabs onSelect={(e)=>changeTabs(e)} activeKey={stateChangeTab} defaultActiveKey={stateChangeTab} id="tab-guide" className="">
                <Tab eventKey="home" title="상품설명">
                  <div className="tab-content-body custom-tab-content">
                    <div
                      className="tab-content-body__card custom-card-render"
                      // dangerouslySetInnerHTML={{
                      //   __html: dataDetail?.description_content || '',
                      // }}
                    >
                      <iframe id="myFrame" srcDoc={dataDetail?.description_content || ''} />
                    </div>
                    {/* <img className="progress-icon" src={images.guide_use_220719} alt="" /> */}
                  </div>
                </Tab>
                <Tab eventKey="profile" title="이용안내">
                  <div className="tab-content-body custom-tab-content">
                    <div
                      className="tab-content-body__card information-use"
                      style={{display:(dataDetail?.guide_content ? 'block' : 'none')}}
                      // dangerouslySetInnerHTML={{
                      //   __html: dataDetail?.guide_content || '',
                      // }}
                    >
                      <iframe srcDoc={dataDetail?.guide_content || ''} />
                    </div>

                    {/*<div*/}
                    {/*  className="tab-content-body__card notice"*/}
                    {/*  dangerouslySetInnerHTML={{*/}
                    {/*    __html: dataDetail?.caution_content || '',*/}
                    {/*  }}*/}
                    {/*/>*/}
                    {dataDetail?.guide_content ? (<div className="line-under"></div>) : ''}

                    <div
                      className="tab-content-body__card notice"
                      style={{paddingTop:(dataDetail?.guide_content ? '15px' : 0)}}
                    >
                      <h3><strong>유의사항</strong></h3>
                      <p>본 예약 상품은 교환, 환불이 불가합니다.</p>
                      <ul>
                        <li>
                          구매 예약 완료 후, 상품을 결제하지 않을 경우에는 다음 행사
                          참여가 제한될 수 있으니, 꼭 구매를 완료해 주세요.
                        </li>
                        <li>
                          좋은 혜택으로 상품을 구성해 제공하기에, 구매 예약은<br/>
                          1인당 1회로 참여가 가능합니다. 중복 참여 불가합니다.
                        </li>
                        <li>
                          구매 예약 후에는 '예약 취소'가 되지 않으며,<br/>
                          수령 매장 변경이 어렵습니다.
                        </li>
                        <li>
                          환불 조건 및 방법 : 본 상품은 구매예약 상품으로 유효기간<br/>
                          연장 및 환불대상이 아닙니다.
                        </li>
                        <li>
                          준비한 수량이 모두 소진 될 경우, 구매 기한이 남아 있더라도<br/>
                          구매 예약이 불가합니다.
                        </li>
                      </ul>
                    </div>

                  </div>
                </Tab>
                <Tab eventKey="qa" title="Q&A">
                  <div className="tab-content-body custom-tab-content">
                    <div className='qa-content'>
                      <QaTabComponent
                        keyToggleShowReply={keyToggleShowReply}
                        idQnaScroll={idQnaScroll}
                        dataList={dataListQa?.data || []}
                        userId={userInfo.id}
                        idEventProduct={dataDetail.event_product_id}
                        getSortListQa={sortListQa}
                        isProcessing={loadingQa}
                        scrollToQna={scrollToQna}
                        userInfo={userInfo}
                        updateQuantityQnaAfterDelete={updateQuantityQnaAfterDelete}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
            <div className='btn-action-submit-bottom'>
              <div
                className={`content-submit-fixed-bottom ${
                  !activeOrder && 'disabled'
                }`}
                onClick={() => toPageOrderBook()}
              >
                <label>
                  {wasBought
                    ? '예약 완료'
                    : isOutOfTime || isOutOfStock
                      ? '예약 기간 종료'
                      : '구매 예약 하기'}{' '}
                </label>
              </div>
            </div>
          </div>


          <div className={`modal-touch-bottom ${isModalSelect ? 'overlay' : ''} ${listOptionsEvent.length > 0 ? '' : 'empty-option'}`}>
            <ModalTouchBottom
              isShowOverlay={isModalSelect}
              setIsShowOverlay={setIsModalSelect}
              data={[]}
              handleSelect={() => {}}
              isShowChildren
            >
              <div id='modal-select-update' className={` modal-select-update open`}>
                <div className='box-content-choose-option'>
                  <div
                    className="box-modal-close"
                  >
                  </div>
                  <div className={`modal-select--header ${listOptionsEvent && listOptionsEvent.length > 0 ? '' : 'd-none'}`}>
                    <span>옵션 선택</span>
                  </div>

                  <div className="modal-content modal-select-over">
                    <div className="modal-over">
                      <div className='main-option-product' 
                        style={{minHeight: `${listOptionsEvent && listOptionsEvent.length > 0 ? '' : '0px'}`}}>
                        {listOptionsEvent && listOptionsEvent.map((item,key)=>(
                          <div key={key} className={`group-radio-item  
                            ${item.total_order == item.order_quantity ? 'disabled ' : ''}
                            ${optionEventSelected && item.id == optionEventSelected.id ? 'active' : ''}`}>
                            <label className='text-option' onClick={()=>{setOptionEventSelected(item)}}>{item.name}</label>
                            {item.total_order == item.order_quantity ? 
                              (
                                <Radio isChecked={false} />
                              ) : (<Radio onChange={() => {
                              setOptionEventSelected(item)
                            }} isChecked={optionEventSelected && item.id === optionEventSelected.id ? true : false} />)}

                          </div>
                        ))}
                      </div>
                      <div className='main-choose-store'>
                        <label className='main-choose-store__title'>픽업 매장 선택</label>
                        <div>
                          <DropDownCommon
                            customClass={`business-drop ${activeDropDown?.id ? 'has-choose' : ''} ${listItemStrore.length > 3 ? ' list-more' : ''}`}
                            dataList={[...listItemStrore]}
                            nameDisplay='가맹점을 선택해 주세요'
                            // placeHolder={'매장 선택'}
                            handleClick={(e) => handleChangeDropdown(e)}
                            keyName='store_name'
                            keyId='store_id'
                            idActive={activeDropDown?.id || 0}
                            errorMes=''
                            name='store_id'
                          />
                        </div>
                      </div>
                      <div className='main-price-product'>
                        <div className='box-price'>
                          <label>공동구매가</label>
                          <strong>
                            {parseInt(((dataDetail?.price || 0)+(+optionEventSelected?.extra_amount || 0)) + 1, 10).toLocaleString('en')}원
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='box-submit-modal'>
                    <Button
                      customClass={`btn btn-order ${
                        storeSelected?.store_id ? '' : 'disabled'
                      }`}

                      onClick={() => handClickOrder()}
                    >
                      구매 예약하기
                    </Button>
                  </div>
                </div>
              </div>
            </ModalTouchBottom>
          </div>

          {/*<div id='modal-select-update' className={`modal-select modal-select-update ${isModalSelect ? 'open' : ''}`}>*/}
          {/*  <div className='box-content-choose-option'>*/}
          {/*    <div*/}
          {/*      className="box-modal-close"*/}
          {/*      onClick={() => {*/}
          {/*        setIsModalSelect(false);*/}
          {/*      }}*/}
          {/*      role="button"*/}
          {/*      tabIndex={0}*/}
          {/*      onKeyDown={() => {}}*/}
          {/*    >*/}
          {/*    </div>*/}
          {/*    <div className="modal-select--header">*/}
          {/*      <span>옵션 선택</span>*/}
          {/*      <div*/}
          {/*        className="modal-close"*/}
          {/*        onClick={() => {*/}
          {/*          setIsModalSelect(false);*/}
          {/*        }}*/}
          {/*        role="button"*/}
          {/*        tabIndex={0}*/}
          {/*        onKeyDown={() => {}}*/}
          {/*      >*/}
          {/*        <img src={IMAGES.pop_X} alt="" />*/}
          {/*      </div>*/}
          {/*    </div>*/}

          {/*    <div className="modal-content modal-select-over">*/}
          {/*      <div className="modal-over">*/}
          {/*        <div className='main-option-product'>*/}
          {/*          {listOptionsEvent && listOptionsEvent.map((item,key)=>(*/}
          {/*            <div key={key} className={`group-radio-item  */}
          {/*            ${item.total_order == item.order_quantity ? 'disabled ' : ''}*/}
          {/*            ${optionEventSelected && item.id == optionEventSelected.id ? 'active' : ''}`}>*/}
          {/*              <label className='text-option' onClick={()=>{setOptionEventSelected(item)}}>{item.name}{item.total_order == item.order_quantity ? '(품절)' : ''}</label>*/}
          {/*              <Radio onChange={() => {*/}
          {/*                setOptionEventSelected(item)*/}
          {/*              }} isChecked={optionEventSelected && item.id === optionEventSelected.id ? true : false} />*/}
          {/*            </div>*/}
          {/*          ))}*/}
          {/*        </div>*/}
          {/*        <div className='main-price-product'>*/}
          {/*          <div className='box-price'>*/}
          {/*            <label>공동구매가</label>*/}
          {/*            <strong>*/}
          {/*              {parseInt(((dataDetail?.price || 0)+(+optionEventSelected?.extra_amount || 0)) + 1, 10).toLocaleString('en')}원*/}
          {/*            </strong>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}

          {/*    <Button*/}
          {/*      customClass="fixed"*/}
          {/*      onClick={() => handClickOrder()}*/}
          {/*    >*/}
          {/*      구매 예약하기*/}
          {/*    </Button>*/}
          {/*  </div>*/}
          {/*</div>*/}


          <ModalPopup
            isOpen={isShowModal}
            isShowFooter
            isShowHeader
            title="구매 불가 안내"
            customClass="modal-alert-status-box"
            handleClose={() => setShowModal(false)}
            textBtnRight="확인"
          >
            <div className="content-modal">
              <div className="content-modal__title">
                <label>
                  현재 고객님의 보유 포인트는{' '}
                  <strong>{pointUserLogin}원</strong>
                  입니다.
                </label>
              </div>
              <div className="content-modal__description">
                <p>
                  <strong>1포인트 이상 소지시</strong> 예약이 가능합니다.
                </p>
                <p>문구야 놀자에서 상품을 구매하면 포인트가 적립됩니다.</p>
              </div>
            </div>
          </ModalPopup>

          <ModalPopup
            isOpen={isShowModalOnePoint}
            isShowFooter
            isShowHeader
            title="구매 조건 안내"
            customClass="modal-alert-status-box"
            handleClose={() => setShowModalOnePoint(false)}
            textBtnRight="확인"
          >
            <div className="content-modal">
              <div className="content-modal__title">
                <label>
                  현재 고객님의 적립 횟수{' '}
                  <strong>{pointUserLogin}회</strong>
                  입니다.
                </label>
              </div>
              <div className="content-modal__description">
                <p>
                  문구야 놀자에서 상품을 구매하면 포인트가 적립됩니다.
                </p>
                <p>포인트 적립 횟수가 2회 이상인 분들에 한에서<br/>
                  랜덤박스 구매 예약이 가능합니다.</p>
              </div>
            </div>
          </ModalPopup>

          {/* Popup Store Selected Error */}
          <ModalPopup
            isOpen={textStoreSelectedError.isShow}
            isShowFooter
            isShowTwoBtn
            handleClose={() =>
              setTextStoreSelectedError({
                isShow: false,
              })
            }
            handleSubmit={() => {
              setTextStoreSelectedError({
                isShow: false,
              });
              history.push({
                pathname: ROUTERS.STORE_SELECTION,
                state: {
                  route: orderBookPageUrl(ROUTERS.ORDER_DETAIL, id),
                  event_id: id,
                },
              });
            }}
            textBtnRight="매장 선택하기"
            textBtnLeft="나중에 할게요"
            customClass="modal-login modal-alert-choose-store"
          >
            <div className="content">
              <div className="modal-content-head">
                <h3 className="modal-content-title">
                  상품 수령할 매장을 선택해주세요.
                </h3>
              </div>
              <div className="modal-content-body">
                <label className='text-center'>
                  매장을 먼저 선택 후,<br/>
                  구매 예약을 진행해주세요.
                </label>
              </div>
            </div>
          </ModalPopup>

          {/* Popup confirm pay 1won */}
          <ModalPopup
              isOpen={stateModalConfirmPay1Won}
              isShowFooter
              isShowTwoBtn
              handleClose={() =>
                  setStateModalConfirmPay1Won(false)
              }
              handleSubmit={() => {
                setStateModalConfirmPay1Won(false)
                toOrderPageSuccess()
              }}
              textBtnRight="확인"
              textBtnLeft="취소"
              customClass="modal-confirm-pay-one-won "
          >
            <div className="content">
              <div className="modal-content-body">
                <label>
                  {indexEvent ? `문구야 갖다줘 ${dataDetail?.e_no || 0}탄: ` : ''}
                  {dataDetail?.card_name || ''}<br/>
                  구매 예약 하시겠습니까?
                </label>
                <p>구매 예약 진행 시, <strong>1 Point</strong>가 차감됩니다.</p>
                <div className='info-store'>
                  <label className='info-store__name'>{storeSelected?.store_name}</label>
                  <label className='info-store__address'>{storeSelected?.store_address}</label>
                </div>
              </div>
            </div>
          </ModalPopup>

          <ModalPopup
            isOpen={isNodataQA}
            customClass="no-data-annoucement"
          >
            <div className="content">
              <h3>존재하지 않는 답변입니다.</h3>
              <div className="group-button-popup">
                <Button
                  type="button"
                  customClass='button--half'
                  onClick={() => handleConfirmNodata()}
                >
                  확인
                </Button>
              </div>
            </div>
          </ModalPopup>
        </MainLayout>

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.groupBuyingReducer.isProcessing,
    isProcessingEventStore: state.groupBuyingReducer.isProcessingEventStore,
    accmulutePointTimes: state.groupBuyingReducer.accmulutePointTimes,
    dataGroupBuying: state.groupBuyingReducer.dataGroupBuying,
    dataGroupBuyingHistory: state.groupBuyingReducer.dataGroupBuyingHistory,
    userInfo: state.accountReducer.userInfo,
    storeSelected: state.groupBuyingReducer.storeSelected,
    indexEvent: state.groupBuyingReducer.indexEvent,
    idQnaScroll: state.groupBuyingReducer.idQnaScroll,
    activeTab: state.groupBuyingReducer.idQnaScroll,
    idEventProduct: state.groupBuyingReducer.idEventProduct,
    listOptionsEvent: state.groupBuyingReducer.listOptionsEvent,
    optionEventSelected: state.groupBuyingReducer.optionEventSelected,
    pointUser: state.mainReducer.pointUser,
    dataEventStore:state.groupBuyingReducer.dataEventStore,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...Creators,
      getDataGroupBuyingHistory: CreatorsOrder.getDataGroupBuyingHistory,
      setIdQnaScroll: CreatorsOrder.setIdQnaScroll,
      setIdEventProduct: CreatorsOrder.setIdEventProduct,
      setOptionEventSelected: CreatorsOrder.setOptionEventSelected,
      setListOptionsEvent: CreatorsOrder.setListOptionsEvent,
      getDataEventStore:CreatorsOrder.getDataEventStore,
      setStoreSelected:CreatorsOrder.setStoreSelected
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo<Props>(PageOrderDetailComponent));
