import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as CreatorStore } from 'screens/myPage/redux';
import { Creators as CreatorAccount } from 'account/redux';
import { Creators as CreatorSearch } from 'screens/findStore/redux';
import ProductComponent from '../components';
import { Creators } from '../redux';

const mapStateToProps = (state) => {
  return {
    typeRequest: state.productReducer.type,
    isProcessing: state.productReducer.isProcessing,
    isProcessingProduct: state.productReducer.isProcessingProduct,
    isProcessingComment: state.productReducer.isProcessingComment,
    total: state.productReducer.total,
    listProductEnquiry: state.productReducer.listProductEnquiry,
    isProcessingLike: state.productReducer.isProcessingLike,
    isProcessingDelProductEnquiry: state.productReducer.isProcessingDelProductEnquiry,
    userInfo: state.accountReducer.userInfo,
    statusCode: state.productReducer.statusCode,
    listStoreLikeOfUser: state.myPageReducer.listStoreLike,
    dataMyInfo: state.myPageReducer.dataMyInfo,
    storeSearch: state.productReducer.storeSearch,
    listAllStore: state.searchReducer.listAllStore,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...Creators,
      getListProductEnquiry: Creators.getListProductEnquiry,
      resetTypeRegister: Creators.resetTypeRegister,
      likeProductEnquiry: Creators.likeProductEnquiry,
      deleteProductEnquiry: Creators.deleteProductEnquiry,
      createComment: Creators.createComment,
      deleteComment: Creators.deleteComment,
      updateComment: Creators.updateComment,
      getMyInfo: CreatorStore.getMyInfo,
      updateProfileUserInfo: CreatorAccount.updateProfileUserInfo,
      setStoreActive: Creators.setStoreActive,
      getListAllStore: CreatorSearch.getListAllStore,
      updateListProductEnquiry: Creators.updateListProductEnquiry,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductComponent);
