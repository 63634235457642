import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from '../../utils/Apis';

import { Types } from '../redux';

// worker Saga: will be fired on VERIFY_SMS_CODE actions
function* verifySmsCode(action) {
  try {
    /**
     * Example data
     * url: enpoint/register/check-confirm-code
     * params:
     *  {
     *    code: '123456'
     *  }
     *
     */

    const response = yield call(() =>
      API.get(ROUTES.API_CHECK_CODE, action.data)
    );

    if (response.ok) {
      const { data } = response;
      // In case: get sms code request success
      yield put({ type: Types.VERIFY_SMS_CODE_SUCCESS, data });
    } else {
      // In case: get sms code request failed
      yield put({
        type: Types.VERIFY_SMS_CODE_FAILED,
        errors: response.data.text && response.data.text,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.VERIFY_SMS_CODE_FAILED, error });
  }
}

/*
  Starts signupAccount on each dispatched `VERIFY_SMS_CODE` action.
*/
function* verifySmsCodeSaga() {
  yield takeLatest(Types.VERIFY_SMS_CODE, verifySmsCode);
}

export default verifySmsCodeSaga;
