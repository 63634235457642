/* eslint-disable camelcase */
import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from 'utils/Apis';
import { checkStatusCodeApi } from 'utils/Helpers';

import { Types } from '../redux';

// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* createComment(action) {
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() =>
      API.post(ROUTES.API_CREATE_COMMENT, action.data)
    );

    if (response.ok) {
      const { data } = response;
      // In case: signup request success
      yield put({
        type: Types.CREATE_COMMENT_SUCCESS,
        data,
        inquiryId: action?.data?.inquiry_id,
      });
    } else {
      const { data } = response;
      checkStatusCodeApi(data?.code || null)
      yield put({
        type: Types.CREATE_COMMENT_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.CREATE_COMMENT_FAILED, error });
  }
}

/*
  Starts createComment on each dispatched `CREATE_COMMENT` action.
*/
function* createCommentSaga() {
  yield takeLatest(Types.CREATE_COMMENT, createComment);
}

export default createCommentSaga;
