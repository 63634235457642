import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FindStoreComponent from '../components/index';
import { Creators } from '../redux';

const mapStateToProps = (state) => {
  console.log(state, 'FindStoreComponent');
  
  return {
    listStoreLike: state.searchReducer.listStoreLikeOfUser,
    type: state.searchReducer.type,
    isProcessingAllStore: state.searchReducer.isProcessingAllStore,
    isProcessingFindStore: state.searchReducer.isProcessingFindStore,
    listLocationStore: state.searchReducer.listLocationStore,
    listAllStore: state.searchReducer.listAllStore,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...Creators,
      getListFindStore: Creators.getListFindStore,
      getListAllStore: Creators.getListAllStore,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FindStoreComponent);
