export const ORDER_STATUS_HISTORY = {
    COMPLETED : "구매완료",
    AVAILABLE : "수령 가능",
    WAITING : "예약 완료"
};

export const EVENT_STATUS = {
    COMMING_SOON : "COMMING_SOON",
    IN_PROGRESS: "IN_PROGRESS",
    FINISHED: "FINISHED"
};

export const formatTimeToHHmm = (time) => {
    // let formatTime = time ? (moment(new Date(time)).format('YYYY-MM-DD HH:mm:ss')) : ''
    let formatTime = time
    if(formatTime){
        formatTime =  formatTime.slice(0,-3)
    }
    return formatTime
}
export function nl2br (str, replaceMode, isXhtml) {

    var breakTag = (isXhtml) ? '<br />' : '<br>';
    var replaceStr = (replaceMode) ? '$1'+ breakTag : '$1'+ breakTag +'$2';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
}

export function countTextareaContentLength (content) {
    return content.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1 ').length
}

export const TIME_EVENT_NEW_MEMBER = {
    START : "2023-08-21 00:00:00",
    END : "2023-10-10 23:59:59",
}

export const TIME_EVENT_LOTTERY = {
    START : "2023-08-21 00:00:00",
    END : "2023-10-10 23:59:59",
};

export const TIME_EVENT_HASTAG = {
    START : "2023-08-21 00:00:00",
    END : "2023-09-30 23:59:59",
};

export const EVENT_TYPE = {
    RECEIVE_LOTTERY : "RECEIVE_LOTTERY",
    NEW_MEMBER: "NEW_MEMBER",
    HASH_TAG: "HASH_TAG"
};