/* eslint-disable camelcase */
import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTES, API } from 'utils/Apis';
import { checkStatusCodeApi } from 'utils/Helpers';

import { Types } from '../redux';

// worker Saga: will be fired on SIGN_UP_ACCOUNT actions
function* getListFindStore(action) {
  console.log(action, 'action');
  try {
    /**
     * Example data
     * url: enpoint/register
     *
     */
    const response = yield call(() =>
      API.get(ROUTES.API_FIND_STORE, action.storeName)
    );

    if (response.ok) {
      const { data } = response;
      // In case: signup request success
      yield put({ type: Types.GET_LIST_FIND_STORE_SUCCESS, data });
    } else {
      const { data } = response;
      checkStatusCodeApi(data?.code || null)
      yield put({
        type: Types.GET_LIST_FIND_STORE_FAILED,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.GET_LIST_FIND_STORE_FAILED, error });
  }
}

function* removeStoreLikeById(action) {
  try {
    const response = yield call(() =>
      API.post(ROUTES.API_REMOVE_STORE_LIKE, action.storeId)
    );

    if (response.ok) {
      const { data } = response;
      // yield put({ type: Types.GET_LIST_FIND_STORE });
      yield put({ type: Types.REMOVE_STORE_LIKE_BY_ID_SUCCESS });
    } else {
      // yield put({
      //   type: Types.REMOVE_STORE_LIKE_BY_ID_FAILD,
      // });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: Types.GET_LIST_FIND_STORE_FAILED, error });
  }
}

/*
  Starts getListFindStore on each dispatched `GET_LIST_FIND_STORE` action.
*/
function* getListFindStoreSaga() {
  yield takeLatest(Types.GET_LIST_FIND_STORE, getListFindStore);
  yield takeLatest(Types.REMOVE_STORE_LIKE_BY_ID, removeStoreLikeById)
}

export default getListFindStoreSaga;
